import React from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import CreaNuevaSolicitud from "../contenedores/CreaNuevaSolicitud";
import Principal from "../contenedores/Principal";
import ConsultaSolicitudesMasNomina from "../contenedores/ConsultaSolicitudesMasNomina";

import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Row } from "react-bootstrap";

import Col from 'react-bootstrap/Col';

import Container from 'react-bootstrap/Container';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Fab from '@mui/material/Fab';
import CalculateIcon from '@mui/icons-material/Calculate';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import NavigationIcon from '@mui/icons-material/Add';
import NearMeIcon from '@mui/icons-material/NearMe';
import ConsultaSolicitudes from "../contenedores/ConsultaSolicitudes";
import Cotizador from "../forms/Cotizador";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import Guias from "../contenedores/Guias"; 

//import CatalogoAutos from "../contenedores/CatalogoAutos2";
import Tickets from "../contenedores/tickets";
const logo = require("../imagenes/logo_dxn.png")

const avatar = require("../imagenes/avatar.png")

const menu = require("../imagenes/menu.png")

const pin2 = require("../imagenes/pin2.png")


const gradiente = require("../imagenes/back_sidebar.png")


class SideNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.clickElementoSideBar = this.clickElementoSideBar.bind(this)
    this.clickMenu = this.clickMenu.bind(this)
    this.manejaMenu = this.manejaMenu.bind(this)
    this.state = {
      muestraComponente: 'consultaSolicitudes',
      isVisible: false,
      showClaseCollapse: 'collapse collapse-horizontal show border-end ',
      noShowClaseCollapse: 'collapse collapse-horizontal border-end ',
      colapsaCatalogos: true 
    };
  }


  clickElementoSideBar(elementoPresionado) {
    console.log("presioanndo el side bar")
    this.setState({ muestraComponente: elementoPresionado, isVisible: false })
  }

  clickMenu() {
    this.setState({ isVisible: !this.state.isVisible })
  }

  manejaMenu() {
    this.setState({ colapsaCatalogos: !this.state.colapsaCatalogos })
  }

  render() {
    return (

      <div className="container-fluid" /*style={{overflowX: "hidden"}}*/>

        <div className="row flex-nowrap">

          <div className="col-auto px-0">


            <div id="sidebar" style={{ width: '300px', height: '100%', backgroundImage: 'linear-gradient(#0000, #FF6B00)' }} className={this.state.isVisible ? this.state.showClaseCollapse : this.state.noShowClaseCollapse}>
              <div className="sidebar-content">
                <div className="sidebar-section sidebar-user">
                  <div className="sidebar-section-body">
                    <div className="media">
                      <a href="#" className="mr-3">
                        <img src={logo} style={{ height: "75%", width: "100%" }} />
                      </a>
                    </div>
                  </div>
                </div>
                <div id="sidebar-nav" className="list-group border-0 rounded-0 text-sm-start min-vh-100">

                  <br></br>
                  <List
                    sx={{ width: '100%', maxWidth: 360, backgroundImage: 'linear-gradient(#0000, #FF6B00)' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader style={{ backgroundColor: '#212529', color: 'white' }} component="div" id="nested-list-subheader">
                      </ListSubheader>
                    }
                  >


                    <ListItemButton onClick={() => { this.clickElementoSideBar("nuevaSol") }}>
                      <ListItemIcon>
                        <AddToPhotosOutlinedIcon style={{ color: '#585858' }} />
                      </ListItemIcon>
                      <ListItemText primary="Nueva Solicitud" style={{ color: '#585858' }} />
                    </ListItemButton>

                    {<ListItemButton onClick={() => { 
                        this.setState({ muestraComponente: "pp" })
                        setTimeout(() => {  this.clickElementoSideBar("consultaSolicitudes") },10)
                      
                      }}>
                      <ListItemIcon>
                        <AddchartOutlinedIcon style={{ color: '#585858' }} />
                      </ListItemIcon>
                      <ListItemText primary="Consulta de Solicitudes" style={{ color: '#585858' }} />
                    </ListItemButton>}

                    <ListItemButton onClick={() => { this.clickElementoSideBar("guias") }}>
                      <ListItemIcon>
                        <PlagiarismIcon style={{ color: '#585858' }} />
                      </ListItemIcon>
                      <ListItemText primary="Guias" style={{ color: '#585858' }} />
                    </ListItemButton>


                    <ListItemButton onClick={() => { this.clickElementoSideBar("tickets") }}>
                      <ListItemIcon>
                        <ConfirmationNumberOutlinedIcon style={{ color: '#585858' }} />
                      </ListItemIcon>
                      <ListItemText primary="Tickets" style={{ color: '#585858' }} />
                    </ListItemButton>

                  { /* <ListItemButton onClick={() => { this.clickElementoSideBar("autos") }}>
                      <ListItemIcon>
                        <DirectionsCarFilledIcon style={{ color: '#585858' }} />
                      </ListItemIcon>
                      <ListItemText primary="Catalogo Autos" style={{ color: '#585858' }} />
                    </ListItemButton>*/}


                  </List>
                </div>
              </div>
            </div>



          </div>

          <main className="col ps-md-2 pt-2">
            <Navbar expand="lg" className="bg-body-tertiary">
              <Container fluid>
                <Navbar.Brand href="#"> 
                  {
                    this.state.isVisible == true ? <img src={menu} style={{ height: "30%", width: "30%", color: "white", justifyContent: "left" }} onClick={this.clickMenu} /> : <img onClick={this.clickMenu} src={menu} style={{ height: "30%", width: "30%", color: "white", justifyContent: "left" }} />

                  }
               
                </Navbar.Brand>

                <Form className="d-flex">
                  <Dropdown>
                    <Dropdown.Toggle style={{ backgroundColor: 'transparent', color: '#FF6B00', borderColor: 'white' }} id="dropdown-basic">
                      {this.props.nombreUsuario.toString()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item style={{ backgroundColor: 'transparent', color: '#FF6B00', borderColor: 'white' }} href="/">Cerrar sesión</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <img src={avatar} alt="Avatar Logo" style={{ width: "40px" }} class="rounded-pill" />
                </Form>




              </Container>
            </Navbar>
            <br />

            <Row>
            

              <Col xs={12} md={6} lg={6} style={{display:'flex'}}>

                <Fab variant="extended" style={{ color: '#FF6B00' }} onClick={() => {
                  this.setState({ muestraComponente: "pp" })
                  setTimeout(() => { this.setState({ muestraComponente: "nuevaSol" }) }, 10)

                }}>
                  <NavigationIcon sx={{ mr: 1 }} />
                  Nueva Solicitud
                </Fab>

                <Fab variant="extended" style={{ color: '#FF6B00',marginLeft:"30px" }} onClick={() => {
                  this.setState({ muestraComponente: "pp" })
                  setTimeout(() => { this.setState({ muestraComponente: "Cotizador" }) }, 10)

                }}>
                  <CalculateIcon sx={{ mr: 1 }} />
                  Cotizador
                </Fab>


              </Col>
            </Row>


            {this.state.muestraComponente === 'nuevaSol' && 
            <CreaNuevaSolicitud urlINE={this.props.urlINE}  idPuesto={this.props.idPuesto} muestraComponente={this.state.muestraComponente} idUsuario={this.props.idUsuario} idSucursal={this.props.idSucursal} esMesaControl={this.props.esMesaControl} />}
            {this.state.muestraComponente === 'consultaSolicitudes' && 
            <ConsultaSolicitudes  urlINE={this.props.urlINE} idPuesto={this.props.idPuesto} idUsuario={this.props.idUsuario} idSucursal={this.props.idSucursal} isSuperUsuario={this.props.isSuperUsuario} esMesaControl={this.props.esMesaControl} />}
            {this.state.muestraComponente === 'Cotizador' &&
             <Cotizador/>}
            {this.state.muestraComponente === 'guias' && <Guias/>}

            {this.state.muestraComponente === 'tickets' && <Tickets idUsuario={this.props.idUsuario}/>}


          </main>
        </div>
      </div>


    );
  }

}

export default SideNavBar;
