import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import conexiones from '../urls/conexiones.json'
import Modal from 'react-bootstrap/Modal';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import Table from 'react-bootstrap/Table';
import botonRegresar from '../imagenes/Boton_regresar.png'
import botonActualizar from '../imagenes/botonActulizar.png'
import botonGuardarSolicitud from "../imagenes/botonGuardarSolicitud.png"
import botonAvanzar from '../imagenes/avanzar_boton.png'


export default class OcupacionSolicitanteForm extends Component {

    constructor() {
        super();
        this.getCatalogo = this.getCatalogo.bind(this)
        this.armaSelect = this.armaSelect.bind(this)
        this.armaSelectDuro = this.armaSelectDuro.bind(this)
        this.creaDatosOcupacion = this.creaDatosOcupacion.bind(this)
        this.getDatosOcupacion = this.getDatosOcupacion.bind(this)
        this.actualizaDatosOcupacion = this.actualizaDatosOcupacion.bind(this)
        this.formatoLog = this.formatoLog.bind(this)
        this.quitaEspecialesYNumeros = this.quitaEspecialesYNumeros.bind(this)
        this.mascaraDecimales = this.mascaraDecimales.bind(this)
        this.especialesPuestoProfesion = this.especialesPuestoProfesion.bind(this)
        this.state = {
            idBanderaClavePrepCat:0,
            idDXN:"",
            banderaJubilado: false,
            /*modal */

            colorErrorModal: '#F83C3C',
            colorSuccesModal: '#27F45E',
            colorModal: '',
            tituloModal: '',
            mensajeError: '',
            showModal: false,
            /*modal */
            idDatosOcupacion: null,
            selectTipoEmpleado: [],
            cargoPublico: [],
            cargoFarmiliar: [],
            cargoPublicoBandera: true,
            cargoFarmiliarBandera: true,
            validaciones: {
                validaTipoEmpleado: false,
                validaNumEmpleado: false,
                validaIngresoMensual: false,
                validaProfesion: false,
                validaPuesto: false,
                validaPuestoPublico: false,
                validaPuestoFamiliar: false,
                validaPeriodoEjercicioPublico: false,
                validaPeriodoEjercicioFamiliar: false,
                validaCargoPublico: false,
                validaCargoFamiliar: false,
                validaNombreFamiliar: false,
                validaParentesco: false,
                validaClavePresupuestal:false,
                validaCategoria:false
            },
            datosSolicitante: {
                tipoEmpleado: "2",
                numeroEmpleado: null,
                ingresoMensual: null,
                profesion: null,
                puesto: null,
                cargoPublicoCampo: "No",
                cargoPublicoFamiliarCampo: "No",
                puestoPublico: null,
                periodoEjercicioPublico: null,
                nombreFamiliar: null,
                parentescoFamiliar: null,

                puestoFamiliar: null,
                periodoEjercicioFamiliar: null,
                clavePresupuestal:null,
                categoria:null
            }

        }
    }


    especialesPuestoProfesion(valor) {
        let finalString = valor.replace(/[^0-9a-zA-Z ]/g, '')
        return finalString
    }
    quitaEspecialesYNumeros(valor) {
        let finalString = valor.replace(/[^0-9a-zA-Z. ]/g, '')
        let digitsOnlyString = finalString.replace(/[^A-Za-z. ]/g, '');
        return digitsOnlyString
    }

    mascaraDecimales(valor) {
        return valor.replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    }
    mascaraDecimales2(valor) {
        var regex = /^\d+(\.\d{1,2})?$/;

        if (valor.includes(".")) {
            //return valor
            let contenidoCadena = valor.split(".")
            if (contenidoCadena.length > 1) {

                let cadena2 = contenidoCadena[1].substring(0, 2)
                return contenidoCadena[0].replace(/\D/g, "") + "." + cadena2.replace(/\D/g, "")
            } else {
                return contenidoCadena[0].replace(/\D/g, "")
            }
        } else {
            return valor.replace(/\D/g, "")
        }
    }



    onChangeValues = e => {

        let validaciones = this.state.validaciones
        let datosSolicitante = this.state.datosSolicitante
        if (e.target.name == "selectTipoEmpleado") {
            let sector = e.target.value;
            if (sector == null || sector == "Seleccione...") {
                validaciones.validaTipoEmpleado = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaTipoEmpleado = false
                this.setState({ validaciones: validaciones })
            }
            if (e.target.value == 3) {
                datosSolicitante["profesion"] = "N/A"
                datosSolicitante["puesto"] = "N/A"
                this.setState({ banderaJubilado: true })
            } else {
                this.setState({ banderaJubilado: false })
            }
            datosSolicitante["tipoEmpleado"] = e.target.value
        }


        if (e.target.name == "numeroEmpleado") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaNumEmpleado = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaNumEmpleado = false
                this.setState({ validaciones: validaciones })
            }
            console.log("numeroEmpleado ", e.target.value)
            datosSolicitante["numeroEmpleado"] = e.target.value
        }

        if (e.target.name == "ingresoMensual") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaIngresoMensual = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaIngresoMensual = false
                this.setState({ validaciones: validaciones })
            }

            datosSolicitante["ingresoMensual"] = this.mascaraDecimales2(e.target.value)

        }


        if (e.target.name == "profesion") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaProfesion = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaProfesion = false
                this.setState({ validaciones: validaciones })
            }

            datosSolicitante["profesion"] = this.especialesPuestoProfesion(e.target.value).toUpperCase()
        }

        if (e.target.name == "puesto") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaPuesto = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPuesto = false
                this.setState({ validaciones: validaciones })
            }
            datosSolicitante["puesto"] = this.especialesPuestoProfesion(e.target.value).toUpperCase()
        }

        if (e.target.name == "cargoPublico") {
            console.log("cargoPublico   ", e.target.name, e.target.value)
            let sector = e.target.value;
            if (sector == null || sector == "Seleccione...") {
                datosSolicitante["puestoPublico"] = ""
                datosSolicitante["periodoEjercicioPublico"] = ""
                validaciones.validaCargoPublico = true
                this.setState({ validaciones: validaciones, cargoPublicoBandera: true })
            } else if (sector == "Si") {
                validaciones.validaCargoPublico = false
                this.setState({ validaciones: validaciones, cargoPublicoBandera: false })
            } else if (sector == "No") {
                datosSolicitante["puestoPublico"] = ""
                datosSolicitante["periodoEjercicioPublico"] = ""
                validaciones.validaCargoPublico = false
                this.setState({ validaciones: validaciones, cargoPublicoBandera: true })
            }

            datosSolicitante["cargoPublicoCampo"] = e.target.value
        }


        if (e.target.name == "puestoPublico") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaPuestoPublico = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPuestoPublico = false
                this.setState({ validaciones: validaciones })
            }
            datosSolicitante["puestoPublico"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
        }

        if (e.target.name == "periodoEjercicioPublico") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaPeriodoEjercicioPublico = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPeriodoEjercicioPublico = false
                this.setState({ validaciones: validaciones })
            }
            datosSolicitante["periodoEjercicioPublico"] = e.target.value
        }


        if (e.target.name == "cargoFarmiliar") {
            let sector = e.target.value;
            if (sector == null || sector == "Seleccione...") {
                validaciones.validaCargoFamiliar = true
                datosSolicitante["nombreFamiliar"] = ""
                datosSolicitante["parentescoFamiliar"] = ""
                datosSolicitante["puestoFamiliar"] = ""
                datosSolicitante["periodoEjercicioFamiliar"] = ""
                this.setState({ validaciones: validaciones, cargoFarmiliarBandera: true })
            } else if (sector == "Si") {
                validaciones.validaCargoFamiliar = false
                this.setState({ validaciones: validaciones, cargoFarmiliarBandera: false })
            } else if (sector == "No") {
                validaciones.validaCargoFamiliar = false
                datosSolicitante["nombreFamiliar"] = ""
                datosSolicitante["parentescoFamiliar"] = ""
                datosSolicitante["puestoFamiliar"] = ""
                datosSolicitante["periodoEjercicioFamiliar"] = ""
                this.setState({ validaciones: validaciones, cargoFarmiliarBandera: true })
            }
            datosSolicitante["cargoPublicoFamiliarCampo"] = e.target.value

        }

        if (e.target.name == "nombreFamiliar") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaNombreFamiliar = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaNombreFamiliar = false
                this.setState({ validaciones: validaciones })
            }
            datosSolicitante["nombreFamiliar"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
        }

        if (e.target.name == "parentescoFamiliar") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaParentesco = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaParentesco = false
                this.setState({ validaciones: validaciones })
            }
            datosSolicitante["parentescoFamiliar"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
        }

        if (e.target.name == "puestoFamiliar") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaPuestoFamiliar = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPuestoFamiliar = false
                this.setState({ validaciones: validaciones })
            }
            datosSolicitante["puestoFamiliar"] = this.quitaEspecialesYNumeros(e.target.value).toUpperCase()
        }

        if (e.target.name == "periodoEjercicioFamiliar") {
            let fechaIngreso = e.target.value;
            if (fechaIngreso == null || fechaIngreso == "") {
                validaciones.validaPeriodoEjercicioFamiliar = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPeriodoEjercicioFamiliar = false
                this.setState({ validaciones: validaciones })
            }
            datosSolicitante["periodoEjercicioFamiliar"] = e.target.value
        }

        if (e.target.name == "clavePresupuestal") {
            let clavePresupuestal = e.target.value;
            if (clavePresupuestal == null || clavePresupuestal == "") {
                validaciones.validaClavePresupuestal = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaClavePresupuestal = false
                this.setState({ validaciones: validaciones })
            }
            datosSolicitante["clavePresupuestal"] = e.target.value
        }

        if (e.target.name == "categoria") {
            let categoria = e.target.value;
            if (categoria == null || categoria == "") {
                validaciones.validaCategoria = true
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaCategoria = false
                this.setState({ validaciones: validaciones })
            }
            datosSolicitante["categoria"] = e.target.value
        }

        console.log(this.state)
        this.setState({ datosSolicitante: datosSolicitante })
    }

    async getSolicitud(idSolicitud) {
        try {
          const response = await fetch(conexiones.urlServicios + "wedo_solicitud/" + idSolicitud);
          if (response.ok) {
            const json = await response.json();
            console.log("respojsjahsahsuhuiah  ", json)
            if (json.length > 0) {
              this.setState({idBDSolicitud:json[0].id, idDXN:json[0].appid,
                idBanderaClavePrepCat: json[0].tipo_producto_id == 9 || json[0].tipo_producto_id == 10 || json[0].tipo_producto_id == 11
                ?true:false
             })
            }
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }

    UNSAFE_componentWillMount() {
        if (this.props.idSolicitud != null) {
            this.getSolicitud(this.props.idSolicitud)
        }


        if (this.props.existenDatosOcupacion) {
            this.getDatosOcupacion(this.props.idSolicitud)
        } else {
            console.log("entrando con el id null  ", this.props.datosSolicitanteOcupacion)
            if (this.props.datosSolicitanteOcupacion.tipoEmpleado == 3) {
                this.setState({ banderaJubilado: true })
            } else {
                this.setState({ banderaJubilado: false })

            }
            this.setState({ datosSolicitante: this.props.datosSolicitanteOcupacion })
        }

        setTimeout(() => {
            this.getCatalogo(conexiones.urlPytonParametros + "catalogo_tipo_empleado/0", "selectTipoEmpleado")
            this.armaSelectDuro("cargoPublico")
            this.armaSelectDuro("cargoFarmiliar")
        }, 1000);
    }

    armaSelectDuro(selector) {
        let select = []
        //if(selector == "selectSector" ||  selector == "selectProducto" || selector == "selectSucursal"){
        let options = []
        options.push(<option value={null} >Seleccione...</option>)
        if (selector == "cargoPublico") {
            /* if (this.state.idDatosOcupacion == null) {
                 options.push(<option value="Si">{"Si"}</option>)
                 options.push(<option value="No">{"No"}</option>)
             } else {*/
            if (this.state.datosSolicitante.cargoPublicoCampo == "Si") {
                options.push(<option selected value="Si">{"Si"}</option>)
                options.push(<option value="No">{"No"}</option>)
            }
            if (this.state.datosSolicitante.cargoPublicoCampo == "No") {
                options.push(<option value="Si">{"Si"}</option>)
                options.push(<option selected value="No">{"No"}</option>)
            }
            // }

        }


        if (selector == "cargoFarmiliar") {
            /*   if (this.state.idDatosOcupacion == null) {
                   options.push(<option value="Si">{"Si"}</option>)
                   options.push(<option value="No">{"No"}</option>)
               } else {*/
            if (this.state.datosSolicitante.cargoPublicoFamiliarCampo == "Si") {
                options.push(<option selected value="Si">{"Si"}</option>)
                options.push(<option value="No">{"No"}</option>)
            }
            if (this.state.datosSolicitante.cargoPublicoFamiliarCampo == "No") {
                options.push(<option value="Si">{"Si"}</option>)
                options.push(<option selected value="No">{"No"}</option>)
            }
            // }

        }
        if (selector == "cargoPublico" || selector == "cargoFarmiliar") {
            select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
        }


        this.setState({ [selector]: select })
    }
    armaSelect(filas, selector) {
        let select = []
        //if(selector == "selectSector" ||  selector == "selectProducto" || selector == "selectSucursal"){
        let options = []
        options.push(<option value={null} >Seleccione...</option>)

        /*  if (this.state.idDatosOcupacion == null) {
              for (var i = 0; i < filas.length; i++) {
                  let fila = filas[i].fila
                  options.push(<option value={fila[0].value}>{fila[1].value}</option>)
              }
          } else {*/
        for (var i = 0; i < filas.length; i++) {
            let fila = filas[i].fila
            if (this.state.datosSolicitante.tipoEmpleado == fila[0].value) {
                options.push(<option selected value={fila[0].value}>{fila[1].value}</option>)
            } else {
                options.push(<option value={fila[0].value}>{fila[1].value}</option>)
            }
        }

        //}


        if (selector == "selectTipoEmpleado") {
            select.push(<Form.Select disabled={this.props.banderaSolicitud} onChange={this.onChangeValues} name={selector}  >{options}</Form.Select>)
        }


        this.setState({ [selector]: select })
    }

    async getCatalogo(url, selector) {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const json = await response.json();
                console.log(json)
                this.armaSelect(json.filas, selector)
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    async getDatosOcupacion(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_ocupacion/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("respuesta de la solicitud datos personales ", json[0])
                if (json.length > 0) {
                    let publico = null
                    if (json[0].cargo_publico != null) {
                        if (json[0].cargo_publico == 1) {
                            publico = "Si"
                        } else {
                            publico = "No"
                        }
                    }


                    let familiar = null
                    if (json[0].cargo_publico_familiar != null) {
                        if (json[0].cargo_publico_familiar == 1) {
                            familiar = "Si"
                        } else {
                            familiar = "No"
                        }
                    }


                    //  let nuevaSolicitud = this.state.datosSolicitante
                    let datosSolicitante = {
                        tipoEmpleado: json[0].tipo_empleado,
                        numeroEmpleado: json[0].numero_empleado,
                        ingresoMensual: json[0].ingreso_mensual,
                        profesion: json[0].profesion,
                        puesto: json[0].puesto,
                        cargoPublicoCampo: publico,
                        puestoPublico: json[0].puesto_cp,
                        periodoEjercicioPublico: json[0].periodo_ejercicio_cp,
                        cargoPublicoFamiliarCampo: familiar,
                        nombreFamiliar: json[0].nombre_familiar,
                        parentescoFamiliar: json[0].parentesco,
                        puestoFamiliar: json[0].puesto_cpf,
                        periodoEjercicioFamiliar: json[0].periodo_ejercicio_cpf,
                        clavePresupuestal:json[0].clave_presupuestal,
                        categoria:json[0].categoria,
                    }
                    if (json[0].tipo_empleado == 3) {
                        this.setState({ banderaJubilado: true })
                    } else {
                        this.setState({ banderaJubilado: false })

                    }

                    let validaciones = {
                        validaTipoEmpleado: false,
                        validaNumEmpleado: false,
                        validaIngresoMensual: false,
                        validaProfesion: false,
                        validaPuesto: false,
                        validaPuestoPublico: false,
                        validaPuestoFamiliar: false,
                        validaPeriodoEjercicioPublico: false,
                        validaPeriodoEjercicioFamiliar: false,
                        validaCargoPublico: false,
                        validaCargoFamiliar: false,
                        validaNombreFamiliar: false,
                        validaParentesco: false,
                        validaClavePresupuestal:false,
                        validaCategoria:false
                    }
                    if (publico == "Si") {
                        this.setState({ cargoPublicoBandera: false })
                    }

                    if (familiar == "Si") {
                        this.setState({ cargoFarmiliarBandera: false })
                    }
                    this.props.setDatosOcupacionServicio(json)
                    this.props.setBanderaExistenDatosOcupacion(true)

                    this.setState({ datosSolicitante: datosSolicitante, validaciones: validaciones, idDatosOcupacion: json[0].id })
                }

            } else {
                console.error('Error response.status :', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio", showModal: true })

            }
        } catch (error) {
            console.error('Error: ', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosDomicilio", showModal: true })

        }
    }


    creaDatosOcupacion() {

        let validaciones = this.state.validaciones
        let datosSolicitante = this.state.datosSolicitante

        if (datosSolicitante.tipoEmpleado == null) {
            validaciones.validaTipoEmpleado = true;
            this.setState({ validaciones: validaciones })
        } else {
            validaciones.validaTipoEmpleado = false;
            this.setState({ validaciones: validaciones })
        }

        if (datosSolicitante.numeroEmpleado == null) {
            validaciones.validaNumEmpleado = true;
            this.setState({ validaciones: validaciones })
        } else {
            validaciones.validaNumEmpleado = false;
            this.setState({ validaciones: validaciones })
        }

        if (datosSolicitante.ingresoMensual == null) {
            validaciones.validaIngresoMensual = true;
            this.setState({ validaciones: validaciones })
        } else {
            validaciones.validaIngresoMensual = false;
            this.setState({ validaciones: validaciones })
        }

        if (datosSolicitante.profesion == null) {
            validaciones.validaProfesion = true;
            this.setState({ validaciones: validaciones })
        } else {
            validaciones.validaProfesion = false;
            this.setState({ validaciones: validaciones })
        }

        if (datosSolicitante.tipoEmpleado == 1) {
            if (datosSolicitante.puesto == null) {
                validaciones.validaPuesto = true;
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaProfesion = false;
                this.setState({ validaciones: validaciones })
            }

        }

        if (datosSolicitante.cargoPublicoCampo == null) {
            validaciones.validaCargoPublico = true;
            this.setState({ validaciones: validaciones })
        } else {
            validaciones.validaCargoPublico = false;
            this.setState({ validaciones: validaciones })
        }

        if (datosSolicitante.cargoPublicoCampo == "Si") {
            console.log("los daot solicitanto ", datosSolicitante)
            if (datosSolicitante.puestoPublico == null) {
                validaciones.validaPuestoPublico = true;
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPuestoPublico = false;
                this.setState({ validaciones: validaciones })
            }
        }

        if (datosSolicitante.cargoPublicoCampo == "Si") {
            console.log("los daot solicitanto ", datosSolicitante)
            if (datosSolicitante.periodoEjercicioPublico == null) {
                validaciones.validaPeriodoEjercicioPublico = true;
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPeriodoEjercicioPublico = false;
                this.setState({ validaciones: validaciones })
            }
        }


        if (datosSolicitante.cargoPublicoFamiliarCampo == null) {
            validaciones.validaCargoFamiliar = true;
            this.setState({ validaciones: validaciones })
        } else {
            validaciones.validaCargoFamiliar = false;
            this.setState({ validaciones: validaciones })
        }

        if(this.state.idBanderaClavePrepCat ){
            
            if (datosSolicitante.clavePresupuestal == null || datosSolicitante.clavePresupuestal == "") {
                validaciones.validaClavePresupuestal = true;
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaClavePresupuestal = false;
                this.setState({ validaciones: validaciones })
            }

            if (datosSolicitante.categoria == null || datosSolicitante.categoria == "") {
                validaciones.validaCategoria = true;
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaCategoria = false;
                this.setState({ validaciones: validaciones })
            }

        }



        if (datosSolicitante.cargoPublicoFamiliarCampo == "Si") {
            console.log("los daot solicitanto ", datosSolicitante)
            if (datosSolicitante.nombreFamiliar == null) {
                validaciones.validaNombreFamiliar = true;
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaNombreFamiliar = false;
                this.setState({ validaciones: validaciones })
            }
        }
        if (datosSolicitante.cargoPublicoFamiliarCampo == "Si") {
            console.log("los daot solicitanto ", datosSolicitante)
            if (datosSolicitante.parentescoFamiliar == null) {
                validaciones.validaParentesco = true;
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaParentesco = false;
                this.setState({ validaciones: validaciones })
            }
        }

        if (datosSolicitante.cargoPublicoFamiliarCampo == "Si") {
            console.log("los daot solicitanto ", datosSolicitante)
            if (datosSolicitante.puestoFamiliar == null) {
                validaciones.validaPuestoFamiliar = true;
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPuestoFamiliar = false;
                this.setState({ validaciones: validaciones })
            }
        }

        if (datosSolicitante.cargoPublicoFamiliarCampo == "Si") {
            console.log("los daot solicitanto ", datosSolicitante)
            if (datosSolicitante.periodoEjercicioFamiliar == null) {
                validaciones.validaPeriodoEjercicioFamiliar = true;
                this.setState({ validaciones: validaciones })
            } else {
                validaciones.validaPeriodoEjercicioFamiliar = false;
                this.setState({ validaciones: validaciones })
            }
        }


        let publico = null
        if (this.state.datosSolicitante.cargoPublicoCampo == "Si") {
            publico = 1
        } else if (this.state.datosSolicitante.cargoPublicoCampo == "No") {
            publico = 2
        }
        let familiar = null
        if (this.state.datosSolicitante.cargoPublicoFamiliarCampo == "Si") {
            familiar = 1
        } else if (this.state.datosSolicitante.cargoPublicoFamiliarCampo == "No") {
            familiar = 2
        }
        let json =
        {
            "solicitud": this.props.idSolicitud,
            "numero_empleado": this.state.datosSolicitante.numeroEmpleado,
            "ingreso_mensual": this.state.datosSolicitante.ingresoMensual,
            "puesto": this.state.datosSolicitante.puesto,
            "profesion": this.state.datosSolicitante.profesion,
            "cargo_publico": publico,
            "puesto_cp": this.state.datosSolicitante.puestoPublico,
            "periodo_ejercicio_cp": this.state.datosSolicitante.periodoEjercicioPublico,
            "cargo_publico_familiar": familiar,
            "nombre_familiar": this.state.datosSolicitante.nombreFamiliar,
            "parentesco": this.state.datosSolicitante.parentescoFamiliar,
            "puesto_cpf": this.state.datosSolicitante.puestoFamiliar,
            "periodo_ejercicio_cpf": this.state.datosSolicitante.periodoEjercicioFamiliar,
            "tipo_empleado": this.state.datosSolicitante.tipoEmpleado,
            "clave_presupuestal":this.state.idBanderaClavePrepCat == true ? this.state.datosSolicitante.clavePresupuestal:null,
            "categoria":this.state.idBanderaClavePrepCat == true ? this.state.datosSolicitante.categoria:null,
        }
        console.log("el json ", json)
        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }
        fetch(conexiones.urlServicios + "wedo_ocupacion/0", requestReferidoOptions)
            .then(response => response.json())
            .then(data => {

                console.log("respuesta del servicio al crea una solicitud ", data)
                if (data["id"] != undefined) {
                    this.props.setDatosOcupacionServicio(data)
                    this.props.setBanderaExistenDatosOcupacion(true)
                    this.setState({ idDatosOcupacion: data["id"]/*, colorModal: this.state.colorSuccesModal, tituloModal: 'Info guardada', mensajeError: "!Se han guardado tus datos de ocupación con exito¡", showModal: true */ })
                    setTimeout(() => { this.props.clickAvanzar("referencias") }, 500)
                } else {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
                }


            }).catch(error => {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

            })

    }

    actualizaDatosOcupacion() {
        let publico = null
        if (this.state.datosSolicitante.cargoPublicoCampo == "Si") {
            publico = 1
        } else if (this.state.datosSolicitante.cargoPublicoCampo == "No") {
            publico = 2
        }
        let familiar = null
        if (this.state.datosSolicitante.cargoPublicoFamiliarCampo == "Si") {
            familiar = 1
        } else if (this.state.datosSolicitante.cargoPublicoFamiliarCampo == "No") {
            familiar = 2
        }
        let json =
        {
            "solicitud": this.props.idSolicitud,
            "numero_empleado": this.state.datosSolicitante.numeroEmpleado,
            "ingreso_mensual": this.state.datosSolicitante.ingresoMensual,
            "puesto": this.state.datosSolicitante.puesto,
            "cargo_publico": publico,
            "profesion": this.state.datosSolicitante.profesion,
            "puesto_cp": this.state.datosSolicitante.puestoPublico,
            "periodo_ejercicio_cp": this.state.datosSolicitante.periodoEjercicioPublico,
            "cargo_publico_familiar": familiar,
            "nombre_familiar": this.state.datosSolicitante.nombreFamiliar,
            "parentesco": this.state.datosSolicitante.parentescoFamiliar,
            "puesto_cpf": this.state.datosSolicitante.puestoFamiliar,
            "periodo_ejercicio_cpf": this.state.datosSolicitante.periodoEjercicioFamiliar,
            "tipo_empleado": this.state.datosSolicitante.tipoEmpleado,
            "clave_presupuestal":this.state.idBanderaClavePrepCat == true ? this.state.datosSolicitante.clavePresupuestal:null,
            "categoria":this.state.idBanderaClavePrepCat == true ? this.state.datosSolicitante.categoria:null,
        }
        console.log("el json ", json)
        const requestReferidoOptions = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }
        fetch(conexiones.urlServiciosActualiza + "wedo_ocupacion/" + this.props.idSolicitud, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {

                console.log("respuesta del servicio al crea una solicitud ", data)
                if (data["id"] != undefined) {
                    this.props.setDatosOcupacionServicio(data)
                    this.props.setBanderaExistenDatosOcupacion(true)
                    this.setState({ idDatosOcupacion: data["id"]/*, colorModal: this.state.colorSuccesModal, tituloModal: 'Info guardada', mensajeError: "!Se han actualizado tus datos de ocupación con exito¡", showModal: true */ })
                    setTimeout(() => { this.props.clickAvanzar("referencias") }, 500)
                } else {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: this.formatoLog(data), showModal: true })
                }


            }).catch(error => {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })

            })

    }

    formatoLog(data) {
        let encabezados = Object.keys(data)
        let contenido = Object.values(data)
        let salida = ''

        let salidaEncabezados = []

        console.log("encabzados ", encabezados)
        for (var i = 0; i < encabezados.length; i++) {
            salidaEncabezados.push(
                <tr>
                    <td>{encabezados[i]}</td>
                    <td>{contenido[i]}</td>
                </tr>

            );
        }


        salida =
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Campo</th>
                        <th>Mensaje</th>
                    </tr>
                </thead>
                <tbody>
                    {salidaEncabezados}
                </tbody>
            </Table>



        return salida
    }


    render() {
        return (
            <div>
                {/* */}
                < Row>
                    <Col xs={12}>
                        <span style={{ font: "bold", color: "#FF4000" }}>Sol: {this.props.idSolicitud == null ? "0" : this.props.idSolicitud}  DXN:{this.state.idDXN} </span>

                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={3} xs={12}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Label>Estatus del empleado: <span style={{ color: 'red' }}>*</span></Form.Label>
                            {this.state.selectTipoEmpleado}
                            {this.state.validaciones.validaTipoEmpleado == true ? (
                                <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un tipo de empleado</span></Form.Label>
                            ) : null}
                        </Form.Group>
                    </Col>



                    <Col lg={3} md={3} xs={12}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Label>Número de empleado : <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" maxLength={20} name="numeroEmpleado" onChange={this.onChangeValues} value={this.state.datosSolicitante.numeroEmpleado} />
                            {this.state.validaciones.validaNumEmpleado == true ? (
                                <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un número de empleado</span></Form.Label>
                            ) : null}
                        </Form.Group>
                    </Col>

                    <Col lg={3} md={3} xs={12}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Label>Ingreso neto mensual: <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="ingresoMensual" onChange={this.onChangeValues} value={this.state.datosSolicitante.ingresoMensual} />
                            {this.state.validaciones.validaIngresoMensual == true ? (
                                <Form.Label><span style={{ color: 'red' }}>Se debe registrar el ingreso neto mensual</span></Form.Label>
                            ) : null}
                        </Form.Group>
                    </Col>

                    <Col lg={3} md={3} xs={12}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Label>Nombramiento: <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control type="text" disabled={this.state.banderaJubilado || this.props.banderaSolicitud} placeholder="" name="profesion" onChange={this.onChangeValues} value={this.state.datosSolicitante.profesion} />
                            {this.state.validaciones.validaProfesion == true ? (
                                <Form.Label><span style={{ color: 'red' }}>Se debe ingresar el nombramiento</span></Form.Label>
                            ) : null}
                        </Form.Group>
                    </Col>
                </Row>
                <br></br>


                {
                    this.state.datosSolicitante.tipoEmpleado != null ?
                        <>
                            <Row>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Puesto: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control type="text" disabled={this.state.banderaJubilado || this.props.banderaSolicitud} placeholder="" name="puesto" onChange={this.onChangeValues} value={this.state.datosSolicitante.puesto}
                                        />
                                        {this.state.validaciones.validaPuesto == true ? (
                                            <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un puesto</span></Form.Label>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                        : ''
                }

                <br></br>


                {
                    this.state.idBanderaClavePrepCat == true ?   <>
                    <Row>
                        <Col lg={3} md={3} xs={12}>
                            <Form.Group className="mb-6" controlId="">
                                <Form.Label>Clave presupuestal: <span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control type="text"  placeholder="" name="clavePresupuestal" onChange={this.onChangeValues} value={this.state.datosSolicitante.clavePresupuestal}
                                />
                                {this.state.validaciones.validaClavePresupuestal == true ? (
                                    <Form.Label><span style={{ color: 'red' }}>Se debe ingresar una clave presupuestal</span></Form.Label>
                                ) : null}
                            </Form.Group>
                        </Col>

                        <Col lg={3} md={3} xs={12}>
                            <Form.Group className="mb-6" controlId="">
                                <Form.Label>Categoria: <span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control type="text"  placeholder="" name="categoria" onChange={this.onChangeValues} value={this.state.datosSolicitante.categoria}
                                />
                                {this.state.validaciones.validaCategoria == true ? (
                                    <Form.Label><span style={{ color: 'red' }}>Se debe ingresar una categoria</span></Form.Label>
                                ) : null}
                            </Form.Group>
                        </Col>
                    </Row>
                </>:""
                }





                <br />

                <Row>
                    <Col xs={12}><h4>Cargo público</h4></Col>
                </Row>

                {/* */}
                <Row>
                    <Col lg={3} md={3} xs={12}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Label>Tiene cargo público: <span style={{ color: 'red' }}>*</span></Form.Label>
                            {this.state.cargoPublico}
                            {this.state.validaciones.validaCargoPublico == true ? (
                                <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un tipo de empleado</span></Form.Label>
                            ) : null}

                        </Form.Group>
                    </Col>


                    {
                        this.state.cargoPublicoBandera == false ?
                            <>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Puesto: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="puestoPublico" onChange={this.onChangeValues} value={this.state.datosSolicitante.puestoPublico} />
                                        {this.state.validaciones.validaPuestoPublico == true ? (
                                            <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un puesto</span></Form.Label>
                                        ) : null}
                                    </Form.Group>
                                </Col>

                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Periodo de ejercicio: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control disabled={this.props.banderaSolicitud} type="text" placeholder="" name="periodoEjercicioPublico" onChange={this.onChangeValues} value={this.state.datosSolicitante.periodoEjercicioPublico} />
                                        {this.state.validaciones.validaPeriodoEjercicioPublico == true ? (
                                            <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un periodo</span></Form.Label>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </>
                            : ''
                    }



                </Row>

                <br />

                <Row>
                    <Col xs={12}><h4>Cargo público familiar</h4></Col>
                </Row>

                {/* */}
                <Row>
                    <Col lg={3} md={3} xs={12}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Label>Tiene cargo público: <span style={{ color: 'red' }}>*</span></Form.Label>
                            {this.state.cargoFarmiliar}
                            {this.state.validaciones.validaCargoFamiliar == true ? (
                                <Form.Label><span style={{ color: 'red' }}>Se debe seleccionar un tipo de cargo público familiar</span></Form.Label>
                            ) : null}
                        </Form.Group>
                    </Col>


                    {
                        this.state.cargoFarmiliarBandera == false ?
                            <>
                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Indicar nombre del familiar: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" name="nombreFamiliar" onChange={this.onChangeValues}
                                            value={this.state.datosSolicitante.nombreFamiliar} disabled={this.state.cargoFarmiliarBandera || this.props.banderaSolicitud} />
                                        {this.state.validaciones.validaNombreFamiliar == true ? (
                                            <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un nombre</span></Form.Label>
                                        ) : null}

                                    </Form.Group>
                                </Col>

                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Parentesco: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" name="parentescoFamiliar" onChange={this.onChangeValues}
                                            value={this.state.datosSolicitante.parentescoFamiliar} disabled={this.state.cargoFarmiliarBandera || this.props.banderaSolicitud} />
                                        {this.state.validaciones.validaParentesco == true ? (
                                            <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un parentesco</span></Form.Label>
                                        ) : null}
                                    </Form.Group>
                                </Col>

                                <Col lg={3} md={3} xs={12}>
                                    <Form.Group className="mb-6" controlId="">
                                        <Form.Label>Puesto: <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="" name="puestoFamiliar" onChange={this.onChangeValues}
                                            value={this.state.datosSolicitante.puestoFamiliar} disabled={this.state.cargoFarmiliarBandera || this.props.banderaSolicitud} />
                                        {this.state.validaciones.validaPuestoFamiliar == true ? (
                                            <Form.Label><span style={{ color: 'red' }}>Se debe ingresar el puesto</span></Form.Label>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </>
                            : ''
                    }


                </Row>
                <br></br>

                {
                    this.state.cargoFarmiliarBandera == false ?
                        <Row>
                            <Col lg={3} md={3} xs={12}>
                                <Form.Group className="mb-6" controlId="">
                                    <Form.Label>Periodo de ejercicio:  <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="" name="periodoEjercicioFamiliar" onChange={this.onChangeValues}
                                        value={this.state.datosSolicitante.periodoEjercicioFamiliar} disabled={this.state.cargoFarmiliarBandera || this.props.banderaSolicitud} />
                                    {this.state.validaciones.validaPeriodoEjercicioFamiliar ? (
                                        <Form.Label><span style={{ color: 'red' }}>Se debe ingresar un periodo </span></Form.Label>
                                    ) : null}
                                </Form.Group>
                            </Col>
                        </Row>
                        : ''
                }

                <br></br>
                <Row>



                    <div style={{ display: "flex" }}>
                        <img src={botonRegresar}
                            style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "10px" }} onClick={() => { this.props.clickAvanzar("datosSol") }} />
                        <>
                            {
                                this.state.idDatosOcupacion == null ?
                                    <img src={botonAvanzar}
                                        style={{ width: '150px', height: "60px", borderRadius: '40px' }}
                                        onClick={this.creaDatosOcupacion} />
                                    :
                                    <img src={botonAvanzar} disabled={this.props.banderaSolicitud}
                                        style={{ width: '150px', height: "60px", borderRadius: '40px' }} onClick={this.props.banderaSolicitud ? () => { console.log("mkk") } : this.actualizaDatosOcupacion} />


                            }
                        </>

                    </div>


                </Row>
                <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModal: false }) }}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </Modal>

            </div>

        )
    }
}
