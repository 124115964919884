import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./ticket.css";
import conexiones from '../urls/conexiones.json'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Fab from '@mui/material/Fab';

import Modal from 'react-bootstrap/Modal';
import CreateOutlinedIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import "react-datepicker/dist/react-datepicker.css";
/* configuracion para tabla con ejes dobles */
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TicketsForm from "../forms/TicketsForm";

export default class Tickets extends Component {

    constructor() {
        super();
        this.mascaraDecimales = this.mascaraDecimales.bind(this)
        this.cambiaPagina = this.cambiaPagina.bind(this)
        this.armaPaginador = this.armaPaginador.bind(this)
        this.generaDocumentoAMostrar = this.generaDocumentoAMostrar.bind(this)
        this.armaTabla = this.armaTabla.bind(this)
        this.getTickets = this.getTickets.bind(this)


        this.state = {
            esNuevoTicket: false,
            nombreDocAMostrar: "",
            showDocumentoImagen: false,
            showDocumentoPDF: false,
            showModaldocumentoFormato: false,

            muestraComisionesAgenteBandera: false,
            valorBusquedaFolio: "",
            placeHolderFolio: "",
            valorTipoFolio: 2,
            valorBusquedaTabla: "",
            idSolResp: null,
            colorErrorModal: '#F83C3C',
            colorSuccesModal: '#27F45E',
            colorInformativo: "#FF6B00",
            colorModal: '',
            tituloModal: '',
            mensajeError: '',
            showModal: false,
            appIDSeleccionado: null,

            idSolicitud: null,
            banderaSolicitud: false,
            banderaDocumentos: false,
            colorEntrando: "blue",
            valoresTabla: null,
            selectEstatus: [],
            selectTR: [],
            selectEstatusBusqueda: [],
            paginacion: null,
            topePaginacion: 20,
            fechaInicio: null,
            fechaFin: Date.now(),
            idEstatus: 1,
            idEstatusCambio: null,
            zellEstatus: [],
            idEstatusSolicitudes1: 0,
            idEstatusSolicitudes2: 0,
            idEstatusSolicitudes3: 0,
            idEstatusSolicitudes4: 0,
            idEstatusSolicitudes5: 0,
            idEstatusSolicitudes6: 0,
            idEstatusSolicitudes7: 0,
            idEstatusSolicitudes8: 0,
            idEstatusSolicitudes9: 0,
            idEstatusSolicitudes10: 0,
            idEstatusBusqueda: 0,
            page: 0,
            rowsPerPage: 1,

            columns: [
                { id: 'Estatus de mi ticket', label: 'Estatus de mi ticket', minWidth: 80 },
                { id: 'Observaciones', label: 'Observaciones', minWidth: 100 },
                { id: 'Sector', label: 'Sector', minWidth: 80 },
                { id: 'Convenio', label: 'Convenio', minWidth: 80 },
                {
                    id: 'Conclusion',
                    label: 'Conclusion',
                    minWidth: 100,
                    align: 'right',
                    //format: (value) => value.toLocaleString('en-US'),
                },
                {
                    id: 'Documento',
                    label: 'Documento',
                    minWidth: 50,
                    align: 'right',
                    // format: (value) => value.toLocaleString('en-US'),
                },
                { id: 'FechaAlta', label: 'Fecha Alta', minWidth: 80 },
                { id: 'FechaMod', label: 'Fecha Modificacion', minWidth: 80 },


            ],

        }
    }


    mascaraDecimales(valor) {
        return valor.replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    }

    cambiaPagina(numPagina, e) {
        return event => {
            let conteo = (numPagina) * this.state.topePaginacion
            let inicio = (conteo - this.state.topePaginacion) + 1
            let fin = conteo + 1
            this.armaTabla(this.state.valoresTabla, inicio, fin, numPagina)
        }

    }

    armaPaginador(data, pagina) {
        let items = []
        let paginas = data.length / this.state.topePaginacion;

        if (paginas % 2 == 0) {
            console.log("total de paginas  == 0 " + paginas)
            for (var i = 1; i <= paginas; i++) {
                items.push(
                    <Pagination.Item onClick={this.cambiaPagina(i)} key={i} active={i === pagina}>
                        {i}
                    </Pagination.Item>
                )
            }
        } else {
            let paginasString = paginas + ""
            let paginaInt = parseInt(paginasString.split(".")[0]) + 1
            console.log("total de paginas != 0 " + (paginaInt))

            for (var i = 1; i <= paginaInt; i++) {
                items.push(
                    <Pagination.Item onClick={this.cambiaPagina(i)} key={i} active={i === pagina}>
                        {i}
                    </Pagination.Item>
                )
            }
        }

        let paginacion = <Pagination>{items}</Pagination>
        this.setState({ paginacion: paginacion })


    }

    async generaDocumentoAMostrar(urlDoc, nombreDocAMostrar) {
        try {
            console.log("la url a resolver mi chavo ", urlDoc)
            if (urlDoc.includes(".pdf")) {
                console.log("PDFFFFF  ", urlDoc)
                this.setState({ showDocumentoPDF: true, showDocumentoImagen: false })

            } else if (urlDoc.includes(".jpg") || urlDoc.includes(".jpeg")) {
                console.log("IMG ", urlDoc)
                this.setState({ showDocumentoImagen: true, showDocumentoPDF: false })
            } else {
                console.log("otro ", urlDoc)
                this.setState({ showDocumentoImagen: false, showDocumentoPDF: false })
            }
            const response = await fetch(urlDoc)
            if (!response.ok) {
                throw new Error("Error al obtener el archivo");
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            this.setState({ nombreDocAMostrar: nombreDocAMostrar, documentoformato: url, showModaldocumentoFormato: true })


        } catch (exception) {
            console.log(exception)
        }

    }

    armaTabla(data, inicio, fin, pagina) {
        let selectTR = []
        console.log(data)

        inicio = inicio - 1
        fin = fin - 1
        console.log(inicio)
        console.log(fin)
        for (let i = inicio; i < fin; i++) {
            if (data[i] != undefined) {
                console.log("/////////////////////////////// ", data[i])

                let selectTD = []
                selectTD.push(
                    <TableCell align={"center"} >
                        {<Row><h6 >{data[i].estatus} </h6></Row>}

                    </TableCell>
                )
                selectTD.push(<TableCell align={"left"}>
                    <>
                        {data[i].observaciones}

                    </>
                </TableCell>)
                selectTD.push(<TableCell align={"left"}>
                    <>
                        {data[i].sector == null ? "" : data[i].sector}
                    </>
                </TableCell>)

                selectTD.push(<TableCell align={"left"}>
                    <>
                        {data[i].producto == null ? "" : data[i].producto}
                    </>
                </TableCell>)


                selectTD.push(<TableCell align={"left"}>
                    <>
                        {data[i].comentario_sistemas == null ? "" : data[i].comentario_sistemas}
                    </>
                </TableCell>)

                selectTD.push(
                    <TableCell align={"left"}>
                        <>
                            <Row>
                                <Col lg={3} md={3} xs={3}>
                                    <CreateOutlinedIcon style={{ color: '#ffd700', cursor: 'pointer' }} />
                                </Col>

                                <Col lg={3} md={3} xs={3}>
                                    <VisibilityIcon onClick={() => { this.generaDocumentoAMostrar(data[i].documento_ruta, data[i].documento) }} style={{ color: '#77dd77', cursor: 'pointer' }} ></VisibilityIcon>
                                </Col>
                            </Row>

                        </>
                    </TableCell>
                );

                selectTD.push(<TableCell align={"left"}>
                    <>
                        {data[i].fecha_alta == null ? "" : data[i].fecha_alta}
                    </>
                </TableCell>)

                selectTD.push(<TableCell align={"left"}>
                    <>
                        {data[i].ultima_modificacion == null ? "" : data[i].ultima_modificacion}
                    </>
                </TableCell>)

                selectTR.push(<TableRow className="clase-tabla">{selectTD}</TableRow>)


            }// else { break; }


        }
        this.setState({ selectTR: selectTR })
        this.armaPaginador(data, pagina)



    }

    getTickets() {
        fetch(conexiones.urlTicketsTabla + this.props.idUsuario) // debe llevar el id del usuario logeuado
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ valoresTabla: data })
                this.armaTabla(data, 1, this.state.topePaginacion + 1, 1)
            }).catch(error => {

            })

    }

    UNSAFE_componentWillMount() {
        this.getTickets()
    }


    render() {
        return (
            <div className="page-content" style={{ backgroundColor: 'white', marginTop: '25px', height: '100%', width: '100%' }}>
                <div className="content-wrapper">
                    <div className="content-inner" style={{ margin: '10px' }}>
                        <br></br>

                        {!this.state.esNuevoTicket ? <>
                            <Modal backdrop="static" keyboard={false}
                                show={this.state.showModaldocumentoFormato} onHide={() => { this.setState({ showModaldocumentoFormato: false }) }} centered size="md" fullscreen={true} >
                                <Modal.Header style={{ backgroundColor: this.state.colorModalDocumentos, textAlign: 'center' }} closeButton>
                                    <Modal.Title style={{ textAlign: 'center' }}>{this.state.nombreDocAMostrar}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body >

                                    {
                                        this.state.showDocumentoPDF && !this.state.showDocumentoImagen ?
                                            <Worker style={{ width: "100%", height: "100%" }} workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">
                                                <Viewer fileUrl={this.state.documentoformato} />
                                            </Worker>
                                            : !this.state.showDocumentoPDF && this.state.showDocumentoImagen ? <img style={{ width: "100%" }} src={this.state.documentoformato} alt="Vista previa" />
                                                : !this.state.showDocumentoPDF && !this.state.showDocumentoImagen ?
                                                    <iframe style={{ width: "100%", height: "100%" }} src={this.state.documentoformato} ></iframe> : ''
                                    }

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => { this.setState({ showModaldocumentoFormato: false }) }}>
                                        Cerrar
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Row>


                                <Col xs={6} md={8} lg={8} style={{ display: 'flex' }}>
                                    &nbsp;
                                </Col>
                                <Col xs={6} md={4} lg={4} style={{ display: 'flex' }}>

                                    <Fab variant="extended" style={{ backgroundColor: '#FF6B00', color: "white", width: "100%" }} onClick={() => {
                                        this.setState({ muestraComponente: "pp" })
                                        setTimeout(() => { this.setState({ esNuevoTicket: true }) }, 10)

                                    }}>

                                        Nuevo Ticket
                                    </Fab>




                                </Col>
                            </Row>

                            <br></br>
                            <Col lg={12} md={12} xs={12}>
                                <Paper sx={{ width: '100%' }} md={{ width: '100%' }} lg={{ width: '100%' }} >

                                    <TableContainer sx={{ maxHeight: 440 }}>


                                        <Table stickyHeader aria-label="sticky table">
                                            <Col lg={12} md={12} xs={12}>

                                            </Col>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" colSpan={2}>
                                                        &nbsp;
                                                    </TableCell>
                                                    <TableCell align="left" colSpan={3}>
                                                        &nbsp;
                                                    </TableCell>
                                                    <TableCell align="left" colSpan={3}>
                                                        &nbsp;
                                                    </TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    {this.state.columns.map((column, index) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={index == 0 ? "center" : "left"}
                                                            style={{ top: 57, minWidth: column.minWidth, fontStyle: "bold", fontSize: "15px" }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.selectTR}


                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Row lg={12} md={12} xs={12} style={{ margin: '1px' }}>
                                        <Col lg={8} md={8} xs={0}>&nbsp;</Col>
                                        <Col lg={4} md={4} xs={12}>{this.state.paginacion}</Col>
                                    </Row>

                                </Paper>
                            </Col></> : <TicketsForm idUsuario={this.props.idUsuario} regresaBandeja={() => {
                                this.setState({ esNuevoTicket: false })
                                this.getTickets()
                            }} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}
