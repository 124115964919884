import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Card, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import LinearProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';

const fondo = require("../imagenes/BACK_HEAD.png")
const fondoBajoForm = require("../imagenes/LOGO_AUTO_EXPRESS_2024_BLANCO.png")

const kwid = require("../imagenes/KWID_03.png")
const kwid1 = require("../imagenes/KWID_02.png")
const march = require("../imagenes/MARCH_2.png")
const march1 = require("../imagenes/MARCH_1.png")

const aveo = require("../imagenes/AUTO_PRINCIPAL_03.png")
const polo = require("../imagenes/POLO.png")
const polo1 = require("../imagenes/POLO_03.png")

const seguro = require("../imagenes/ICONOS_03.png")
const tenencia = require("../imagenes/ICONOS_05.png");
const altaPlacas = require("../imagenes/ICONOS_07.png")
const verificacion = require("../imagenes/ICONOS_09.png")
const tCirculacion = require("../imagenes/ICONOS_11.png")

const CatalogoAutos = (props) => {
  const [activo, setActivo] = useState(["0", "1"]);

  const [index, setIndex] = useState(0);
  const [indexGusto, setIndexGusto] = useState(0);
  const [indexServicios, setIndexServicios] = useState(0);
  const [validacionDatosVacios, setValidacionDatosVacios] = useState(false);
  const [showDatosAutos, setShowDatosAutos] = useState(false);
  const [imagenCarousel1, setImagenCarousel1] = useState(null);
  const [imagen1Carousel1, setImagen1Carousel1] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectModelo, setSelectModelo] = useState(null)
  const [selectMarca, setSelectMarca] = useState(null)
  const [selectVersion, setSelectVersion] = useState(null)
  const [selectAnio, setSelectAnio] = useState(null)
  const [selectSector, setSelectSector] = useState(null)
  const [selectProducto, setSelectProducto] = useState(null)
  const [selectTipoProducto, setSelectTipoProducto] = useState(null)
  const [selectPeriodo, setSelectPeriodo] = useState(null)
  const [selectPlazo, setSelectPlazo] = useState(null)
  const [jsonConfConvenio, setJsonConfConvenio] = useState({
    idSector: null,
    idProductoConvenio: null,
    idTipoProducto: null,
    idPeriodo: null,
    idPlazo: null,
    valorFinal: 0,
    idUnidad: null,
    costoUnidad: 0.0,

  })
  const [showModalLoader, setShowModalLoader] = useState(false);
  const expresionEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;


  const [pilaAutoBuscado, setPilaAutoBuscado] = useState([])

  const [precioAutoSeleccionado, setPrecioAutoSeleccionado] = useState("");
  const [nombreAnoAutoSeleccionado, setNombreAnoAutoSeleccionado] = useState("")
  const [textoValidacionFormulario, setTextoValidacionFormulario] = useState("")

  const [totalAuto, setTotalAuto] = useState(0.0);
  const [enganche, setEnganche] = useState(0.0);
  const [gestoria, setGestoria] = useState(0.0);
  const [precioAutoTotal, setPrecioAutoTotal] = useState(0.0);
  const [cantidadPagos, setCantidadPagos] = useState("");

  const [showModalError, setShowModalError] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [showModalExito, setShowModalExito] = useState(false);
  const [mensajeExito, setMensajeExito] = useState("");

  let arrIMG = [kwid1, march1, polo1, aveo]

  const [jsonFormulario, setJsonFormulario] = useState({
    nombreCompleto: "",
    rfc: "",
    curp:"",
    alcaldiaMunicipio: "",
    correoElectronico: "",
    numeroCelular: ""
  });


  useEffect(() => {
    const getSelectMarca = async () => {
      try {
        const response = await fetch("https://wedoplus.mx:8099/autos/marca/0");
        if (!response.ok) {
          throw new Error("Error al obtener los datos");
        }//else{
        const result = await response.json();
        // setData(result);
        console.log("Datos obtenidos:", result);
        armaSelect("selectorMarca", result)
        //}
      } catch (error) {
        setError(error.message);
        console.error("Error en la petición:", error);
      } finally {
        setLoading(false);
      }
    };

    getSelectMarca();
    getSelectGenerico("https://wedoplus.mx:8099/parametros/catalogo_sector/0", "selectorSector")
  }, []);


  const convertidorServicioFeoAEstrcuturaSelect = (filasFeas, selector) => {
    let valores = []
    for (var i = 0; i < filasFeas.length; i++) {
      let filaFea = filasFeas[i].fila
      //   console.log("la perra fila fea  ", filaFea)
      if (selector == "selectorSector" || selector == "selectorProducto" || selector == "selectorTipoProducto" || selector == "selectorPeriodo") {
        valores.push({
          id: filaFea[0].value,
          descripcion: filaFea[1].value
        })
      }

    }
    return valores
  }

  const mascaraDecimales = (valor) => {
    if (typeof valor !== "string") {
      valor = String(valor); // Convertir a string si no lo es
    }
    return valor.replace(/\D/g, "")
      //  .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
  }

  const mascaraDecimalesCapPago = (valor) => {
    if (typeof valor !== "string") {
      valor = String(valor); // Convertir a string si no lo es
    }
    return valor.replace(/\D/g, "")
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
  }

  const getSelectGenerico = async (url, selectorPorHacer) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Error al obtener los datos");
      }
      const result = await response.json();
      console.log("Datos obtenidos:", result);
      if (selectorPorHacer == "selectorSector" || selectorPorHacer == "selectorProducto" || selectorPorHacer == "selectorTipoProducto" || selectorPorHacer == "selectorPeriodo") {
        let valores = convertidorServicioFeoAEstrcuturaSelect(result.filas, selectorPorHacer)
        armaSelect(selectorPorHacer, valores)
      } else {
        armaSelect(selectorPorHacer, result)
      }
    } catch (error) {
      setError(error.message);
      console.error("Error en la petición:", error);
    } finally {
      setLoading(false);
    }
  };

  const armaSelect = (selectorName, valores) => {

    let pila = []
    if (selectorName == "selectorMarca") { pila.push(<option value={null}>Marca</option>) }
    if (selectorName == "selectorModelo") { pila.push(<option value={null}>Modelo</option>) }
    if (selectorName == "selectorVersion") { pila.push(<option value={null}>Version</option>) }
    if (selectorName == "selectorAnio") { pila.push(<option value={null}>Año</option>) }
    if (selectorName == "selectorSector") { pila.push(<option value={null}>Sector</option>) }
    if (selectorName == "selectorProducto") { pila.push(<option value={null}>Convenio</option>) }
    if (selectorName == "selectorTipoProducto") { pila.push(<option value={null}>Tipo Producto</option>) }
    if (selectorName == "selectorPeriodo") { pila.push(<option value={null}>Periodo</option>) }
    if (selectorName == "selectorPlazo") { pila.push(<option value={null}>Plazo</option>) }

    for (var i = 0; i < valores.length; i++) {
      if (selectorName == "selectorMarca") {
        pila.push(
          <option value={valores[i].id}>{valores[i].marca}</option>
        )
      }
      if (selectorName == "selectorModelo") {
        pila.push(
          <option value={valores[i].id}>{valores[i].modelo}</option>
        )
      }

      if (selectorName == "selectorPlazo") {
        pila.push(
          <option value={valores[i].id}>{valores[i].plazo}</option>
        )
      }


      if (selectorName == "selectorVersion" || selectorName == "selectorAnio" || selectorName == "selectorSector"
        || selectorName == "selectorProducto" || selectorName == "selectorTipoProducto" || selectorName == "selectorPeriodo") {
        pila.push(
          <option value={valores[i].id}>{valores[i].descripcion}</option>
        )
      }

    }


    let selector =
      <Form.Select name={selectorName} onChange={onChangeSelects} className=" rounded-pill bg-white w-100">
        {pila}
      </Form.Select>
    if (selectorName == "selectorMarca") {
      setSelectMarca(selector)
    }
    if (selectorName == "selectorModelo") {
      setSelectModelo(selector)
    }
    if (selectorName == "selectorVersion") {
      setSelectVersion(selector)
    }
    if (selectorName == "selectorAnio") {
      setSelectAnio(selector)
    }

    if (selectorName == "selectorSector") {
      setSelectSector(selector)
    }
    if (selectorName == "selectorProducto") {
      setSelectProducto(selector)
    }
    if (selectorName == "selectorTipoProducto") {
      setSelectTipoProducto(selector)
    }
    if (selectorName == "selectorPeriodo") {
      setSelectPeriodo(selector)
    }

    if (selectorName == "selectorPlazo") {
      setSelectPlazo(selector)
    }

  }


  const onChangeSelects = (e) => {
    console.log("Valor seleccionado:", e.target.name, e.target.value);
    let jsonConfConvenioSalida = jsonConfConvenio
    if (e.target.name == "selectorMarca") {
      setSelectModelo(null)
      getSelectGenerico("https://wedoplus.mx:8099/autos/modelo/" + e.target.value, "selectorModelo")
      setSelectVersion(null)
      setSelectAnio(null)
    }

    if (e.target.name == "selectorModelo") {
      setSelectVersion(null)
      setSelectAnio(null)
      getSelectGenerico("https://wedoplus.mx:8099/autos/version/" + e.target.value, "selectorVersion")

    }

    if (e.target.name == "selectorVersion") {
      setSelectAnio(null)
      getSelectGenerico("https://wedoplus.mx:8099/autos/anio/" + e.target.value, "selectorAnio")
    }


    if (e.target.name == "selectorAnio") {

      getAutoBuscado("https://wedoplus.mx:8099/autos/unidad/" + e.target.value)
    }

    /* selectores de formulario Queremos saber mas de ti ! */

    if (e.target.name == "selectorSector") {
      jsonConfConvenioSalida.idSector = e.target.value
      setSelectProducto(null)
      setSelectTipoProducto(null)
      setSelectPeriodo(null)
      getSelectGenerico("https://wedoplus.mx:8099/parametros/catalogo_producto/" + e.target.value, "selectorProducto")
    }

    if (e.target.name == "selectorProducto") {
      jsonConfConvenioSalida.idProductoConvenio = e.target.value
      setSelectTipoProducto(null)
      setSelectPeriodo(null)
      //   setIdProductoSeleccionado(e.target.value)
      getSelectGenerico("https://wedoplus.mx:8099/parametros/catalogo_vtipo_producto_producto/" + e.target.value, "selectorTipoProducto")
      getSelectGenerico("https://wedoplus.mx:8099/parametros/catalogo_periodo/" + e.target.value, "selectorPeriodo")

    }

    if (e.target.name == "selectorTipoProducto") {
      jsonConfConvenioSalida.idTipoProducto = e.target.value
      // setIdProductoSeleccionado(e.target.value)
      postPlazoProducto(jsonConfConvenioSalida.idProductoConvenio, e.target.value)
    }


    /*selectores de la cotizacion o inherentes a la cotizaxion  */
    if (e.target.name == "selectorPeriodo") {
      jsonConfConvenioSalida.idPeriodo = e.target.value
    }

    if (e.target.name == "selectorPlazo") {
      jsonConfConvenioSalida.idPlazo = e.target.value
      console.log("enviara la petivion")
      postEvaluaDatosDeuda(e.target.value, enganche)
    }
    setJsonConfConvenio(jsonConfConvenioSalida)
  };

  const postPlazoProducto = async (idProductoConvenio, idTipoProducto) => {
    try {
      const response = await fetch("https://wedoplus.mx:8099/parametros/catalogo_producto_plazo/0", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({

          "producto": idProductoConvenio,
          "tipo_producto": idTipoProducto

        }),
      });

      if (!response.ok) {
        throw new Error("Error en la petición");
      }

      const result = await response.json();
      // setData(result);
      console.log("selector de plaxo ", result)
      armaSelect("selectorPlazo", result)
    } catch (error) {
      // setError(error.message);
    } finally {
      // setLoading(false);
    }
  }

  const getAutoBuscado = async (url) => {
    try {
      setShowModalLoader(true)
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Error al obtener los datos");
      }//else{
      const result = await response.json();
      let jsonConfConvenioSalida = jsonConfConvenio

      console.log("Datos obtenidos auto buscado:", result);
      let auto = result.auto
      if (auto.id != undefined) {
        jsonConfConvenioSalida.valorFinal = (auto.costo + auto.gestoria)
        jsonConfConvenioSalida.idUnidad = auto.id
        jsonConfConvenioSalida.costoUnidad = auto.costo
        setPrecioAutoTotal((auto.costo + auto.gestoria))
        setTotalAuto((auto.costo + auto.gestoria))
        setGestoria(auto.gestoria)
        setNombreAnoAutoSeleccionado(auto.marca_descripcion + " " + auto.modelo_descripcion + " " + auto.anio_descripcion)
        setPrecioAutoSeleccionado("$" + auto.costo)
        let imagenes = result.imagen
        if (imagenes.length > 0) {
          let pilaImagenes = []
          for (var i = 0; i < imagenes.length; i++) {
            pilaImagenes.push(
              <Carousel.Item>
                <img style={{
                  display: "block", // Evita alineación izquierda
                  margin: "auto", // Centra la imagen
                  maxWidth: "100%", // Evita que se desborde
                  height: "auto",
                  objectFit: "contain" // Mantiene proporciones sin cortes
                }}
                  src={imagenes[i].imagen} />

              </Carousel.Item>
            )
          }
          setPilaAutoBuscado(pilaImagenes)
          setTimeout(() => { setShowModalLoader(false) }, 500)
        } else {
          setPilaAutoBuscado([])
          setTimeout(() => { setShowModalLoader(false) }, 500)
        }

      } else {
        jsonConfConvenioSalida.valorFinal = 0.0
        jsonConfConvenioSalida.idUnidad = null
        jsonConfConvenioSalida.costoUnidad = 0.0
        setPrecioAutoTotal(0.0)
        setTotalAuto(0.0)
        setGestoria(0.0)
        setNombreAnoAutoSeleccionado("")
        setPrecioAutoSeleccionado("")
        setTimeout(() => { setShowModalLoader(false) }, 500)
      }




    } catch (error) {
      setError(error.message);
      setTimeout(() => { setShowModalLoader(false) }, 500)
      console.error("Error en la petición:", error);
    } finally {
      setTimeout(() => { setShowModalLoader(false) }, 500)
    }
  };


  useEffect(() => {
    if (data) {
      console.log("Nuevo valor de data:", data);
    }
  }, [data]);



  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleSelectGusto = (selectedIndex) => {
    setIndexGusto(selectedIndex);
  };
  const handleSelectServicios = (selectedIndex) => {
    setIndexServicios(selectedIndex);
  };

  const onChangeValues = (e) => {
    const { name, value } = e.target;
    let nuevaSolicitud = jsonFormulario

    if (e.target.name == "capPago") {
      // setCapacidadPago(mascaraDecimalesCapPago(e.target.value))
    }
    if (e.target.name == "rfc") {
      let curp = e.target.value;
      if (curp.length <= 4) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (esLetra(ultimoCaracter)/*ultimoCaracter != '0' && ultimoCaracter != '1' && ultimoCaracter != '2' && ultimoCaracter != '3' && ultimoCaracter != '4' && ultimoCaracter != '5' && ultimoCaracter != '6' && ultimoCaracter != '7' && ultimoCaracter != '8' && ultimoCaracter != '9'*/) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          } else {
            nuevaSolicitud[[e.target.name]] = ""
          }
        } else {
          nuevaSolicitud[[e.target.name]] = ""
        }
        //this.setState({ banderaRFC: false })

      } else if (curp.length > 4 && curp.length < 10) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {

          if (esNumero(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        //this.setState({ banderaRFC: false })
      } else if (curp.length == 10) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          console.log("entrando en el 10")
          if (esNumero(ultimoCaracter) || esLetra(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        // this.setState({ banderaRFC: false })
      } else if (curp.length > 10 && curp.length <= 11) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (esNumero(ultimoCaracter) || esLetra(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        //this.setState({ banderaRFC: false })
      } else if (curp.length > 11 && curp.length <= 13) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (esNumero(ultimoCaracter) || esLetra(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        // this.setState({ banderaRFC: false })
        if (curp.length == 13) {
          //  this.setState({ banderaRFC: true })
        }
      }
      if (curp.length == 13) {
        //let val = this.state.validaciones
        //val.validaRFC = false
        //this.setState({ banderaRFC: true, validaciones: validaciones })
      }

    }

    if (e.target.name == "curp") {
      let curp = e.target.value;
      if (curp.length <= 4) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {


          if (esLetra(ultimoCaracter)  /*&& ultimoCaracter != '0' && ultimoCaracter != '1' && ultimoCaracter != '2' && ultimoCaracter != '3' && ultimoCaracter != '4' && ultimoCaracter != '5' && ultimoCaracter != '6' && ultimoCaracter != '7' && ultimoCaracter != '8' && ultimoCaracter != '9'*/) {
            console.log("curp entrando  ", ultimoCaracter)
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          } else {
            nuevaSolicitud[[e.target.name]] = ""
          }

        } else {
          nuevaSolicitud[[e.target.name]] = ""
        }
        //this.setState({ banderaCurp: false })

      } else if (curp.length > 4 && curp.length <= 10) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (esNumero(ultimoCaracter)) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
        //this.setState({ banderaCurp: false })
      } else if (curp.length > 10 && curp.length <= 16) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (ultimoCaracter != undefined) {
          if (esLetra(ultimoCaracter)/*ultimoCaracter != '0' && ultimoCaracter != '1' && ultimoCaracter != '2' && ultimoCaracter != '3' && ultimoCaracter != '4' && ultimoCaracter != '5' && ultimoCaracter != '6' && ultimoCaracter != '7' && ultimoCaracter != '8' && ultimoCaracter != '9'*/) {
            nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()
          }
        }
       // this.setState({ banderaCurp: false })
      } else if (curp.length > 16 && curp.length <= 18) {
        let ultimoCaracter = curp.split('')[curp.length - 1] // 'd'
        if (esLetra(ultimoCaracter) || esNumero(ultimoCaracter)) {
          nuevaSolicitud[[e.target.name]] = e.target.value.toUpperCase()

        }
    //    this.setState({ banderaCurp: false })
        if (curp.length == 18) {
         // this.setState({ banderaCurp: true })
        }
      }
      if (curp.length == 18) {
      //  let val = this.state.validaciones
        //val.validaCURP = false
        //this.setState({ banderaCurp: true, validaciones: validaciones })
      }
    }


    if (e.target.name == "numeroCelular") {

      nuevaSolicitud["numeroCelular"] = quitaEspeciales(value)

    }


    if (e.target.name != "rfc" && e.target.name != "numeroCelular" && e.target.name != "curp") {
      nuevaSolicitud[[name]] = value
    }



    setJsonFormulario((nuevaSolicitud) => ({
      ...nuevaSolicitud
    }));
  };


  const postEvaluaDatosDeuda = async (idPlazo) => {
    let jsonConfConvenioSalida = jsonConfConvenio
    console.log("monto a salir ", jsonConfConvenioSalida, precioAutoTotal)
    try {
      const response = await fetch("https://wedoplus.mx:8099/portales/wedo_capacidad_pago/0", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "tipo_calculo": 0,
          "producto": jsonConfConvenioSalida.idProductoConvenio,
          "tipo_producto": jsonConfConvenioSalida.idTipoProducto,
          "plazo": idPlazo,
          "frecuencia": jsonConfConvenioSalida.idPeriodo,
          "monto_solicitado": jsonConfConvenioSalida.valorFinal/*-totalEnganche*/,
          "capacidad_pago": 0
        }),
      });

      if (!response.ok) {
        throw new Error("Error en la petición");
      }

      const result = await response.json();
      console.log("selector de plaxo ", result)
      if (result[0].sucesso == 0) {
        setCantidadPagos(result[0].descuento)
      }

    } catch (error) {
      console.log("el error esta aqui  ", error)
    } finally {
    }
  }

  const onClickCambiaImagenCarousel1 = (imagenNueva, imagenNueva2) => {
    setImagenCarousel1(imagenNueva)
    setImagen1Carousel1(imagenNueva2)

  }

  const onClickCotizarAhora = () => {
    console.log(jsonFormulario)
    const {
      nombreCompleto,
      curp,
      rfc,
      alcaldiaMunicipio,
      correoElectronico,
      numeroCelular
    } = jsonFormulario

    const {
      idSector,
      idProductoConvenio,
      idTipoProducto,
    } = jsonConfConvenio

    console.log("jsonConfConvenio ", jsonConfConvenio)



    if (nombreCompleto == "" || rfc == "" || curp =="" || alcaldiaMunicipio == "" || correoElectronico == "" || numeroCelular == "" || idSector == null || idProductoConvenio == null || idTipoProducto == null) {

      setValidacionDatosVacios(true)
      setShowDatosAutos(false)
      setTextoValidacionFormulario("Debes ingresar todos los datos de este formulario")
    }

    if (nombreCompleto != "" && rfc != "" && curp != "" && alcaldiaMunicipio != "" && correoElectronico != "" && numeroCelular != "" && idSector != null && idProductoConvenio != null && idTipoProducto != null) {
      if (expresionEmail.test(correoElectronico)) {
        setShowModalLoader(true)

        setImagenCarousel1(aveo)
        setImagen1Carousel1(aveo)
        setNombreAnoAutoSeleccionado("Chrevrolet Aveo 2025")
        setPrecioAutoSeleccionado("$300,000")
        setValidacionDatosVacios(false)
        setShowDatosAutos(true)
        setTimeout(() => { setShowModalLoader(false) }, 1000)

      } else {
        setValidacionDatosVacios(true)
        setShowDatosAutos(false)
        setTextoValidacionFormulario("Debes ingresar correo valido !")

      }
    }

  }


  const onClickLoQuiero = async () => {
    setShowModalLoader(true)

    console.log("jsonConfConvenio ", jsonConfConvenio)
    console.log("jsonFormulario ", jsonFormulario)


    const {
      nombreCompleto,
      rfc,
      curp,
      alcaldiaMunicipio,
      correoElectronico,
      numeroCelular
    } = jsonFormulario

    const {
      idSector,
      idProductoConvenio,
      idTipoProducto,
    } = jsonConfConvenio

    console.log("jsonConfConvenio ", jsonConfConvenio)



    if (nombreCompleto == "" || rfc == "" || curp =="" || alcaldiaMunicipio == "" || correoElectronico == "" || numeroCelular == "" || idSector == null || idProductoConvenio == null || idTipoProducto == null) {
      setTimeout(() => {
        setShowModalLoader(false)
        setMensajeError("Debes revisar que todos los campos del formulario de tus datos estan llenos ");
        setShowModalError(true)
      }, 500)
    } else if (jsonConfConvenio.idUnidad == null) {
      setTimeout(() => {
        setShowModalLoader(false)
        setMensajeError("Debes seleccionar una unidad desde el buscador de autos o revisar que la busqueda haya devuelto informacion del auto! ");
        setShowModalError(true)
      }, 500)
    } else if (jsonConfConvenio.idPeriodo == null || jsonConfConvenio.idPeriodo == "Periodo") {
      setTimeout(() => {
        setShowModalLoader(false)
        setMensajeError("Debes seleccionar un periodo de pagos ! ");
        setShowModalError(true)
      }, 500)
    } else if (jsonConfConvenio.idPlazo == null || jsonConfConvenio.idPlazo == "Plazo") {
      setTimeout(() => {
        setShowModalLoader(false)
        setMensajeError("Debes seleccionar el plazo al que se pagara el auto! ");
        setShowModalError(true)
      }, 500)
    } else {
      try {
        const response = await fetch("https://wedoplus.mx:8099/autos/cotizacion/0", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "rfc":rfc,
            "celular":numeroCelular,
            "curp":curp,
            "sector": jsonConfConvenio.idSector,
            "producto": jsonConfConvenio.idProductoConvenio,
            "tipo_producto": jsonConfConvenio.idTipoProducto,
            "periodo": jsonConfConvenio.idPeriodo,
            "plazo": jsonConfConvenio.idPlazo,
            "unidad": jsonConfConvenio.idUnidad,
            "costo_unidad": jsonConfConvenio.costoUnidad,//200000.00,
            "enganche": enganche,
            "financiamiento": precioAutoTotal,
            "pago": cantidadPagos,
            "total": totalAuto,
            "estatus": 1,
            "user_id": props.idUsuario,
            "correo_electronico": jsonFormulario.correoElectronico
          }),
        });

        if (!response.ok) {
          setTimeout(() => {
            setShowModalLoader(false)
            setMensajeError("Error en la petición");
            setShowModalError(true)
          }, 500)
          throw new Error("Error en la petición");
        }else{
          const result = await response.json();
          setTimeout(() => {
            setShowModalLoader(false)
            setMensajeExito("Se ha guardado tu cotizacion !");
            setShowModalExito(true);
            if(props.regresarABandeja != undefined){
              props.regresarABandeja()
            }
          }, 500)
        }


      } catch (Error) {
        setTimeout(() => {
          setShowModalLoader(false)
          setMensajeError("Error en la petición "+Error);
          setShowModalError(true)
        }, 500)
      }
    }






  }

  const esLetra = (caracter) => {

    let bandera = false
    if (caracter != undefined) {
      let ascii = caracter.toUpperCase().charCodeAt(0);
      console.log("el caracter es ------ ", ascii)
      bandera = ascii > 64 && ascii < 91
    }
    return bandera;
  }

  const esNumero = (caracter) => {
    console.log("el caracter es ------ ", caracter)
    let bandera = false
    if (caracter != undefined) {
      if (caracter == '0' || caracter == '1' || caracter == '2' || caracter == '3' || caracter == '4' || caracter == '5' || caracter == '6' || caracter == '7' || caracter == '8' || caracter == '9') {
        bandera = true
      }
    }
    return bandera;
  }

  const quitaEspeciales = (valor) => {
    let finalString = valor.replace(/[^0-9]/g, '')
    return finalString
  }



  const [downPayment, setDownPayment] = useState(0);
  const [financing, setFinancing] = useState("100%");


  return (
    <>
      <br />
      <br />

      <Container fluid>
 
        <Row>
          <Col
            xs={12} md={12} lg={12}
            style={{
              backgroundImage: `url(${fondo})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              minHeight: "400px", // Ajusta la altura según necesites
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center"
            }}
          >
            <Container fluid className="vh-100 d-flex flex-column flex-md-row position-relative">
              <Col lg={1} md={1} xs={0}>&nbsp;</Col>
              {/* Sección izquierda */}

              <Col lg={5} md={5} xs={12} className="d-flex align-items-center justify-content-center position-relative text-white p-4">

                <div className="position-relative w-100 w-md-75 p-4 rounded" style={{ border: "1px solid white", borderRadius: "30px", backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
                  <h2 className="fw-bold text-center">Cotiza tu nuevo auto.</h2>

                  <p className="text-center">Hola : Alejandro Narvarte Marquez</p>
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <img
                        src={fondoBajoForm}
                        alt=""
                        style={{
                          width: "100%",
                          maxHeight: "85px", // Limita la altura en móviles
                          objectFit: "contain", // Ajusta la imagen sin recortarla
                          display: "block", // Evita espacios extra
                          margin: "auto" // Centra la imagen
                        }}
                      />
                    </Col>
                  </Row>
                  {/* <Form>
                    <Button className="mt-3 w-100" style={{ border: "3px solid #FF7802", borderRadius: "30px", backgroundColor: "#FF7802" }}>Sucursal numero 1</Button>
                  </Form>*/}

                </div>
              </Col>
              <Col lg={1} md={1} xs={0}>&nbsp;</Col>
              {/* Sección derecha */}
              <Col
                lg={4} md={5} xs={12}
                className="d-flex align-items-center justify-content-center text-center p-4"
                style={{ backgroundColor: "#FF7802", overflow: "hidden" }} // Evita desbordamiento
              >
                <div className="w-100 w-md-100">

                  {/* Título con margen para evitar que se desborde arriba */}
                  <h4
                    className="fw-bold"
                    style={{
                      color: "white",
                      marginTop: "10px", // Espacio para que no se desborde
                      minHeight: "40px" // Asegura que se mantenga en su lugar
                    }}
                  >
                    Queremos saber un poco más de ti
                  </h4>

                  <Form>
                    <Form.Group className="mb-2">
                      <Form.Control style={{ border: "3px solid #FF7802", borderRadius: "30px", backgroundColor: "white" }} value={jsonFormulario.nombreCompleto} onChange={onChangeValues} name="nombreCompleto" type="text" maxLength={150} placeholder="Nombre completo" />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Col>
                        <Form.Control style={{ border: "3px solid #FF7802", borderRadius: "30px", backgroundColor: "white" }} value={jsonFormulario.rfc} onChange={onChangeValues} name="rfc" type="text" maxLength={13} placeholder="RFC (con homoclave)" />
                      </Col>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Col>
                        <Form.Control style={{ border: "3px solid #FF7802", borderRadius: "30px", backgroundColor: "white" }}
                         value={jsonFormulario.curp} onChange={onChangeValues} name="curp" type="text" maxLength={18} placeholder="CURP " />
                      </Col>
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Col><Form.Control style={{ border: "3px solid #FF7802", borderRadius: "30px", backgroundColor: "white" }} value={jsonFormulario.alcaldiaMunicipio} onChange={onChangeValues} name="alcaldiaMunicipio" type="text" maxLength={50} placeholder="Alcaldía o municipio" /></Col>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Control style={{ border: "3px solid #FF7802", borderRadius: "30px", backgroundColor: "white" }} value={jsonFormulario.correoElectronico} onChange={onChangeValues} name="correoElectronico" type="text" maxLength={50} placeholder="Correo electrónico" />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Control style={{ border: "3px solid #FF7802", borderRadius: "30px", backgroundColor: "white" }} value={jsonFormulario.numeroCelular} onChange={onChangeValues} name="numeroCelular" maxLength={10} type="tel" placeholder="Número de celular" />
                    </Form.Group>
                    <div className=" p-2 rounded-pill ">
                      <Row className="g-2 align-items-center">
                        {/* sector */}
                        <Col xs={12} md={12}>
                          {selectSector}
                        </Col>


                        <Col xs={12} md={12}>
                          {selectProducto}
                        </Col>


                        <Col xs={12} md={12}>
                          {selectTipoProducto}
                        </Col>



                      </Row>
                    </div>


                    <Button
                      className="mt-3 w-100"
                      style={{
                        border: "3px solid #001E4C",
                        borderRadius: "30px",
                        backgroundColor: "#001E4C"
                      }}
                      disabled={showDatosAutos}
                      onClick={onClickCotizarAhora}
                    >
                      Cotiza ahora
                    </Button>

                    <Button
                      className="mt-3 w-100"
                      style={{
                        border: "2px solid #001E4C",
                        borderRadius: "30px",
                        backgroundColor: "#FF7802"
                      }}
                      disabled={showDatosAutos}
                      onClick={()=>{
                        setShowModalLoader(true)
                        props.regresarABandeja()
                      }}
                    >
                      Regresar a bandeja
                    </Button>
                  </Form>

                  {
                    validacionDatosVacios ?
                      <>
                        <br />
                        <Row>
                          <Col xs={0} md={0} lg={0}>&nbsp;</Col>
                          <Col xs={12} md={12} lg={12} style={{
                            color: "red", display: "flex",
                            alignItems: "center", justifyContent: "center"
                          }}><h6>{textoValidacionFormulario}</h6></Col>
                          <Col xs={0} md={0} lg={0}>&nbsp;</Col>
                        </Row>
                      </> : ""
                  }


                  <br />

                  {/* Imagen inferior ajustada para evitar desbordamiento */}

                  {/*   <Row>
                    <Col lg={12} md={12} xs={12}>
                      <img
                        src={fondoBajoForm}
                        alt=""
                        style={{
                          width: "100%",
                          maxHeight: "85px", // Limita la altura en móviles
                          objectFit: "contain", // Ajusta la imagen sin recortarla
                          display: "block", // Evita espacios extra
                          margin: "auto" // Centra la imagen
                        }}
                      />
                    </Col>
                  </Row>*/}
                </div>
              </Col>


            </Container>
          </Col>
        </Row>


        {showDatosAutos ? <>

          <Row>
            <Col xs={12} md={12} lg={12} style={{ backgroundColor: "#E6EDF3" }}>
              {/*Titulo */}
              <Row>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
                <Col xs={12} md={4} lg={4} style={{
                  color: "#001E4C", display: "flex",
                  alignItems: "center", justifyContent: "center"
                }}><h3>Elige tu </h3>&nbsp;<h3 style={{ fontWeight: "bold" }}> auto nuevo</h3></Col>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
              </Row>
              {/*Buscador */}
              <Row>
                <Col xs={12} md={12} lg={12}>

                  <div className="container " style={{ backgroundColor: "#E6EDF3" }}>
                    <div className=" p-2 rounded-pill ">
                      <Row className="g-2 align-items-center">

                        {/* Marca */}
                        <Col xs={12} md={3}>
                          {selectMarca}
                        </Col>

                        {/* Separador (solo en pantallas grandes) */}
                        <Col md="auto" className="d-none d-md-block">
                          <div className="border-start"></div>
                        </Col>

                        {/* Modelo */}
                        <Col xs={12} md={3}>
                          {selectModelo}
                        </Col>

                        <Col md="auto" className="d-none d-md-block">
                          <div className="border-start"></div>
                        </Col>

                        {/* Versión */}
                        <Col xs={12} md={3}>
                          {selectVersion}
                        </Col>

                        <Col md="auto" className="d-none d-md-block">
                          <div className="border-start"></div>
                        </Col>

                        {/* Año */}
                        <Col xs={12} md={2}>
                          {selectAnio}
                        </Col>

                        {/* Botón de búsqueda 
                        <Col xs={12} md="auto" className="text-center">
                          <Button
                            variant="primary"
                            className=" d-flex align-items-center justify-content-center mx-auto"
                            style={{ backgroundColor: "#001E4C", border: "none" }}
                          >
                            Buscar
                          </Button>
                        </Col>*/}

                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              {/*carousel */}
              <br />
              <Carousel data-bs-theme="dark" activeIndex={index} onSelect={handleSelect}>
                {pilaAutoBuscado}
              </Carousel>

              <br />
              <br />
              {/*posibles gustos */}
              <Row>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
                <Col xs={12} md={4} lg={4} style={{
                  color: "#001E4C", display: "flex",
                  alignItems: "center", justifyContent: "center", fontWeight: "bold"
                }}><p>Modelos que quizá te gusten</p></Col>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
              </Row>
              <Row>
                <Carousel data-bs-theme="dark" activeIndex={indexGusto} onSelect={handleSelectGusto}>
                  {

                    [kwid, march, polo, aveo].map((image, index) => (

                      <Carousel.Item >
                        <Row>
                          <Col
                            key={index}
                            xs={12} md={12} lg={12}
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                          >
                            <Card
                              style={{
                                width: "100%",

                                borderRadius: "20px",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Sombra suave
                                border: "none",
                                padding: "0px", // Espaciado interno
                              }}
                            >
                              <div style={{ borderRadius: "20px", overflow: "hidden" }}>
                                <Card.Img
                                  variant="top"
                                  src={image}
                                  style={{
                                    width: "100%",
                                    height: "250px", // Ajusta la altura para un look uniforme
                                    objectFit: "contain", // Mantiene la proporción sin recortes
                                    backgroundColor: "#E0E0E0", // Fondo claro similar a la imagen de referencia
                                    padding: "10px",
                                  }}
                                  onClick={() => {
                                    console.log(index)
                                    if (index == 0) {
                                      setPrecioAutoSeleccionado("$350,000")
                                      setNombreAnoAutoSeleccionado("Renault Duster 2022")
                                    }
                                    if (index == 1) {
                                      setPrecioAutoSeleccionado("$285,000")
                                      setNombreAnoAutoSeleccionado("Mazda 2 2020")
                                    }
                                    if (index == 2) {
                                      setPrecioAutoSeleccionado("$320,000")
                                      setNombreAnoAutoSeleccionado("Volkswagen Polo 2019")
                                    }
                                    if (index == 3) {
                                      setNombreAnoAutoSeleccionado("Chrevrolet Aveo 2025")
                                      setPrecioAutoSeleccionado("$300,000")
                                    }

                                    onClickCambiaImagenCarousel1(image, arrIMG[index])
                                  }}
                                />
                              </div>
                              <Card.Body style={{}}>
                                <Card.Title
                                  style={{

                                    color: "#A0A0A0",
                                    textAlign: "center",
                                    fontWeight: "500",
                                    fontSize: "14px"
                                  }}
                                >
                                  Modelo de Auto
                                </Card.Title>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Carousel.Item>
                    ))
                  }
                </Carousel>

              </Row>
            </Col>
          </Row>


          <Row>
            <Col xs={12} md={12} lg={12} style={{ backgroundColor: "white" }}>
              {/*Titulo */}
              <br />
              <Row>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
                <Col xs={12} md={4} lg={4} style={{
                  color: "#001E4C", display: "flex",
                  alignItems: "center", justifyContent: "center"
                }}><h3>Cotiza en </h3>&nbsp;<h3 style={{ fontWeight: "bold" }}> tiempo real</h3></Col>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
              </Row>
              <br />
            </Col>
          </Row>


          <Row>
            <Col xs={12} md={12} lg={12} style={{ backgroundColor: "#E6EDF3" }}>
              {/*Titulo */}
              <br />
              <Row>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
                <Col xs={12} md={4} lg={4} style={{
                  color: "#001E4C", display: "flex",
                  alignItems: "center", justifyContent: "center"
                }}><h1 style={{ fontWeight: "bold", color: "#A0A0A0" }}>{precioAutoSeleccionado}</h1></Col>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
              </Row>

              <Row>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
                <Col xs={12} md={4} lg={4} style={{
                  color: "#001E4C", display: "flex",
                  alignItems: "center", justifyContent: "center"
                }}><h3 style={{ fontWeight: "bold", color: "#A0A0A0" }}>{nombreAnoAutoSeleccionado}</h3></Col>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
              </Row>
              <br />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12} style={{ backgroundColor: "#E6EDF3" }}>
              <Container fluid className="py-3">
                <Row className="justify-content-center">
                  {/* Tarjeta de Enganche */}
                  <Col xs={12} md={5} lg={4} className="mb-3">
                    <Card className="shadow-sm rounded-lg p-3">
                      <Card.Body>
                        <Card.Title className="text-muted small">Enganche:</Card.Title>
                        <h2 className="text-primary">${mascaraDecimales(enganche)}  </h2>
                        <Form.Range
                          value={downPayment}
                          min={0}
                          max={totalAuto / 1000}
                          step={1}
                          onChange={(e) => {
                            let jsonConfConvenioSalida = jsonConfConvenio
                            setDownPayment(e.target.value)
                            setEnganche(e.target.value * 1000)
                            setPrecioAutoTotal(totalAuto - (e.target.value * 1000))
                            jsonConfConvenioSalida.valorFinal = totalAuto - (e.target.value * 1000)
                            if (jsonConfConvenioSalida.idPlazo != null) {
                              setTimeout(() => {
                                postEvaluaDatosDeuda(jsonConfConvenioSalida.idPlazo)
                              }, 100)

                            }


                          }}
                          className="custom-range"
                        />
                      </Card.Body>
                    </Card>
                  </Col>

                  {/* Tarjeta de Gastos y Financiamiento */}
                  <Col xs={12} md={6} lg={5} className="mb-3">
                    <Card className="shadow-sm rounded-lg p-3">
                      <Card.Body>
                        <Row>
                          <Col xs={6}>
                            <Card.Title className="text-muted small">Gastos de gestoría:</Card.Title>
                            <h2 className="text-primary">{"$" + mascaraDecimales(gestoria)}</h2>
                          </Col>



                        </Row>
                        <Row>
                          <Col xs={6}>
                            &nbsp;
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>


          <Row>
            <Col xs={12} md={12} lg={12} style={{ backgroundColor: "white" }}>
              {/*Titulo */}
              <br />
              <Row>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
                <Col xs={12} md={4} lg={4} style={{
                  color: "#001E4C", display: "flex",
                  alignItems: "center", justifyContent: "center"
                }}><h3>Servicios de </h3>&nbsp;<h3 style={{ fontWeight: "bold" }}> gestoría</h3></Col>
                <Col xs={0} md={4} lg={4}>&nbsp;</Col>
              </Row>
              <br />
            </Col>
          </Row>




          <Row>
            <Col xs={12} md={12} lg={12} style={{ backgroundColor: "white" }}>

              <Row>


              </Row>
              {<Container fluid className="py-3">
                <Row className="justify-content-center text-center text-muted">
                  <Col xs={12} md="auto">
                    <div>
                      <img src={seguro} />

                    </div>
                  </Col>

                  <Col xs="auto" className="d-none d-md-block">|</Col>

                  <Col xs={12} md="auto">
                    <div>
                      <img src={tenencia} />

                    </div>
                  </Col>

                  <Col xs="auto" className="d-none d-md-block">|</Col>

                  <Col xs={12} md="auto">
                    <div>
                      <img src={altaPlacas} />

                    </div>
                  </Col>

                  <Col xs="auto" className="d-none d-md-block">|</Col>

                  <Col xs={12} md="auto">
                    <div>
                      <img src={verificacion} />

                    </div>
                  </Col>

                  <Col xs="auto" className="d-none d-md-block">|</Col>

                  <Col xs={12} md="auto">
                    <div>
                      <img src={tCirculacion} />

                    </div>
                  </Col>
                </Row>




              </Container>}
            </Col>
          </Row>


          <Row>
            <Col xs={12} md={12} lg={12} style={{ backgroundColor: "#E6EDF3" }}>

              <Row>
                <Col xs={0} md={0} lg={0}>&nbsp;</Col>
                <Col xs={12} md={12} lg={12} style={{
                  color: "#001E4C", display: "flex",
                  alignItems: "center", justifyContent: "center"
                }}><h3>Resultados </h3>&nbsp;<h3 style={{ fontWeight: "bold" }}> finales</h3></Col>
                <Col xs={0} md={0} lg={0}>&nbsp;</Col>
              </Row>

            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} lg={12} style={{ backgroundColor: "#E6EDF3" }}>
              <div className="container " style={{ backgroundColor: "#E6EDF3" }}>
                <div className=" p-2 rounded-pill ">
                  {/*<Row className="g-2 align-items-center">
                    <Col xs={12} md={4}>
                      {selectSector}
                    </Col>


                    <Col xs={12} md={4}>
                      {selectProducto}
                    </Col>


                    <Col xs={12} md={4}>
                      {selectTipoProducto}
                    </Col>



                  </Row>*/}

                  <Row className="g-2 align-items-center">
                    <Col xs={12} md={3}>
                      &nbsp;
                    </Col>

                    <Col xs={12} md={3}>
                      {selectPeriodo}
                    </Col>

                    <Col xs={12} md={3}>
                      {selectPlazo}
                    </Col>

                    <Col xs={12} md={3}>
                      &nbsp;
                    </Col>


                    {/*<Col xs={12} md={4}>
                      {<Form.Group className="mb-6" controlId="">
                        <Form.Control type="text" placeholder="Capacidad de pago :" value={capacidadPago} name="capPago" onChange={onChangeValues} />

                      </Form.Group>}
                    </Col>*/}
                  </Row>
                  <br />
                  <Row className="g-2 align-items-center">
                    <Col xs={12} md={12} lg={12} style={{ backgroundColor: "#E6EDF3" }}>
                      <Container fluid className="py-3">
                        <Row className="text-center text-white">
                          {/* Sección 1 - Total */}
                          <Col xs={12} md={3} className="p-4" style={{ backgroundColor: "#E9EFF5" }}>
                            <p className="text-dark fw-bold">Total:</p>
                            <h3 className="text-primary fw-bold">{"$" + precioAutoTotal}</h3>
                          </Col>

                          {/* Sección 2 - Pagos Quincenales */}
                          <Col xs={12} md={3} className="p-4" style={{ backgroundColor: "#FF7A00" }}>
                            <p className="fw-bold">Pagos quincenales de:</p>
                            <h3 className="fw-bold">{"$" + cantidadPagos}</h3>
                          </Col>

                          <Col xs={12} md={3} className="p-4" style={{ backgroundColor: "#E9EFF5", color: "#00245D" }}>
                            <p className="fw-bold">Envíar cotización</p>
                            <h3 className="fw-bold">@</h3>
                          </Col>

                          {/* Sección 3 - Botón */}
                          <Col xs={12} md={3} className="p-4 d-flex align-items-center justify-content-center hover-effect" style={{ backgroundColor: "#00245D" }}>
                            <Button variant="link" className="text-white fw-bold text-decoration-none" onClick={onClickLoQuiero}>
                              ¡Lo quiero! →
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>


                </div>
              </div>

            </Col>
          </Row>




        </> : ""}

      </Container>

      {/* venatnas modaldes */}

      <Modal backdrop="static"
        keyboard={false}
        show={showModalLoader} onHide={() => { setShowModalLoader(false) }} centered size="lg">
        <Modal.Header style={{ backgroundColor: "#FF6B00", textAlign: 'center' }} >
          <Modal.Title style={{ textAlign: 'center', color: 'white' }}>{"Procesando información, por favor espera…"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <LinearProgress color="secondary" />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      {/* modal de errores  */}
      <Modal show={showModalError} onHide={() => { setShowModalError(false) }} centered size="lg">
        <Modal.Header style={{ backgroundColor: "red", textAlign: 'center' }} closeButton>
          <Modal.Title style={{ textAlign: 'center', color: "white" }}>{"Opps, revisa el mensaje de error !"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>{mensajeError}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModalError(false) }}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showModalExito} onHide={() => { setShowModalExito(false) }} centered size="lg">
        <Modal.Header style={{ backgroundColor: "#27F45E", textAlign: 'center' }} closeButton>
          <Modal.Title style={{ textAlign: 'center', color: "white" }}>{"Cotizacion guardada !"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>{mensajeExito}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModalExito(false) }}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>



    </>
  );
};

export default CatalogoAutos;
