// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clase-tabla:hover{
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-color: #ff6b00;
    color:#ff6b00;
    
    

}`, "",{"version":3,"sources":["webpack://./src/componentes/contenedores/ticket.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,qBAAqB;IACrB,aAAa;;;;AAIjB","sourcesContent":[".clase-tabla:hover{\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);\n    border-color: #ff6b00;\n    color:#ff6b00;\n    \n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
