import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Fab from '@mui/material/Fab';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


import conexiones from '../urls/conexiones.json'

export default class TicketsForm extends Component {

  constructor() {
    super();
    this.onChangeValues = this.onChangeValues.bind(this)
    this.insertaArchivo = this.insertaArchivo.bind(this)
    this.guardaArchivo = this.guardaArchivo.bind(this)
    this.state = {
      colorErrorModal: '#F83C3C',
      colorSuccesModal: '#27F45E',
      colorModal: '',
      tituloModal: '',
      mensajeError: '',
      showModal: false,
      validaciones: {
        validaObservaciones: true,
      },
      nuevaSolicitud: {
        sector: null,
        producto: null,
        observaciones: ""
      },
      contadorArchivos: 1,
      archivos: [
        {
          archivo: null,
          esCargado: false,
          nombreArchivo: ""
        },
        {
          archivo: null,
          esCargado: false,
          nombreArchivo: ""
        },
        {
          archivo: null,
          esCargado: false,
          nombreArchivo: ""
        }],
      selectSector: [],
      selectProducto: [],


    }
  }


  UNSAFE_componentWillMount() {
    this.getCatalogo(conexiones.urlPytonParametros + "catalogo_sector/0", "selectSector")

  }
  async getCatalogo(url, selector) {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const json = await response.json();
        console.log(json)
        this.armaSelect(json.filas, selector)
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  armaSelect(filas, selector) {
    let select = []
    let options = []
    options.push(<option value={null}>Seleccione...</option>)
    for (var i = 0; i < filas.length; i++) {
      let fila = filas[i].fila
      if (selector == "selectProducto") {
        console.log("armano el select prodcuto ", this.state.nuevaSolicitud.producto, fila[0].value)
        if (this.state.nuevaSolicitud.producto == fila[0].value) {
          options.push(<option selected value={fila[0].value + ":" + fila[8].value}>{fila[1].value}</option>)
        } else {
          options.push(<option value={fila[0].value + ":" + fila[8].value}>{fila[1].value}</option>)
        }
      } else {
        options.push(<option value={fila[0].value}>{fila[1].value}</option>)
      }
    }
    select.push(<Form.Select name={selector} onChange={this.onChangeValues}   >{options}</Form.Select>)
    this.setState({ [selector]: select })
  }

  onChangeValues = e => {
    let validaciones = this.state.validaciones
    let nuevaSolicitud = this.state.nuevaSolicitud
    if (e.target.name == "selectSector") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaSector = true
        nuevaSolicitud["producto"] = null
        this.setState({ validaciones: validaciones, selectProducto: [], banderaSector: false })
      } else {
        validaciones.validaSector = false
        nuevaSolicitud["producto"] = null
        this.setState({ selectProducto: [], nuevaSolicitud: nuevaSolicitud })
        this.getCatalogo(conexiones.urlPytonParametros + "catalogo_producto/" + e.target.value, "selectProducto")

        this.setState({ validaciones: validaciones, banderaSector: true })
      }
      nuevaSolicitud["sector"] = parseInt(e.target.value)
    }
    if (e.target.name == "selectProducto") {
      let sector = e.target.value;
      if (sector == null || sector == "Seleccione...") {
        validaciones.validaProducto = true
        this.setState({ validaciones: validaciones })
      } else {
        validaciones.validaProducto = false
        this.setState({ validaciones: validaciones })
      }
      let res = e.target.value
      if (res.split(":").length > 0) {
        nuevaSolicitud["producto"] = res.split(":")[0]
        //  this.validaBanderaPortales(nuevaSolicitud["producto"])

        //  this.setState({ producto_id_en_zell: res.split(":")[1] })
      }
      //  nuevaSolicitud["producto"] = e.target.value
    }

    if (e.target.name == "observaciones") {
      nuevaSolicitud["observaciones"] = e.target.value
      validaciones.validaObservaciones = nuevaSolicitud["observaciones"] == "" ? true : false
    }
    this.setState({ nuevaSolicitud: nuevaSolicitud, validaciones: validaciones })
  }

  insertaArchivo(contadorArchivo, archivo) {
    let archivos = this.state.archivos
    archivos[contadorArchivo].archivo = archivo
    archivos[contadorArchivo].esCargado = archivo != null ? true : false
    archivos[contadorArchivo].nombreArchivo = archivo != null ? archivo.name : ""
    console.log("entro al onchange ", archivos)
    this.setState({ archivos: archivos })
  }


  async guardaArchivo(sector, producto, observaciones, idUsuario, contadorArchivos, archivos) {
    let formData = new FormData();
    formData.append("sector_id", sector)
    formData.append("producto_id", producto)
    formData.append("observaciones", observaciones)
    formData.append("user_id", idUsuario)
    formData.append("actualizar", 0)
    formData.append("documento", 153)
    let cont=1
    if (contadorArchivos == 1) {
      formData.append("imagen1", archivos[0].archivo)
    }


    if (contadorArchivos == 2) {
      formData.append("imagen1", archivos[0].archivo)
      if (archivos[1].archivo != null) {
        formData.append("imagen2", archivos[1].archivo)
        cont=2
      }
    }

    if (contadorArchivos == 3) {
      formData.append("imagen1", archivos[0].archivo)
      if (archivos[1].archivo != null) {
        formData.append("imagen2", archivos[1].archivo)
        cont=2
      }
      if (archivos[2].archivo != null) {
        formData.append("imagen3", archivos[2].archivo)
        cont=3
      }
    }

    formData.append("total_img", cont)


    const res = await fetch(
      conexiones.urlAltaTickets,
      {
        method: 'POST',
        body: formData,
      },
    );
    const resData = await res.json();
    console.log("respues del serv al levatnar un ticket ", resData);
    if(resData.length> 0){
      if(resData[0].cargado == 1){
        this.setState({ colorModal: this.state.colorSuccesModal,
          tituloModal: 'Atención!', mensajeError:resData[0].estatus , showModal: true })
          setTimeout(()=>{
            this.props.regresaBandeja()
          },1500)
      }else{
        this.setState({ colorModal: this.state.colorErrorModal,
           tituloModal: 'Atención!', mensajeError:resData[0].estatus , showModal: true })
      }
    }


  }

  render() {
    return (
      <div>

        <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} centered size="lg">
          <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { this.setState({ showModal: false }) }}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>

        <Row>
          <Col xs={6} md={8} lg={8} style={{ display: 'flex' }}>
            &nbsp;
          </Col>
          <Col xs={6} md={4} lg={4} style={{ display: 'flex' }}>
            <Fab variant="extended" style={{ backgroundColor: '#FF6B00', color: "white", width: "100%" }} onClick={() => {
              this.setState({ muestraComponente: "pp" })
              setTimeout(() => { this.props.regresaBandeja() }, 10)

            }}>
              Listado de Tickets
            </Fab>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={3} md={3} xs={12}>
            <Form.Group className="mb-6" controlId="">
              <Form.Label>Sector: </Form.Label>
              {this.state.selectSector}

            </Form.Group>
          </Col>

          <Col lg={3} md={3} xs={12}>
            <Form.Group className="mb-6" controlId="">
              <Form.Label>Convenio: </Form.Label>
              {this.state.selectProducto}

            </Form.Group>
          </Col>

          <Col lg={6} md={6} xs={12}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Observaciones <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control as="textarea" rows={5} onChange={this.onChangeValues} name="observaciones" value={this.state.nuevaSolicitud.observaciones} />
              {this.state.validaciones.validaObservaciones == true ? (
                <Form.Label><span style={{ color: 'red' }}>Se debe ingresar una observacíon </span></Form.Label>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <br />

        <Row>
          <Col lg={6} md={6} xs={12}>
            <Row>

              <Col xs={6} md={6} lg={6} style={{ display: 'flex' }}>
                <Fab variant="extended" style={{ backgroundColor: '#2198dc', color: "white", width: "100%" }} onClick={() => {
                  if (this.state.contadorArchivos > 1) {
                    let archivos = this.state.archivos
                    archivos[this.state.contadorArchivos - 1].esCargado = false
                    archivos[this.state.contadorArchivos - 1].archivo = null
                    archivos[this.state.contadorArchivos - 1].nombreArchivo = ""
                    this.setState({ archivos: archivos, contadorArchivos: this.state.contadorArchivos - 1 })
                  }
                }}>
                  - Elimina ultimo archivo
                </Fab>
              </Col>
              <Col xs={6} md={6} lg={6} style={{ display: 'flex' }}>
                <Fab variant="extended" style={{ backgroundColor: '#05af6a', color: "white", width: "100%" }} onClick={() => {
                  if (this.state.contadorArchivos < 3) {
                    this.setState({ contadorArchivos: this.state.contadorArchivos + 1 })
                  }
                }}>
                  + Agrega un archivo
                </Fab>
              </Col>
            </Row>
          </Col>
        </Row>


        <br />
        {
          this.state.contadorArchivos == 1 ?
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label >Añade tu archivo numero 1 &nbsp; &nbsp; &nbsp; &nbsp;</Form.Label>
                  <div style={{ "position": "relative", "display": "inline-block" }}>
                    <input
                      type="file" accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                      id={"custom-file-input-1"}
                      style={{ display: 'none' }}
                      onChange={this.props.banderaDocumentos ? "" : (e) => {



                        this.insertaArchivo(0, e.target.files[0])

                      }}

                    />
                    <label htmlFor={"custom-file-input-1"} style={{
                      "display": "inline-block",
                      "padding": "5px 5px",
                      "backgroundColor": this.state.archivos[0].esCargado ? "#05af6a" : "#FF6B00",//documentos[i].enviado == 2 ? 'green':"red",// esCagardo == true || documentos[i].documento_ruta != null ? "#FF6B00" : "#2E64FE",
                      "color": "white",
                      "cursor": "pointer",
                      "borderRadius": "5px",
                      "border": "none",
                      "fontSize": "15px"
                    }}
                    >
                      <AttachFileIcon />
                    </label>
                    <Form.Label >&nbsp; &nbsp; &nbsp; &nbsp;{this.state.archivos[0].nombreArchivo}</Form.Label>
                  </div>

                </Form.Group>
              </Col>
            </Row>
            : ""
        }


        {
          this.state.contadorArchivos == 2 ?

            <Row>
              <Col lg={12} md={12} xs={12}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label >Añade tu archivo numero 1 &nbsp; &nbsp; &nbsp; &nbsp;</Form.Label>
                  <div style={{ "position": "relative", "display": "inline-block" }}>
                    <input
                      type="file" accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                      id={"custom-file-input-1"}
                      style={{ display: 'none' }}
                      onChange={this.props.banderaDocumentos ? "" : (e) => {



                        this.insertaArchivo(0, e.target.files[0])

                      }}

                    />
                    <label htmlFor={"custom-file-input-1"} style={{
                      "display": "inline-block",
                      "padding": "5px 5px",
                      "backgroundColor": this.state.archivos[0].esCargado ? "#05af6a" : "#FF6B00",//documentos[i].enviado == 2 ? 'green':"red",// esCagardo == true || documentos[i].documento_ruta != null ? "#FF6B00" : "#2E64FE",
                      "color": "white",
                      "cursor": "pointer",
                      "borderRadius": "5px",
                      "border": "none",
                      "fontSize": "15px"
                    }}
                    >
                      <AttachFileIcon />
                    </label>
                    <Form.Label >&nbsp; &nbsp; &nbsp; &nbsp;{this.state.archivos[0].nombreArchivo}</Form.Label>
                  </div>

                </Form.Group>
              </Col>
              <Col lg={12} md={12} xs={12}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label >Añade tu archivo numero 2 &nbsp; &nbsp; &nbsp; &nbsp;</Form.Label>
                  <div style={{ "position": "relative", "display": "inline-block" }}>
                    <input
                      type="file" accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                      id={"custom-file-input-2"}
                      style={{ display: 'none' }}
                      onChange={this.props.banderaDocumentos ? "" : (e) => {

                        this.insertaArchivo(1, e.target.files[0])
                      }}
                    />
                    <label htmlFor={"custom-file-input-2"} style={{
                      "display": "inline-block",
                      "padding": "5px 5px",
                      "backgroundColor": this.state.archivos[1].esCargado ? "#05af6a" : "#FF6B00",
                      "color": "white",
                      "cursor": "pointer",
                      "borderRadius": "5px",
                      "border": "none",
                      "fontSize": "15px"
                    }}
                    >
                      <AttachFileIcon />
                    </label>
                    <Form.Label >&nbsp; &nbsp; &nbsp; &nbsp;{this.state.archivos[1].nombreArchivo}</Form.Label>
                  </div>
                </Form.Group>
              </Col>
            </Row> : ""
        }



        {
          this.state.contadorArchivos == 3 ?

            <Row>
              <Col lg={12} md={12} xs={12}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label >Añade tu archivo numero 1 &nbsp; &nbsp; &nbsp; &nbsp;</Form.Label>
                  <div style={{ "position": "relative", "display": "inline-block" }}>
                    <input
                      type="file" accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                      id={"custom-file-input-1"}
                      style={{ display: 'none' }}
                      onChange={this.props.banderaDocumentos ? "" : (e) => {



                        this.insertaArchivo(0, e.target.files[0])

                      }}

                    />
                    <label htmlFor={"custom-file-input-1"} style={{
                      "display": "inline-block",
                      "padding": "5px 5px",
                      "backgroundColor": this.state.archivos[0].esCargado ? "#05af6a" : "#FF6B00",//documentos[i].enviado == 2 ? 'green':"red",// esCagardo == true || documentos[i].documento_ruta != null ? "#FF6B00" : "#2E64FE",
                      "color": "white",
                      "cursor": "pointer",
                      "borderRadius": "5px",
                      "border": "none",
                      "fontSize": "15px"
                    }}
                    >
                      <AttachFileIcon />
                    </label>
                    <Form.Label >&nbsp; &nbsp; &nbsp; &nbsp;{this.state.archivos[0].nombreArchivo}</Form.Label>
                  </div>

                </Form.Group>
              </Col>
              <Col lg={12} md={12} xs={12}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label >Añade tu archivo numero 2 &nbsp; &nbsp; &nbsp; &nbsp;</Form.Label>
                  <div style={{ "position": "relative", "display": "inline-block" }}>
                    <input
                      type="file" accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                      id={"custom-file-input-2"}
                      style={{ display: 'none' }}
                      onChange={this.props.banderaDocumentos ? "" : (e) => {

                        this.insertaArchivo(1, e.target.files[0])
                      }}
                    />
                    <label htmlFor={"custom-file-input-2"} style={{
                      "display": "inline-block",
                      "padding": "5px 5px",
                      "backgroundColor": this.state.archivos[1].esCargado ? "#05af6a" : "#FF6B00",
                      "color": "white",
                      "cursor": "pointer",
                      "borderRadius": "5px",
                      "border": "none",
                      "fontSize": "15px"
                    }}
                    >
                      <AttachFileIcon />
                    </label>
                    <Form.Label >&nbsp; &nbsp; &nbsp; &nbsp;{this.state.archivos[1].nombreArchivo}</Form.Label>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={12} md={12} xs={12}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label >Añade tu archivo numero 3 &nbsp; &nbsp; &nbsp; &nbsp;</Form.Label>
                  <div style={{ "position": "relative", "display": "inline-block" }}>
                    <input
                      type="file" accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                      id={"custom-file-input-3"}
                      style={{ display: 'none' }}
                      onChange={this.props.banderaDocumentos ? "" : (e) => {

                        this.insertaArchivo(2, e.target.files[0])
                      }}
                    />
                    <label htmlFor={"custom-file-input-3"} style={{
                      "display": "inline-block",
                      "padding": "5px 5px",
                      "backgroundColor": this.state.archivos[2].esCargado ? "#05af6a" : "#FF6B00",
                      "color": "white",
                      "cursor": "pointer",
                      "borderRadius": "5px",
                      "border": "none",
                      "fontSize": "15px"
                    }}
                    >
                      <AttachFileIcon />
                    </label>
                    <Form.Label >&nbsp; &nbsp; &nbsp; &nbsp;{this.state.archivos[2].nombreArchivo}</Form.Label>
                  </div>
                </Form.Group>
              </Col>
            </Row> : ""
        }



        <br />



        <Row>
          <Col xs={6} md={8} lg={8} style={{ display: 'flex' }}>
            &nbsp;
          </Col>
          <Col xs={12} md={12} lg={12} style={{ display: 'flex' }}>
            <Fab variant="extended" style={{ backgroundColor: '#FF6B00', color: "white", width: "100%" }} onClick={() => {
              let validaciones = this.state.validaciones
              let nuevaSolicitud = this.state.nuevaSolicitud
              console.log(this.state)
              if (nuevaSolicitud.observaciones.length == 0) {
                validaciones.validaObservaciones = true
                this.setState({ validaciones: validaciones })
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Atención!', mensajeError: "Debes agregar observaciones !", showModal: true })

              } else if (this.state.archivos[0].esCargado == false && this.state.archivos[0].archivo == null) {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Atención!', mensajeError: "Debes agregar al menos un archivo !", showModal: true })

              } else {
                this.guardaArchivo(nuevaSolicitud.sector== null ? 0:nuevaSolicitud.sector, nuevaSolicitud.producto== null ? 1:nuevaSolicitud.producto, nuevaSolicitud.observaciones,
                  this.props.idUsuario, this.state.contadorArchivos, this.state.archivos)
              }


            }}>
              Levantar Ticket
            </Fab>
          </Col>
        </Row>
      </div>
    )
  }

}