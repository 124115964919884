import React, { Component, CSSProperties } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import conexiones from '../urls/conexiones.json'
import Modal from 'react-bootstrap/Modal';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom';
import MoonLoader from "react-spinners/MoonLoader";
import Alert from 'react-bootstrap/Alert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import botonRegresar from '../imagenes/Boton_regresar.png'
import botonDescargarExpediente from '../imagenes/botonImprimir.png'
import botonEnviar from '../imagenes/Boton_enviar_verde.png'
import firma from '../imagenes/Envio Firma.png'
import LinearProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';


import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';



const carpeta = require("../imagenes/6329.jpg")


export default class DocumentosSolicitanteForm extends Component {

    constructor() {
        super();
        this.getSolicitud = this.getSolicitud.bind(this)
        this.getSolicitudEvaulaFirmaDigital = this.getSolicitudEvaulaFirmaDigital.bind(this)
        this.getDatosPersonales = this.getDatosPersonales.bind(this)
        this.getDatosDomicilio = this.getDatosDomicilio.bind(this)
        this.getDatosContacto = this.getDatosContacto.bind(this)
        this.getDatosBancarios = this.getDatosBancarios.bind(this)
        this.validaCamposRequeridosSolicitud = this.validaCamposRequeridosSolicitud.bind(this)
        this.cargaInformacionDocumentoASolicitar = this.cargaInformacionDocumentoASolicitar.bind(this)
        this.onChangeSeleccionaArchivo = this.onChangeSeleccionaArchivo.bind(this)
        this.buscaArchivo = this.buscaArchivo.bind(this)
        this.buscaArchivoExisente = this.buscaArchivoExisente.bind(this)
        this.buscaArchivoExisente = this.buscaSubArchivoExisente.bind(this)
        this.enviarConcatenacionDeArchivos = this.enviarConcatenacionDeArchivos.bind(this)
        this.onChangeSeleccionaSubArchivo = this.onChangeSeleccionaSubArchivo.bind(this)
        this.enviarArchivosMultiples = this.enviarArchivosMultiples.bind(this)
        this.mascaraDecimales = this.mascaraDecimales.bind(this)
        this.actualizarDecimales = this.actualizarDecimales.bind(this)
        this.generaDocumentoAMostrar=this.generaDocumentoAMostrar.bind(this)
        this.descargarDocumentoAMostrar=this.descargarDocumentoAMostrar.bind(this)
        this.state = {
            urlDocumentoMostrado:"",
            nombreDocAMostrar:"",
            showDocumentoImagen:false,
            showDocumentoPDF:false,
            showModaldocumentoFormato:false,
            documentoformato:"",
            idDXN:"",
            documentosRestantes: 0,
            showModalBorrar: false,
            idDocumentoAEliminar: null,
            descuento: "",
            montoSolicitado: "",
            montoSolicitadoLabel: "",
            banderaModificarDecimales: true,
            primerNombreDigital: "",
            segundoNombreDigital: "",
            primerApellidoDigital: "",
            segundoApellidoDigital: "",
            tipo_convenioURLFirma: "",
            showModalLoader: false,
            showModalFirmaDigital: false,
            showModalFirmaDigitalTexto: false,
            esqueletoReqZell: {

                "ErrorCode": 0,
                "ErrorDescription": null,
                "AppId": null,
                "FirstName": null,
                "SecondName": null,
                "FLastName": null,
                "SLastName": null,
                "OpIdType": null,
                "IdNumber": null,
                "TreasuryId": null,
                "StateId": null,
                "LegalId": null,
                "OpSex": 0,
                "OpNationality": 0,
                "OpMaritalStatus": 0,
                "OpMaritalRegime": 0,
                "BirthDate": null,
                "EMail": null,
                "Dependents": 0,
                "OpEducation": 0,
                "SpFirstName": null,
                "SpSecondName": null,
                "SpFLastName": null,
                "SpSLastName": null,
                "Mobile": null,
                "CountryId": null,
                "OpPropTypeAd": 0,
                "StreetAd": null,
                "ExtNumberAd": null,
                "IntNumberAd": null,
                "CornerAd": null,
                "NeighborhoodAd": null,
                "TownshipAd": null,
                "CountryIdAd": 0,
                "CityAd": null,
                "StateIdAd": null,
                "ZipAd": 0,
                "PhoneNumberAd": null,
                "YearsAd": 0,
                "MonthsAd": 0,
                "CompanyJo": null,
                "PayJo": 0,
                "PhoneNumberJo": null,
                "ExtensionNumberJo": null,
                "SectorIdJo": null,
                "SectorActivityIdJo": null,
                "BossJo": null,
                "PositionJo": null,
                "YearsJo": 0,
                "MonthsJo": 0,
                "OpEmployeeTypeJo": 0,
                "StreetJo": null,
                "ExtNumberJo": null,
                "IntNumberJo": null,
                "CornerJo": null,
                "NeighborhoodJo": null,
                "TownshipJo": null,
                "CountryIdJo": null,
                "CityJo": null,
                "StateIdJo": null,
                "ZipJo": 0,
                "OpTypeJo": 0,
                "Reference": null,
                "AfiliateId": "03",
                "DelegationId": null,
                "ModuleId": null,
                "Afiliation": null,
                "BranchId": null,
                "EmployeeId": null,
                "FinancingId": null,
                "Payments": 0,
                "Solicited": 0,
                "CampaignId": null,
                "OpDisposition": null,
                "Bank": null,
                "AccountNumber": null,
                "OpRelationType1": 0,
                "FirstNameRe1": null,
                "SecondNameRe1": null,
                "FLastNameRe1": null,
                "SLastNameRe1": null,
                "PhoneNumberRe1": null,
                "YearsRe1": 0,
                "MonthsRe1": 0,
                "StreetRe1": null,
                "ExtNumberRe1": null,
                "IntNumberRe1": null,
                "CornerRe1": null,
                "NeighborhoodRe1": null,
                "TownshipRe1": null,
                "CityRe1": null,
                "StateIdRe1": null,
                "CountryIdRe1": null,
                "ZipRe1": 0,
                "OpRelationType2": 0,
                "FirstNameRe2": null,
                "SecondNameRe2": null,
                "FLastNameRe2": null,
                "SLastNameRe2": null,
                "PhoneNumberRe2": null,
                "YearsRe2": 0,
                "MonthsRe2": 0,
                "StreetRe2": null,
                "ExtNumberRe2": null,
                "IntNumberRe2": null,
                "CornerRe2": null,
                "NeighborhoodRe2": null,
                "TownshipRe2": null,
                "CityRe2": null,
                "StateIdRe2": null,
                "CountryIdRe2": null,
                "ZipRe2": 0,
                "OpRelationType3": 0,
                "FirstNameRe3": null,
                "SecondNameRe3": null,
                "FLastNameRe3": null,
                "SLastNameRe3": null,
                "PhoneNumberRe3": null,
                "YearsRe3": 0,
                "MonthsRe3": 0,
                "StreetRe3": null,
                "ExtNumberRe3": null,
                "IntNumberRe3": null,
                "CornerRe3": null,
                "NeighborhoodRe3": null,
                "TownshipRe3": null,
                "CityRe3": null,
                "StateIdRe3": null,
                "CountryIdRe3": null,
                "ZipRe3": 0,
                "OpRelationType4": 0,
                "FirstNameRe4": null,
                "SecondNameRe4": null,
                "FLastNameRe4": null,
                "SLastNameRe4": null,
                "PhoneNumberRe4": null,
                "YearsRe4": 0,
                "MonthsRe4": 0,
                "StreetRe4": null,
                "ExtNumberRe4": null,
                "IntNumberRe4": null,
                "CornerRe4": null,
                "NeighborhoodRe4": null,
                "TownshipRe4": null,
                "CityRe4": null,
                "StateIdRe4": null,
                "CountryIdRe4": null,
                "ZipRe4": 0,
                "OpCommType1": 0,
                "CompanyCom1": null,
                "ReferenceCom1": null,
                "AmountCom1": 0,
                "BalanceCom1": 0,
                "YearsCom1": 0,
                "MonthsCom1": 0,
                "OpCommType2": 0,
                "CompanyCom2": null,
                "ReferenceCom2": null,
                "AmountCom2": 0,
                "BalanceCom2": 0,
                "YearsCom2": 0,
                "MonthsCom2": 0,
                "OpCommType3": 0,
                "CompanyCom3": null,
                "ReferenceCom3": null,
                "AmountCom3": 0,
                "BalanceCom3": 0,
                "YearsCom3": 0,
                "MonthsCom3": 0,
                "OpCommType4": 0,
                "CompanyCom4": null,
                "ReferenceCom4": null,
                "AmountCom4": 0,
                "BalanceCom4": 0,
                "YearsCom4": 0,
                "MonthsCom4": 0,
                "Income0": 0,
                "Income1": 0,
                "Income2": 0,
                "Income3": 0,
                "Income4": 0,
                "Income5": 0,
                "Income6": 0,
                "Income7": 0,
                "Income8": 0,
                "Income9": 0,
                "Expense0": 0,
                "Expense1": 0,
                "Expense2": 0,
                "Expense3": 0,
                "Expense4": 0,
                "Expense5": 0,
                "Expense6": 0,
                "Expense7": 0,
                "Expense8": 0,
                "Expense9": 0,
                "Dato0": null,
                "Dato1": null,
                "Dato2": null,
                "Dato3": null,
                "Dato4": null,
                "Dato5": null,
                "Dato6": null,
                "Dato7": null,
                "Dato8": null,
                "Dato9": null,
                "DestinationId": null,
                "Comment": null,
                "Disposition": 0,
                "OtherCredits": 0,
                "OpCreditType": null,
                "FirstPayment": null,
                "OpStatus": 0,
                "Restructure": ""

            },
            salidaHtmlBotonGuardar: '',
            cumpleConReferencias: false,
            datosSolicitud: null,
            datosPersonales: null,
            datosDomicilio: null,
            datosContacto: null,
            datosBancarios: null,
            datosOcupacion: null,
            arrayReferencias: [],

            /* bandera bloqueo de tabla docs */
            banderaTablaDocs: false,
            /*modal */
            colorErrorModal: '#F83C3C',
            colorSuccesModal: '#27F45E',
            colorModal: '',
            tituloModal: '',
            mensajeError: '',
            showModal: false,
            banExisteExpediente: false,
            banMuestraTabla: false,
            selectTR: [],
            decimalesDescuento: "",
            /*modal */
            idIdenctificacionOficial: null,
            rutaIdentificacionOficial: null,
            idIdenctificacionOficialAnverso: null,
            rutaIdentificacionOficialAnverso: null,
            idComprobanteDomicilio: null,
            rutaComprobanteDomicilio: null,
            idComprobanteIngresos1: null,
            rutaComprobanteIngresos1: null,
            idComprobanteIngresos2: null,
            rutaComprobanteIngresos2: null,
            idEstadoCuenta: null,
            rutaEstadoCuenta: null,
            idSolicitudCredito: null,
            rutaSolicitudCredito: null,
            idInstDesc1: null,
            rutaInstDesc1: null,
            idInstDesc2: null,
            rutaInstDesc2: null,
            idPagare: null,
            rutaPagare: null,
            idFormatoDom: null,
            rutaFormatoDom: null,
            idContratoCredito: null,
            rutaContratoCredito: null,
            idDocAdicional: null,
            rutaDocAdicional: null,
            idContratoMedios: null,
            rutaContratoMedios: null,
            idFotoPromotor: null,
            rutaFotoPromotor: null,
            loading: false,
            banHabiliar: false,
            showModalCartaLibranza: false,
            banderaEnviar: false,//opera con el disabled // si es true se oculta el boton
            contadorObligatorios: 0,
            todosDocsObligatoriosArriba: false,
            arrayDocumentosASolicitar: [],
            colorModalDocumentos: '#FF6B00',
            showModalDocumentos: false,
            tituloModalDocumentos: '',
            mensajesDocumentos: [],
            arrayArchivos: [],
            arraySubArchivos: [],
            arrayDocumentosCargados: [],
            documentos: [],
            AppId: "",
            esEditarDocumento: false,
            showModalConfirmaEnviarAZell: false,
            banderaMuestraFirma: false,
            carpeta: ''
        }
    }

    async getSolicitud(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_solicitud/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("respuesta de la solicitud ", json[0])
                this.setState({idBDSolicitud:json[0].id, idDXN:json[0].appid})
                if (json.length > 0) {
                    this.setState({ decimalesDescuento: json[0].descuento/*.split(".")[1]*/, descuento: json[0].descuento, montoSolicitado: json[0].monto_solicitado, montoSolicitadoLabel: json[0].monto_solicitado })

                }
            } else {
                console.error('Error:', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error:' + response.status + "en getSolicitud  respuesta 500", showModal: true })
            }
        } catch (error) {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getSolicitud servicio interrumpido", showModal: true })
        }
    }

    async getSolicitudEvaulaFirmaDigital(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_solicitud/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("respuesta de la solicitud ", json[0])
                let compraDeuda = false
                if (json.length > 0) {
                    if (json[0].firma_digital == 2) {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Aviso !', mensajeError: 'Tu solicitud esta en proceso de firma, por favor espere unos instantes...', showModal: true })
                    }

                    if (json[0].firma_digital == 1) {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'UPSS !', mensajeError: 'Este documento ya ha sido firmado digitalmente, por favor envie el expediente digital a zell ', showModal: true, banderaEnviar: false })
                    }

                    if (json[0].firma_digital == 0) {
                        this.setState({ showModalFirmaDigital: true })
                    }
                    //this.setState({ datosSolicitud: json[0], AppId: json[0].appid == null ? "" : json[0].appid })

                }
            } else {
                console.error('Error:', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error:' + response.status + "en getSolicitud  respuesta 500", showModal: true })
            }
        } catch (error) {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getSolicitud servicio interrumpido", showModal: true })
        }
    }

    async getDatosPersonales(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_datos_solicitante/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                if (json.length > 0) {
                    this.setState({ datosPersonales: json[0] })
                }


            } else {
                console.error('Error:', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error:' + response.status + "en getDatosPersonales  respuesta 500", showModal: true })
            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosPersonales servicio interrumpido", showModal: true })
        }
    }

    async getDatosDomicilio(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_domicilio/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("respuesta de la solicitud datos personales ", json[0])
                if (json.length > 0) {
                    this.setState({ datosDomicilio: json[0] })
                }

            } else {
                console.error('Error response.status :', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosDomicilio  respuesta 500", showModal: true })

            }
        } catch (error) {
            console.error('Error: ', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosDomicilio servicio interrumpido", showModal: true })

        }
    }

    async getDatosContacto(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_contacto/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("respuesta de la solicitud datos personales ", json[0])
                if (json.length > 0) {
                    this.setState({ datosContacto: json[0] })
                }

            } else {
                console.error('Error response.status :', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosContacto  respuesta 500", showModal: true })

            }
        } catch (error) {
            console.error('Error: ', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosContacto servicio interrumpido", showModal: true })

        }
    }

    async getDatosBancarios(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_datos_bancarios/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("respuesta de la solicitud datos personales ", json[0])
                if (json.length > 0) {
                    this.setState({ datosBancarios: json[0] })
                }

            } else {
                console.error('Error response.status :', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosBancarios respuesta 500", showModal: true })

            }
        } catch (error) {
            console.error('Error: ', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosBancarios servicio interrumpido", showModal: true })

        }
    }

    async getDatosOcupacion(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_ocupacion/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("respuesta de la solicitud datos personales ", json[0])
                if (json.length > 0) {



                    //  let nuevaSolicitud = this.state.datosSolicitante
                    let datosSolicitante = {
                        tipoEmpleado: json[0].tipo_empleado,
                        numeroEmpleado: json[0].numero_empleado,
                        ingresoMensual: json[0].ingreso_mensual,
                        profesion: json[0].profesion,
                        puesto: json[0].puesto,
                        //    cargoPublicoCampo: publico,
                        puestoPublico: json[0].puesto_cp,
                        periodoEjercicioPublico: json[0].periodo_ejercicio_cp,
                        //  cargoPublicoFamiliarCampo: familiar,
                        nombreFamiliar: json[0].nombre_familiar,
                        parentescoFamiliar: json[0].parentesco,
                        puestoFamiliar: json[0].puesto_cpf,
                        periodoEjercicioFamiliar: json[0].periodo_ejercicio_cpf
                    }



                    this.setState({ datosOcupacion: datosSolicitante })
                }

            } else {
                console.error('Error response.status :', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getDatosOcupacion  respuesta 500", showModal: true })

            }
        } catch (error) {
            console.error('Error: ', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getDatosOcupacion servicio interrumpido", showModal: true })

        }
    }
    async getReferencias(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_referencias/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("referencias ", json)
                if (json.length > 0) {
                    this.setState({ arrayReferencias: json })

                    if (json.length >= 2) {
                        this.setState({ cumpleConReferencias: true })
                    } else {
                        this.setState({ cumpleConReferencias: false })
                    }

                }


            } else {
                console.error('Error:', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: response.status + " en getReferencias respuesta 500 ", showModal: true })

            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getReferencias servicio interrumpido", showModal: true })
        }
    }


    async estaGeneradoElExpediente() {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_solicitud_verificapdf/" + this.props.idSolicitud);
            if (response.ok) {
                const json = await response.json();
                // console.log("respuesta de la solicitud datos personales ", json[0])
                if (json.length > 0) {
                    this.getDocumentosASolicitar(this.props.idSolicitud)
                } else {
                    this.setState({ banderaEnviar: true })
                }

            } else {
                console.error('Error response.status :', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en getEstadoGeneralExpediente respuesta 500 ", showModal: true })

            }
        } catch (error) {
            console.error('Error: ', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en getEstadoGeneralExpediente servicio interrumpido", showModal: true })

        }
    }



    async validaSiHayDocumentosFaltantesPorEnviar() {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_estatus_envio_zell/" + this.props.idSolicitud);
            if (response.ok) {
                const json = await response.json();
                // console.log("respuesta de la solicitud datos personales ", json[0])
                if (json.length > 0) {
                    if (this.props.nuevaSolicitudServicio.estatus != 9
                        && this.props.nuevaSolicitudServicio.estatus != 4
                        && this.props.nuevaSolicitudServicio.estatus != 10
                        && this.props.nuevaSolicitudServicio.estatus != 7
                        && this.props.nuevaSolicitudServicio.estatus != 6
                        && this.props.nuevaSolicitudServicio.estatus != 5
                        && this.props.nuevaSolicitudServicio.estatus != 3
                        && this.props.nuevaSolicitudServicio.estatus != 2) {
                        this.setState({ banderaEnviar: false })
                    } else {
                        this.setState({ banderaEnviar: true })
                    }
                } else {
                    if (this.props.activar_IMSS && this.props.nuevaSolicitudServicio.estatus != 9
                        && this.props.nuevaSolicitudServicio.estatus != 4
                        && this.props.nuevaSolicitudServicio.estatus != 10
                        && this.props.nuevaSolicitudServicio.estatus != 7
                        && this.props.nuevaSolicitudServicio.estatus != 6
                        && this.props.nuevaSolicitudServicio.estatus != 5
                        && this.props.nuevaSolicitudServicio.estatus != 3
                        && this.props.nuevaSolicitudServicio.estatus != 2) {
                        this.setState({ banderaEnviar: false })
                    } else {
                        this.setState({ banderaEnviar: true })
                    }
                }

            } else {
                console.error('Error response.status :', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: 'Error :' + response.status + " en wedo_estatus_envio_zell respuesta 500 ", showModal: true })

            }
        } catch (error) {
            console.error('Error: ', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en wedo_estatus_envio_zell servicio interrumpido", showModal: true })

        }
    }



    validaCamposRequeridosSolicitud() {
        console.log(this.state.datosSolicitud)
        let solicitud = this.state.datosSolicitud
        let arrayKeys = Object.keys(solicitud)
        let banderaContinuar = true
        for (var i = 0; i < arrayKeys.length; i++) {
            let key = arrayKeys[i]
            let valor = solicitud[key]
            if (typeof valor === 'number') {
                if (valor == 0 || valor == null) {
                    banderaContinuar = false
                    break;
                }
            }
            if (typeof valor === 'string') {
                if (valor == '' || valor == null || valor == 'Seleccione...') {
                    banderaContinuar = false
                    break;
                }
            }
        }
    }

    esJson(valores) {
        let bandera = false
        try {
            JSON.parse(valores)
            bandera = true
        } catch (Exception) {
            //   return false
            bandera = false
        }
        return bandera
    }
 
    UNSAFE_componentWillMount() {
        this.setState({ showModalLoader: true })

        if (this.props.idSolicitud != null) {

            //meterlo todo esto en un solo servicio 
            // this.getReferencias(this.props.idSolicitud)
             this.getSolicitud(this.props.idSolicitud)
            // this.getDatosPersonales(this.props.idSolicitud)
            //this.getDatosDomicilio(this.props.idSolicitud)
            //this.getDatosContacto(this.props.idSolicitud)
            //this.getDatosBancarios(this.props.idSolicitud)
            //this.getDatosOcupacion(this.props.idSolicitud)
            this.setState({ showModalLoader: false })


            //setTimeout(() => {
            console.log("el id de la solicitud  ", this.state)
            console.log("las props del componente  ", this.props, !this.esJson(this.props.datosOcupacionServicio))
            if (this.props.existenDatosPersonales /*&&
                this.props.existenDatosDomicilio && 
                this.props.existenDatosContacto &&
                this.props.existenDatosBancarios &&
                this.props.existenDatosOcupacion &&
                this.props.existenDatosReferencias*/
            ) {
                if(this.props.existenDatosDomicilio){
                    if(this.props.existenDatosContacto){
                        if(this.props.existenDatosBancarios){
                            if(this.props.existenDatosOcupacion){
                                if(this.props.existenDatosReferencias){
                                    this.setState({
                                        datosSolicitud: this.props.nuevaSolicitudServicio,
                                        decimalesDescuento: this.props.nuevaSolicitudServicio.descuento,//.split(".")[1],
                                        AppId: this.props.nuevaSolicitudServicio.appid == null ? "" : this.props.nuevaSolicitudServicio.appid,
                                        datosPersonales: this.props.datosPersonalesServicio[0] != undefined ? this.props.datosPersonalesServicio[0] : this.props.datosPersonalesServicio,
                                        datosDomicilio: this.props.datosDomicilioServicio[0] != undefined ? this.props.datosDomicilioServicio[0] : this.props.datosDomicilioServicio,
                                        datosContacto: this.props.datosContactoServicio[0] != undefined ? this.props.datosContactoServicio[0] : this.props.datosContactoServicio,
                                        datosBancarios: this.props.datosBancariosServicio[0] != undefined ? this.props.datosBancariosServicio[0] : this.props.datosBancariosServicio,
                                        datosOcupacion: this.props.datosOcupacionServicio[0] != undefined ? this.props.datosOcupacionServicio[0] : this.props.datosOcupacionServicio,
                                        arrayReferencias: this.props.datosReferenciasServicio,
                                        cumpleConReferencias: this.props.datosReferenciasServicio != null ? this.props.datosReferenciasServicio.length >= 2 ? true : false : false
                                    })
                    
                                    if (this.props.nuevaSolicitudServicio != null) {
                                        if (this.props.nuevaSolicitudServicio.estatus == 1 || this.props.nuevaSolicitudServicio.estatus == 4 || this.props.nuevaSolicitudServicio.estatus == 8) {
                                            if (this.props.nuevaSolicitudServicio.estatus == 4) {
                                                this.setState({ banderaMuestraFirma: true })
                                            }
                                            if (this.props.activar_IMSS) {
                    
                                                if (this.props.nuevaSolicitudServicio.estatus == 4) {
                                                    this.setState({ banderaEnviar: true })// si es true se oculta el boton
                                                } else {
                                                    this.setState({ banderaEnviar: false })// si es true se oculta el boton
                                                }
                    
                                            } else {
                                                this.setState({ banderaEnviar: true })// si es true se oculta el boton
                                            }
                    
                                        }
                                    }
                                    this.estaGeneradoElExpediente(this.props.idSolicitud)
                                }else{
                                    this.setState({ banderaEnviar: true })
                                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Faltan secciones por llenar de información (Datos de Referencias )', showModal: true })    
                                }
                            }else{
                                this.setState({ banderaEnviar: true })
                                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Faltan secciones por llenar de información (Datos de Ocupación )', showModal: true })    
                            }

                        }else{
                            this.setState({ banderaEnviar: true })
                            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Faltan secciones por llenar de información (Datos Bancarios)', showModal: true })    
                        }

                    }else{
                        this.setState({ banderaEnviar: true })
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Faltan secciones por llenar de información (Datos de Contacto)', showModal: true })    
                    }

                }else{
                    this.setState({ banderaEnviar: true })
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Faltan secciones por llenar de información (Datos Domiciliares)', showModal: true })    
                }

            } else {
                this.setState({ banderaEnviar: true })
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Atención!', mensajeError: 'Faltan secciones por llenar de información (Datos personales)', showModal: true })

            }




            console.log("state del componente de documentos al cargarse una solicitud  ", this.state)
            console.log("props del componente de documentos al cargarse una solicitud  ", this.props)
            let primerNombre =
                this.props.datosPersonalesServicio != null && this.props.datosPersonalesServicio != undefined ?
                    this.props.datosPersonalesServicio instanceof Array ? this.props.datosPersonalesServicio[0].primer_nombre + "" : this.props.datosPersonalesServicio.primer_nombre + "" : ""
            let segundoNombre =
                this.props.datosPersonalesServicio != null && this.props.datosPersonalesServicio != undefined ? this.props.datosPersonalesServicio instanceof Array ? this.props.datosPersonalesServicio[0].segundo_nombre + "" : this.props.datosPersonalesServicio.segundo_nombre : ""
            let primerApp =
                this.props.datosPersonalesServicio != null && this.props.datosPersonalesServicio != undefined ? this.props.datosPersonalesServicio instanceof Array ? this.props.datosPersonalesServicio[0].primer_apellido + "" : this.props.datosPersonalesServicio.primer_apellido + "" : ""
            let segundoApp =
                this.props.datosPersonalesServicio != null && this.props.datosPersonalesServicio != undefined ? this.props.datosPersonalesServicio instanceof Array ? this.props.datosPersonalesServicio[0].segundo_apellido + "" : this.props.datosPersonalesServicio.segundo_apellido : ""


            let carpetaID = this.props.nuevaSolicitudServicio != null && this.props.nuevaSolicitudServicio != undefined ?
                this.props.nuevaSolicitudServicio instanceof Array ? this.props.nuevaSolicitudServicio[0].tipo_producto_id : this.props.nuevaSolicitudServicio.tipo_producto_id : null

            if (carpetaID != null) {
                if (carpetaID == 1) {
                    this.setState({ carpeta: 'Tradicional' })
                }
                if (carpetaID == 2) {
                    this.setState({ carpeta: 'Refinanciamiento' })
                }
                if (carpetaID == 3) {
                    this.setState({ carpeta: 'CompraDeuda' })
                }
            }
console.log(" segundoNombre",segundoNombre ,typeof(segundoNombre),segundoNombre=="null")
            this.setState({ primerNombreDigital: primerNombre.replaceAll(" ","%20"), segundoNombreDigital: segundoNombre== null? "":segundoNombre=="null" ?"":segundoNombre.replaceAll(" ","%20")+"%20" ,
             primerApellidoDigital: primerApp.replaceAll(" ","%20"), segundoApellidoDigital: segundoApp== null? "":segundoApp=="null" ?"":segundoApp.replaceAll(" ","%20")+"%20" })


            console.log("url de salida ", 'https://serviciosdxn.mx/firmar-documentos/?id_solicitud=' + this.props.idSolicitud + '&signerName=' +
            typeof(primerNombre) +
                segundoNombre +
                primerApp +
                segundoApp
                + '&signatureReason=De%20acuerdo%20con%20las%20condiciones&carpeta=' + carpetaID)


        } else {
            this.setState({ banderaEnviar: true })
            this.setState({ showModalLoader: false })
        }


    }


    enviajsonRequestZELL() {
        let jsonResultadosZell = this.state.esqueletoReqZell
        if (this.state.AppId != null && this.state.AppId != "") {
            jsonResultadosZell.AppId = this.state.AppId
        }
        /*   if (  this.props.existenDatosPersonales && this.props.existenDatosDomicilio && this.props.existenDatosContacto && this.props.existenDatosBancarios &&
               this.props.existenDatosOcupacion && this.props.existenDatosReferencias 
               ) {
   
                   this.setState({ 
                       datosSolicitud: this.props.nuevaSolicitudServicio, 
                       AppId: this.props.nuevaSolicitudServicio.appid == null ? "" : this.props.nuevaSolicitudServicio.appid ,
                       datosPersonales: this.props.datosPersonalesServicio[0],
                       datosDomicilio:this.props.datosDomicilioServicio[0],
                       datosContacto:this.props.datosContactoServicio[0],
                       datosBancarios:this.props.datosBancariosServicio[0],
                       datosOcupacion:this.props.datosOcupacionServicio[0],
                       arrayReferencias:this.props.datosReferenciasServicio,
                       cumpleConReferencias:this.props.datosReferenciasServicio != null ?this.props.datosReferenciasServicio.length >= 2 ? true:false:false
                   })
               
                   setTimeout(()=> {*/
        console.log("el estado al enviar el request a zeeeelllll ", this.state, this.props)
        jsonResultadosZell.TreasuryId = this.state.datosSolicitud.rfc
        jsonResultadosZell.LegalId = this.state.datosSolicitud.curp
        jsonResultadosZell.DestinationId = this.state.datosSolicitud.destcredito_id
        jsonResultadosZell.SectorActivityIdJo = this.state.datosSolicitud.sector_id
        jsonResultadosZell.Solicited = this.state.datosSolicitud.monto_solicitado
        jsonResultadosZell.FirstName = this.state.datosPersonales.primer_nombre //
        jsonResultadosZell.SecondName = this.state.datosPersonales.segundo_nombre //
        jsonResultadosZell.FLastName = this.state.datosPersonales.primer_apellido//
        jsonResultadosZell.SLastName = this.state.datosPersonales.segundo_apellido //
        jsonResultadosZell.OpSex = this.state.datosPersonales.genero //
        jsonResultadosZell.OpMaritalStatus = this.state.datosPersonales.edocivil   //
        jsonResultadosZell.BirthDate = this.state.datosPersonales.fecha_nacimiento //
        jsonResultadosZell.CountryId = this.state.datosPersonales.pais_nacimiento  //
        jsonResultadosZell.StateId = this.state.datosPersonales.entidad_nacimiento //
        jsonResultadosZell.OpNationality = this.state.datosPersonales.nacionalidad //
        jsonResultadosZell.OpIdType = this.state.datosPersonales.identificacion //

        jsonResultadosZell.ZipAd = this.state.datosDomicilio.codigo_postal //
        jsonResultadosZell.OpPropTypeAd = this.state.datosDomicilio.tipo_vivienda //
        jsonResultadosZell.StateIdAd = this.state.datosDomicilio.entidad //
        jsonResultadosZell.TownshipAd = this.state.datosDomicilio.municipio //
        jsonResultadosZell.CityAd = this.state.datosDomicilio.ciudad //
        jsonResultadosZell.NeighborhoodAd = this.state.datosDomicilio.colonia //
        jsonResultadosZell.StreetAd = this.state.datosDomicilio.calle //
        jsonResultadosZell.ExtNumberAd = this.state.datosDomicilio.no_exterior//
        jsonResultadosZell.IntNumberAd = this.state.datosDomicilio.no_interior  //  

        jsonResultadosZell.EMail = this.state.datosContacto.email //
        jsonResultadosZell.Mobile = this.state.datosContacto.celular //

        jsonResultadosZell.OpDisposition = this.state.datosBancarios.medio //
        jsonResultadosZell.AccountNumber = this.state.datosBancarios.clabe //
        jsonResultadosZell.Bank = this.state.datosBancarios.banco // 

        jsonResultadosZell.Afiliation = this.state.datosOcupacion.numero_empleado //
        jsonResultadosZell.BossJo = this.state.datosOcupacion.profesion //
        jsonResultadosZell.PositionJo = this.state.datosOcupacion.puesto // 
        jsonResultadosZell.OpStatus = 0
        jsonResultadosZell.Restructure = ''
        jsonResultadosZell.solicitud_id = this.props.idSolicitud
        jsonResultadosZell.id_solicitud = this.props.idSolicitud






        for (var clave in jsonResultadosZell) {
            if (clave.includes("FirstNameRe") || clave.includes("SecondNameRe") || clave.includes("FLastNameRe") || clave.includes("SLastNameRe") || clave.includes("PhoneNumberRe")) {
                console.log(clave + " = " + jsonResultadosZell[clave])
                for (var i = 0; i < this.state.arrayReferencias.length; i++) {
                    jsonResultadosZell["FirstNameRe" + (i + 1)] = this.state.arrayReferencias[i].primer_nombre
                    jsonResultadosZell["SecondNameRe" + (i + 1)] = this.state.arrayReferencias[i].segundo_nombre
                    jsonResultadosZell["FLastNameRe" + (i + 1)] = this.state.arrayReferencias[i].primer_apellido
                    jsonResultadosZell["SLastNameRe" + (i + 1)] = this.state.arrayReferencias[i].segundo_apellido
                    if (this.state.arrayReferencias[i].telefono != null && this.state.arrayReferencias[i].celular != null) {
                        jsonResultadosZell["PhoneNumberRe" + (i + 1)] = this.state.arrayReferencias[i].telefono
                    } else if (this.state.arrayReferencias[i].telefono != null && this.state.arrayReferencias[i].celular == null) {
                        jsonResultadosZell["PhoneNumberRe" + (i + 1)] = this.state.arrayReferencias[i].telefono
                    } else if (this.state.arrayReferencias[i].telefono == null && this.state.arrayReferencias[i].celular != null) {
                        jsonResultadosZell["PhoneNumberRe" + (i + 1)] = this.state.arrayReferencias[i].celular
                    }

                }
            }

        }

        console.log("el json la salida ", jsonResultadosZell)
        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(jsonResultadosZell)
        }
        fetch(conexiones.urlServicioZell + "zell_envio_solicitud/0", requestReferidoOptions)
            .then(response => response.json())
            .then(data => {
                console.log("respuesta de data", data)
                if (data.length != 0) {
                    if (data[0].ErrorCode == 0) {

                        this.setState({ AppId: data[0].AppId })
                        this.setState({ showModalLoader: false })
                        this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Aviso !', mensajeError: "La información ha sido enviada con éxito" + data[0].AppId, showModal: true })
                        this.enviaDcoumentosAZELL(data[0].AppId)

                    } else {
                        this.setState({ showModalLoader: false })
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error en la respuesta del servicio de ZELL !', mensajeError: "la respuesta fue -> " + data[0].ErrorDescription, showModal: true })
                    }

                } else {
                    this.setState({ showModalLoader: false })
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error en la respuesta del servicio de ZELL !', mensajeError: "la respuesta fue -> " + data, showModal: true })
                }

            }).catch(error => {
                this.setState({ showModalLoader: false })
                this.setState({ showModalReservar: false })
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir !', mensajeError: error.toString(), showModal: true })

            })
        //},100)


        //}
    }
    setJsonRequestZell() {
        this.setState({ showModalConfirmaEnviarAZell: false })
        this.setState({ showModalLoader: true })

        //console.log("this.state.datosSolicitud  ", this.state.datosSolicitud)
        if (this.props.nuevaSolicitudServicio.tipo_producto_id == 2) {
            if (this.state.AppId == null || this.state.AppId == "") {
                this.setState({ showModalLoader: false })
                this.setState({
                    colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error  !',
                    mensajeError: "Debes ingresar un folo de zell en datos de la solicitud cuando es un refinanciamiento ", showModal: true
                })
            } else {
                this.enviajsonRequestZELL()
            }
        } else {
            this.enviajsonRequestZELL()
        }




    }

    enviaDcoumentosAZELL(appId) {
        let json = {
            "solicitud_id": this.props.idSolicitud,
            "documento_id": 0,
            "AppId": appId
        }
        let requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }
        fetch(conexiones.urlServicioZell + "zell_envio_documentos/0", requestReferidoOptions)
            .then(response => response.json())
            .then(data => {

                console.log(data, "resulytado del envio docs ")
                setTimeout(()=>{                
                    this.props.clickAvanzar("solicitud")
                },3000) 

            }).catch(error => {
                //logErrores.push('Ocurrio un error al consumir  el envio de documentos !' + error.toString())
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir el envio de documentos!', mensajeError: error.toString(), showModal: true })

            })
    }


    /* enviaDcoumentosAZELL(AppId) {
         let docs_enviar = this.state.arrayDocumentosCargados
         let elementosEnviadosContador = 0;
         let logErrores = []
         for (var i = 0; i < docs_enviar.length; i++) {
             docs_enviar[i].AppId = AppId
             const requestReferidoOptions = {
                 method: "POST",
                 headers: { 'Content-Type': 'application/json' },
                 body: JSON.stringify(docs_enviar[i])
             }
 
             //   setTimeout(() =>{
             fetch(conexiones.urlServicioZell + "zell_envio_documentos/0", requestReferidoOptions)
                 .then(response => response.json())
                 .then(data => {
                     if (data.length != 0) {
                         if (data[0].ErrorCode == 0) {
                             elementosEnviadosContador++
                         } else {
                             logErrores.push('Ocurrio un error en la respuesta del servicio de ZELL  la respuesta fue ->  ' + data[0].ErrorDescription)
                             this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error en la respuesta del servicio de ZELL !', mensajeError: "la respuesta fue -> " + data[0].ErrorDescription, showModal: true })
                         }
 
                     } else {
                         logErrores.push('Ocurrio un error en la respuesta del servicio de ZELL  la respuesta fue ->  ' + data)
 
                         this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error en la respuesta del servicio de ZELL !', mensajeError: "la respuesta fue -> " + data, showModal: true })
                     }
 
                 }).catch(error => {
                     logErrores.push('Ocurrio un error al consumir !' + error.toString())
 
                     this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consumir !', mensajeError: error.toString(), showModal: true })
 
                 })
             // },2000)
 
 
         }
 
         setTimeout(() => {
 
             this.getDocumentosASolicitar(this.props.idSolicitud)
             this.setState({ showModalLoader: false })
             if (elementosEnviadosContador == docs_enviar.length) {
                 ///exito
                 this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Aviso !', mensajeError: "La información ha sido enviada con éxito" + AppId, showModal: true })
 
             } else {
                 let salidaEncabezados = []
 
                 for (var i = 0; i < logErrores.length; i++) {
                     salidaEncabezados.push(
                         <tr>
                             <td>{logErrores[i]}</td>
                         </tr>
 
                     );
                 }
                 let salida =
                     <Table striped bordered hover>
                         <thead>
                             <tr>
                                 <th>Mensaje</th>
                             </tr>
                         </thead>
                         <tbody>
                             {salidaEncabezados}
                         </tbody>
                     </Table>
                 this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Lista de errores !', mensajeError: salida, showModal: true })
             }
 
 
 
         }, 20000)
 
     }*/

    armaDocumentos(documentos) {
        for (var i = 0; i < documentos.length; i++) {
            if (documentos[i].documento == 1) {
                this.setState({
                    idIdenctificacionOficial: documentos[i].id,
                    rutaIdentificacionOficial: documentos[i].documento_ruta
                })
            }
            if (documentos[i].documento == 2) {
                this.setState({
                    idIdenctificacionOficialAnverso: documentos[i].id,
                    rutaIdentificacionOficialAnverso: documentos[i].documento_ruta
                })
            }

            if (documentos[i].documento == 3) {
                this.setState({
                    idComprobanteDomicilio: documentos[i].id,
                    rutaComprobanteDomicilio: documentos[i].documento_ruta
                })
            }
            if (documentos[i].documento == 4) {
                this.setState({
                    idComprobanteIngresos1: documentos[i].id,
                    rutaComprobanteIngresos1: documentos[i].documento_ruta
                })
            }

            if (documentos[i].documento == 5) {
                this.setState({
                    idComprobanteIngresos2: documentos[i].id,
                    rutaComprobanteIngresos2: documentos[i].documento_ruta
                })
            }
            if (documentos[i].documento == 6) {
                this.setState({
                    idEstadoCuenta: documentos[i].id,
                    rutaEstadoCuenta: documentos[i].documento_ruta
                })
            }
            if (documentos[i].documento == 7) {
                this.setState({
                    idSolicitudCredito: documentos[i].id,
                    rutaSolicitudCredito: documentos[i].documento_ruta
                })
            }

            if (documentos[i].documento == 8) {
                this.setState({
                    idInstDesc1: documentos[i].id,
                    rutaInstDesc1: documentos[i].documento_ruta
                })
            }

            if (documentos[i].documento == 9) {
                this.setState({
                    idInstDesc2: documentos[i].id,
                    rutaInstDesc2: documentos[i].documento_ruta
                })
            }

            if (documentos[i].documento == 10) {
                this.setState({
                    idPagare: documentos[i].id,
                    rutaPagare: documentos[i].documento_ruta
                })
            }

            if (documentos[i].documento == 11) {
                this.setState({
                    idFormatoDom: documentos[i].id,
                    rutaFormatoDom: documentos[i].documento_ruta
                })
            }

            if (documentos[i].documento == 12) {
                this.setState({
                    idContratoCredito: documentos[i].id,
                    rutaContratoCredito: documentos[i].documento_ruta
                })
            }

            if (documentos[i].documento == 13) {
                this.setState({
                    idDocAdicional: documentos[i].id,
                    rutaDocAdicional: documentos[i].documento_ruta
                })
            }

            if (documentos[i].documento == 14) {
                this.setState({
                    idContratoMedios: documentos[i].id,
                    rutaContratoMedios: documentos[i].documento_ruta
                })
            }


            if (documentos[i].documento == 15) {
                this.setState({
                    idFotoPromotor: documentos[i].id,
                    rutaFotoPromotor: documentos[i].documento_ruta
                })
            }
        }
    }

    getExpediente(idSolicitud) {
        try {
            fetch(conexiones.urlServicios + "wedo_solicitudpdf/" + idSolicitud)
                .then(response => response.json())
                .then(dataExpediente => {
                    console.log("consulta wedo_solicitudpdf")
                    if (dataExpediente.length > 0) {
                        this.setState({ banExisteExpediente: true })
                        // this.armaTablaExpediente(dataExpediente)
                        this.descargarExpediente(dataExpediente)
                        console.log("existe expediente")
                    } else {
                        this.setState({ banExisteExpediente: false })
                        console.log("no existe expediente")
                    }
                }).catch(error => {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })
                })
        } catch (error) {
            console.error('Error:', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })
        }
    }

 /*   armaTablaExpediente(dataExpediente) {
        let selectTR = []
        for (var i = 0; i < dataExpediente.length; i++) {
            let selectTD = []
            let rutaExpediente = dataExpediente[i].documento_ruta.replace('/media/root', 'https://wedoplus.mx:8001/')
            selectTD.push(
                <>
                    <td>{dataExpediente[i].solicitud}</td>
                    <td><a href={rutaExpediente} target="_blank" rel="noopener noreferrer">{rutaExpediente}</a></td>
                </>
            )
            selectTR.push(<tr>{selectTD}</tr>)
        }
        this.setState({ selectTR: selectTR })
        console.log("dataExpediente", dataExpediente);
    }*/


    descargarExpediente(dataExpediente) {
        let base64 = dataExpediente[0].documento_ruta
        /*   var element = document.createElement('a');
           console.log("data expediente ",dataExpediente) 
           element.setAttribute('href', 'data:application/pdf;base64,'+base64);
           element.setAttribute('download', "archivo.pdf");
           element.style.display = 'none';
           document.body.appendChild(element);
           element.click();
           document.body.removeChild(element);*/
        const blobData = atob(base64);
        const arrayBuffer = new ArrayBuffer(blobData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < blobData.length; i++) {
            uint8Array[i] = blobData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'nombre_archivo.pdf'; // Reemplaza con el nombre deseado
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }




    async generarExpediente() {
        //    this.setState({ banderaTablaDocs: true, banHabiliar: true })
        //validar los componentes anteriores

        // this.validaCamposRequeridosSolicitud()


        // if (this.state.cumpleConReferencias) {

        this.setState({ showModalLoader: true })
        if (this.state.banExisteExpediente) {
            this.setState({ banderaTablaDocs: true, banMuestraTabla: true, banHabiliar: false })
            this.setState({ showModalLoader: false })
        } else {
            let formData = new FormData();
            this.setState({ banderaTablaDocs: true, loading: true })
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            formData.append('solicitud', this.props.idSolicitud);
            formData.append('documento', 8);
            formData.append('cargado', 1);
            axios.post(conexiones.urlServicios + "wedo_solicitudpdf/" + this.props.idSolicitud, formData, config)
                .then(res => {
                    console.log("respues del servicio archivos ", res)
                    if (res.data[0].cargado == 1) {
                        this.getExpediente(this.props.idSolicitud)
                        this.setState({ banderaTablaDocs: false, banMuestraTabla: true, loading: false, banHabiliar: false })
                        setTimeout(() => {
                            this.getDocumentosASolicitar(this.props.idSolicitud)
                            setTimeout(() => { this.setState({ showModalLoader: false }) }, 1500)
                        }, 1000)
                    } else {
                        this.setState({ showModalLoader: false })
                        this.setState({ banHabiliar: false, loading: false, banderaTablaDocs: false, colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: res.data[0].estatus, showModal: true })
                    }

                }).catch(error => {
                    this.setState({ showModalLoader: false })
                    this.setState({ banHabiliar: false, loading: false, banderaTablaDocs: false, colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString() + " en la carga del archivo ", showModal: true })
                })

        }
        //   } else {
        //    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: "Son requeridas al menos dos referencias ", showModal: true })
        //  }

        //this.setState({banderaTablaDocs:false})

        console.log("entro en generarExpediente")
    }

    async getDocumentos(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlSeviciosArchivos + "/doc_solicitud/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("documentos ", json)
                if (json.length > 0) {
                    this.armaDocumentos(json)
                }
            } else {
                console.error('Error:', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: response.status, showModal: true })

            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })
        }
    }

    setArrayArchivos(documentos) {
        let arrayArchivos = []
        let arraySubArchivos = []
        let contadorObligatorios = 0
        let tipo_convenioURLFirma = ""
        for (let i = 0; i < documentos.length; i++) {
            if (i == 0) {
                tipo_convenioURLFirma = documentos[i].tipo_convenio == 1 ? "confianza" : "normal"
            }
            if (documentos[i].tipo_documento == 2) {// permitimos pasar los documentos

                if (documentos[i].obligatorio == 1) { contadorObligatorios++ }

                if (documentos[i].multicarga == 0) {// carga sencilla 
                    if (documentos[i].documento_ruta != null) {
                        arrayArchivos.push({
                            idDocumento: documentos[i].documento_id,
                            archivo: "",
                            obligatorio: documentos[i].obligatorio,
                            //  id:documentos[i].id
                        })

                    }
                }
                if (documentos[i].multicarga == 1) {
                    if (documentos[i].documento_ruta != null) {
                        arraySubArchivos.push({
                            idDocumento: documentos[i].documento_id,
                            idSubDocumento: 0,
                            archivo: "",
                            obligatorio: documentos[i].obligatorio,
                            //    id:documentos[i].id

                        })
                    }
                }
            }
        }
        this.setState({ tipo_convenioURLFirma: tipo_convenioURLFirma, contadorObligatorios: contadorObligatorios, arrayArchivos: arrayArchivos, arraySubArchivos: arraySubArchivos })
    }


    async getDocumentosASolicitar(idSolicitud) {
        try {
            const response = await fetch(conexiones.urlServicios + "wedo_vdocumentos_convenio_origen/" + idSolicitud);
            if (response.ok) {
                const json = await response.json();
                console.log("documentos ", json)
                if (json.length > 0) {
                    this.setArrayArchivos(json)
                    setTimeout(() => {
                        this.armaDocumentosASolicitar(json, null)
                    }, 50)

                }
            } else {
                console.error('Error:', response.status);
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: response.status, showModal: true })

            }
        } catch (error) {
            console.error('Error:', error);
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error!', mensajeError: error.toString(), showModal: true })
        }
    }


    async generaDocumentoAMostrar(urlDoc,nombreDocAMostrar){
        try{
this.setState({urlDocumentoMostrado:urlDoc})
            console.log("la url a resolver mi chavo ",urlDoc)
            if(urlDoc.includes(".pdf")){
                console.log("PDFFFFF  ",urlDoc)
                this.setState({showDocumentoPDF:true,showDocumentoImagen:false})

            }else if(urlDoc.includes(".jpg")||urlDoc.includes(".jpeg") ){
                console.log("IMG ",urlDoc)
                this.setState({showDocumentoImagen:true,showDocumentoPDF:false})
            }else{
                console.log("otro ",urlDoc)
                this.setState({showDocumentoImagen:false,showDocumentoPDF:false})
            }
            const response = await fetch(urlDoc)
            if (!response.ok) {
                throw new Error("Error al obtener el archivo");
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            this.setState({nombreDocAMostrar:nombreDocAMostrar,documentoformato:url,showModaldocumentoFormato:true})


        }catch(exception){
console.log(exception)

        }

    }

    async descargarDocumentoAMostrar(){
        try{
            let urlDoc=this.state.urlDocumentoMostrado
            console.log("la url a resolver mi chavo ",urlDoc)
            const response = await fetch(urlDoc)
            if (!response.ok) {
                throw new Error("Error al obtener el archivo");
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = this.state.nombreDocAMostrar; // Nombre del archivo con extensión
            document.body.appendChild(link);
    
            // Simular clic para descargar el archivo
            link.click();
    
            // Limpiar el enlace dinámico después de su uso
            URL.revokeObjectURL(url);
            document.body.removeChild(link);
    

        }catch(exception){
console.log(exception)
        }

    }


    armaDocumentosASolicitar(documentos) {
        console.log("entrando a armaDocumentosASolicitar ", documentos)
        let arrayDocumentosASolicitar = []
        let arrayDocumentosCargados = []
        let contadorObligatorios = 0
        for (let i = 0; i < documentos.length; i++) {
            if (documentos[i].tipo_documento == 2) {// permitimos pasar los documentos

                let salidaHTML = ''
                let gener = this.buscaArchivoExisente(this.state.arrayArchivos, documentos[i].documento_id, documentos[i].regla)
                let esCagardo = gener.banderaExiste
                let nombreArchivo = gener.nombre
                let color="";
                if(documentos[i].enviado == 0 && documentos[i].documento_ruta != null){
                    color="#FF6B00"
                }
                if(documentos[i].enviado == 0 && documentos[i].documento_ruta == null){
                    color="blue"
                }

                if(documentos[i].enviado == 2 && documentos[i].documento_ruta != null){
                    color="green"
                }

                if(documentos[i].enviado == 9 && documentos[i].documento_ruta != null){
                    color="brown"
                }



                if (documentos[i].multicarga == 0) {// carga sencilla 
                    salidaHTML = <tr>
                        <td style={{ color:/* esCagardo == true || documentos[i].documento_ruta != null ? "#FF6B00" : 'blue'*/color }} colSpan={1}>
                            {documentos[i].documento}
                            {nombreArchivo}
                            {documentos[i].obligatorio == 1 ? esCagardo == true || documentos[i].documento_ruta != null ? "" : <span style={{ fontWeight: "bold", color: 'red' }}>{"{REQUERIDO}"}</span> : ''}
                            {"      "}
                            {<span style={{ fontWeight: "bold", color:color /*documentos[i].enviado == 2 ? 'green':"red" */}}>{documentos[i].etiqueta.length > 0 ?"{"+documentos[i].etiqueta+"}":""}</span>}
                            </td>
                        <td style={{ color: /*esCagardo == true || documentos[i].documento_ruta != null ? "#FF6B00" : 'blue' */color}}></td>
                        {documentos[i].documento_id != 136 ?
                            <td>
                                <Row>
                                    <Col lg={8} md={5} xs={5}>
                                        <div style={{ "position": "relative", "display": "inline-block" }}>
                                            <input disabled={this.props.banderaDocumentos}
                                                type="file" accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                                                id={"custom-file-input" + documentos[i].documento}
                                                style={{ display: 'none' }}
                                                onChange={this.props.banderaDocumentos ? "" : (e) => {

                                                    this.onChangeSeleccionaArchivo(documentos[i].documento_id,
                                                        documentos[i].numero_multicarga, e.target.files[0], documentos[i].regla, (esCagardo == true || documentos[i].documento_ruta != null) == true ? true : false)
                                                }}
                                            />
                                            <label htmlFor={"custom-file-input" + documentos[i].documento} style={{
                                                "display": "inline-block",
                                                "padding": "5px 5px",
                                                "backgroundColor": color,//documentos[i].enviado == 2 ? 'green':"red",// esCagardo == true || documentos[i].documento_ruta != null ? "#FF6B00" : "#2E64FE",
                                                "color": "white",
                                                "cursor": "pointer",
                                                "borderRadius": "5px",
                                                "border": "none",
                                                "fontSize": "15px"
                                            }}
                                            >
                                                <AttachFileIcon />
                                            </label>
                                        </div>
                                    </Col>

                                </Row>
                            </td>
                            :
                            <td>
                                <Row>
                                    <Col lg={8} md={5} xs={5}>
                                        <div style={{ "position": "relative", "display": "inline-block" }}>
                                            <button disabled={this.props.banderaDocumentos}
                                                id={"custom-file-input-window" + documentos[i].documento}
                                                style={{ display: 'none' }}
                                                onClick={this.props.banderaDocumentos ? "" : (e) => {
                                                    console.log("cloclando el boton de la ventana")
                                                    this.getSolicitud(this.props.idSolicitud)
                                                    const startButton = document.getElementById('custom-file-input-libranza');
                                                    startButton.click()
                                                    /*this.setState({
                                                        banderaModificarDecimales: true,
                                                        //decimalesDescuento: this.props.nuevaSolicitudServicio.descuento.split(".")[1],
                                                        colorModal: this.state.colorModalDocumentos, tituloModal: 'Atención!',
                                                        showModalCartaLibranza: true
                                                    })*/
                                                }}
                                            />
                                            <input disabled={this.props.banderaDocumentos}
                                                type="file" accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                                                id={"custom-file-input-libranza"}
                                                style={{ display: 'none' }}
                                                onChange={this.props.banderaDocumentos ? "" : (e) => {

                                                    this.onChangeSeleccionaArchivoLibranza(documentos[i].documento_id,
                                                        documentos[i].numero_multicarga, e.target.files[0], documentos[i].regla, (esCagardo == true || documentos[i].documento_ruta != null) == true ? true : false)
                                                }}
                                            />


                                            <label htmlFor={"custom-file-input-window" + documentos[i].documento}

                                                style={{
                                                    "display": "inline-block",
                                                    "padding": "5px 5px",
                                                    "backgroundColor":color,//documentos[i].enviado == 2 ? 'green':"red",// esCagardo == true || documentos[i].documento_ruta != null ? "#FF6B00" : "#2E64FE",
                                                    "color": "white",
                                                    "cursor": "pointer",
                                                    "borderRadius": "5px",
                                                    "border": "none",
                                                    "fontSize": "15px"
                                                }}
                                            >
                                                <AttachFileIcon />
                                            </label>
                                        </div>
                                    </Col>

                                </Row>
                            </td>

                        }

                        <td>
                            {
                                documentos[i].documento_ruta != null ?
                                    <button target="_blank" onClick={
                                        ()=>{
                                            this.generaDocumentoAMostrar(documentos[i].documento_ruta,documentos[i].documento)
                                          //  this.setState({documentoformato:"https://wedoplus.mx:8099" + documentos[i].documento_ruta,showModaldocumentoFormato:true})
                                         }
                                    
                                    }
                                        style={{ border:"none","color": color/*documentos[i].enviado == 2 ? 'green':"red"*//*"#FF6B00"*/, "textDecoration": "none", "fontWeight": "bold", "transition": "color 0.3s" }}
                                        /*href={documentos[i].documento_ruta.replace("/root", "http://143.110.147.216:8001/")} */
                                        href={"#"/*"https://wedoplus.mx:8001" + documentos[i].documento_ruta*/}
                                        class="enlace-personalizado"><VisibilityIcon /></button>

                                    :
                                    <div>&nbsp;</div>
                            }
                        </td>
                        <td>
                            {documentos[i].documento_ruta != null ?
                                <a target="_blank" onClick={this.props.banderaDocumentos ? () => { } : () => {
                                    this.setState({
                                        idDocumentoAEliminar: documentos[i].documento_id,
                                        colorModal: this.state.colorErrorModal,
                                        tituloModal: 'Atención!', mensajeError: "Estas seguro que deseas eliminar el documento " + documentos[i].documento + "?", showModalBorrar: true
                                    })/*this.eliminaArchivo(documentos[i].documento_id)*/
                                }} style={{ "color": "red", "textDecoration": "none", "fontWeight": "bold", "transition": "color 0.3s" }} class="enlace-personalizado"> <DeleteIcon /> </a>
                                : ""
                            }
                        </td>
                    </tr>
                    if (documentos[i].documento_ruta != null) {
                        if (documentos[i].obligatorio == 1) { contadorObligatorios++ }
                        arrayDocumentosCargados.push({ documento_id: documentos[i].documento_id, solicitud_id: this.props.idSolicitud })
                    }

                }


                if (documentos[i].multicarga == 1) {// carga multiple

                    console.log(esCagardo, this.state.arraySubArchivos, "esCagardo", documentos[i].documento_id)
                    console.log("esCagardo ", this.buscaArchivoExisente444(this.state.arraySubArchivos, documentos[i].documento_id))
                    esCagardo = this.buscaArchivoExisente444(this.state.arraySubArchivos, documentos[i].documento_id).banderaExiste
                    
                    let color="";
                    if(documentos[i].enviado == 0 && documentos[i].documento_ruta != null){
                        color="#FF6B00"
                    }
                    if(documentos[i].enviado == 0 && documentos[i].documento_ruta == null){
                        color="blue"
                    }

                    if(documentos[i].enviado == 2 && documentos[i].documento_ruta != null){
                        color="green"
                    }

                    if(documentos[i].enviado == 9 && documentos[i].documento_ruta != null){
                        color="brown"
                    }


                    salidaHTML = <tr>
                        <td style={{ color: color/*documentos[i].enviado == 2 ? 'green':documentos[i].enviado == 0 ?"blue":"red"*//*esCagardo == true ? "#FF6B00" : 'blue' */}} colSpan={2}>
                        {documentos[i].documento}
                        {documentos[i].obligatorio == 1 ? esCagardo == true || documentos[i].documento_ruta != null ? "" : <span style={{ fontWeight: "bold", color: 'red' }}>{"{REQUERIDO}"}</span> : ''}
                        {"      "}
                            {<span style={{ fontWeight: "bold", color:color/* documentos[i].enviado == 2 ? 'green':documentos[i].enviado == 0 ?"blue":"red"*/ }}>{documentos[i].etiqueta.length > 0 ?"{"+documentos[i].etiqueta+"}":""}</span>}
                        </td>
                        <td>
                            <Row>
                                <Col lg={8} md={5} xs={5}>
                                    <div style={{ "position": "relative", "display": "inline-block" }}>

                                        <label onClick={this.props.banderaDocumentos ? () => { } : () => { this.cargaInformacionDocumentoASolicitar(documentos[i].documento_id, documentos[i].documento, documentos[i].numero_multicarga, documentos[i].regla) }}
                                            style={{
                                                "display": "inline-block",
                                                "padding": "5px 5px",
                                                "backgroundColor":color,//documentos[i].enviado == 2 ? 'green':documentos[i].enviado == 0 ?"blue":"red", //esCagardo == true ? "#FF6B00" : "red",
                                                "color": "white",
                                                "cursor": "pointer",
                                                "borderRadius": "5px",
                                                "border": "none",
                                                "fontSize": "15px"
                                            }}
                                        >
                                            <AttachFileIcon />
                                        </label>
                                    </div>

                                    {
                                        /*
                                            <input type="file"  style={{ display: 'none' }} ref={(ref) => this.solCredito = ref} onChange={null}></input>
                                            <i className="fa fa-cloud-upload"  onClick={() => {this.cargaInformacionDocumentoASolicitar(documentos[i].documento_id,documentos[i].documento,documentos[i].numero_multicarga)  }} style={{ color: "blue", textDecoration: 'none' }} data-popup="tooltip" title="Selecciona Archivos" ></i>
                                        */
                                    }


                                </Col>

                            </Row>
                        </td>
                        <td>
                            {
                                documentos[i].documento_ruta != null ?
                                    <button target="_blank" onClick={()=>{
                                        this.generaDocumentoAMostrar(documentos[i].documento_ruta,documentos[i].documento)
                                        //this.setState({documentoformato:"https://wedoplus.mx:8099" + documentos[i].documento_ruta,showModaldocumentoFormato:true})
                                        
                                        }}
                                        style={{border:"none", "color":color/*documentos[i].enviado == 2 ? 'green':"red" *//*"#FF6B00"*/, "textDecoration": "none", "fontWeight": "bold", "transition": "color 0.3s" }}
                                        /*href={documentos[i].documento_ruta.replace("/root", "http://143.110.147.216:8001/")} */
                                        href={"#"/*"https://wedoplus.mx:8001" + documentos[i].documento_ruta*/}
                                        class="enlace-personalizado"><VisibilityIcon /></button>

                                    :
                                    <div>&nbsp;</div>
                            }
                        </td>

                        <td>
                            {documentos[i].documento_ruta != null ?
                                <a target="_blank" onClick={this.props.banderaDocumentos ? () => { } : () => {
                                    this.setState({
                                        idDocumentoAEliminar: documentos[i].documento_id,
                                        colorModal: this.state.colorErrorModal,
                                        tituloModal: 'Atención!', mensajeError: "Estas seguro que deseas eliminar el documento " + documentos[i].documento + "?", showModalBorrar: true
                                    })/* this.eliminaArchivo(documentos[i].documento_id)*/
                                }} style={{ "color": "red", "textDecoration": "none", "fontWeight": "bold", "transition": "color 0.3s" }} class="enlace-personalizado"> <DeleteIcon /> </a>
                                : ""
                            }
                        </td>
                    </tr>
                    if (documentos[i].documento_ruta != null) {
                        if (documentos[i].obligatorio == 1) { contadorObligatorios++ }
                        arrayDocumentosCargados.push({ documento_id: documentos[i].documento_id, solicitud_id: this.props.idSolicitud })
                    }
                }

                arrayDocumentosASolicitar.push(salidaHTML)
            }

        }
        //  console.log("entrando a armaDocumentosASolicitar ", arrayDocumentosASolicitar, documentos)
        console.log("total de obligatorios subids ", contadorObligatorios, this.state.contadorObligatorios, this.state.AppId, this.state.esEditarDocumento)
        //this.setState({showModalLoader:false})

        if (this.state.AppId != "") {// yaexite en zell
            if (contadorObligatorios == this.state.contadorObligatorios) {
                if (this.state.esEditarDocumento) {

                    if (this.props.nuevaSolicitudServicio.estatus != 9
                        && this.props.nuevaSolicitudServicio.estatus != 4
                        && this.props.nuevaSolicitudServicio.estatus != 10
                        && this.props.nuevaSolicitudServicio.estatus != 7
                        && this.props.nuevaSolicitudServicio.estatus != 6
                        && this.props.nuevaSolicitudServicio.estatus != 5
                        && this.props.nuevaSolicitudServicio.estatus != 3
                        && this.props.nuevaSolicitudServicio.estatus != 2) {
                        this.setState({ banderaEnviar: false })
                    }
                } else {
                    if (this.props.activar_IMSS && this.props.nuevaSolicitudServicio.estatus != 9
                        && this.props.nuevaSolicitudServicio.estatus != 4
                        && this.props.nuevaSolicitudServicio.estatus != 10
                        && this.props.nuevaSolicitudServicio.estatus != 7
                        && this.props.nuevaSolicitudServicio.estatus != 6
                        && this.props.nuevaSolicitudServicio.estatus != 5
                        && this.props.nuevaSolicitudServicio.estatus != 3
                        && this.props.nuevaSolicitudServicio.estatus != 2) {
                        this.setState({ banderaEnviar: false })
                    } else {
                        this.setState({ banderaEnviar: true })
                    }
                }
                this.validaSiHayDocumentosFaltantesPorEnviar()

            } else {
                this.setState({ banderaEnviar: true })
            }
        } else {
            if (contadorObligatorios == this.state.contadorObligatorios) {
                if (this.props.nuevaSolicitudServicio.estatus != 9
                    && this.props.nuevaSolicitudServicio.estatus != 4
                    && this.props.nuevaSolicitudServicio.estatus != 10
                    && this.props.nuevaSolicitudServicio.estatus != 7
                    && this.props.nuevaSolicitudServicio.estatus != 6
                    && this.props.nuevaSolicitudServicio.estatus != 5
                    && this.props.nuevaSolicitudServicio.estatus != 3
                    && this.props.nuevaSolicitudServicio.estatus != 2) {
                    this.setState({ banderaEnviar: false })
                }
                //this.validaSiHayDocumentosFaltantesPorEnviar()
            } else {
                if (this.props.activar_IMSS && this.props.nuevaSolicitudServicio.estatus != 9
                    && this.props.nuevaSolicitudServicio.estatus != 4
                    && this.props.nuevaSolicitudServicio.estatus != 10
                    && this.props.nuevaSolicitudServicio.estatus != 7
                    && this.props.nuevaSolicitudServicio.estatus != 6
                    && this.props.nuevaSolicitudServicio.estatus != 5
                    && this.props.nuevaSolicitudServicio.estatus != 3
                    && this.props.nuevaSolicitudServicio.estatus != 2) {
                    this.setState({ banderaEnviar: false })
                } else {
                    this.setState({ banderaEnviar: true })
                }
            }
        }

        this.setState({ documentos: documentos, documentosRestantes: this.state.contadorObligatorios - contadorObligatorios, arrayDocumentosASolicitar: arrayDocumentosASolicitar, arrayDocumentosCargados: arrayDocumentosCargados })
    }


    onChangeSeleccionaArchivo(idDocumento, totalDeCargas, archivo, idRegla, esActulizacion) {

        console.log(idDocumento, totalDeCargas, archivo)
        if (archivo != undefined) {
            this.setState({ showModalLoader: true })
            let arrayArchivos = this.state.arrayArchivos
            if (arrayArchivos.length == 0) {
                arrayArchivos.push({
                    idDocumento: idDocumento,
                    archivo: archivo,
                    idRegla: idRegla
                })
            } else {
                //vamos a buscar
                this.buscaArchivo(arrayArchivos, idDocumento, archivo)
            }

            this.setState({ arrayArchivos: arrayArchivos })
            this.guardaArchivo(idDocumento, idRegla, archivo, esActulizacion)
            /* setTimeout(() => {
                 this.getDocumentosASolicitar(this.props.idSolicitud)
                 this.setState({ showModalLoader: false })
             }, 500)*/
        }


    }
    onChangeSeleccionaArchivoLibranza(idDocumento, totalDeCargas, archivo, idRegla, esActulizacion) {

        console.log(idDocumento, totalDeCargas, archivo)
        if (archivo != undefined) {
            this.setState({ showModalLoader: true })
            let arrayArchivos = this.state.arrayArchivos
            if (arrayArchivos.length == 0) {
                arrayArchivos.push({
                    idDocumento: idDocumento,
                    archivo: archivo,
                    idRegla: idRegla
                })
            } else {
                //vamos a buscar
                this.buscaArchivo(arrayArchivos, idDocumento, archivo)
            }

            this.setState({ arrayArchivos: arrayArchivos })
            this.guardaArchivoLibranza(idDocumento, idRegla, archivo, esActulizacion)
            /* setTimeout(() => {
                this.setState({
                    banderaModificarDecimales: true,
                    colorModal: this.state.colorModalDocumentos, tituloModal: 'Atención!',
                    showModalCartaLibranza: true
                })
             }, 50)*/
        }


    }

    async guardaArchivoLibranza(idDocumento, idRegla, archivo, esActulizacion) {
        console.log("enviando archivo sencillos")
        let formData = new FormData();
        formData.append("solicitud", this.props.idSolicitud/*51*/)
        formData.append("cargado", 1)
        formData.append("documento", idDocumento)
        formData.append("regla", idRegla)
        formData.append("total_img", 1)
        formData.append("imagen1", archivo)
        if (esActulizacion) {
            this.setState({ esEditarDocumento: true })
            formData.append("actualizar", 1)
        } else {
            if (this.state.AppId != "") {
                this.setState({ esEditarDocumento: true })
            }
            formData.append("actualizar", 0)
        }
        try {
            const res = await fetch(
                conexiones.urlSolicitudConcat + this.props.idSolicitud,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            const resData = await res.json();
            console.log("respues del serv", resData);
            let respuesta = resData[0]
            if (respuesta.cargado == 0) {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Ocurrio un error !', mensajeError: "Favor de cargar nuevamente el archivo.", showModal: true })
                this.setState({ showModalLoader: false })
            }
            if (respuesta.cargado == 1) {
                //  this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Éxito!', mensajeError: "Archivo cargado correctamente", showModal: true })
                setTimeout(() => {
                    this.getDocumentosASolicitar(this.props.idSolicitud)
                    this.setState({
                        showModalLoader: false,
                        banderaModificarDecimales: true,
                        colorModal: this.state.colorModalDocumentos, tituloModal: 'Atención!',
                        showModalCartaLibranza: true
                    })
                }, 500)
            }

        } catch (error) {
            console.log("error en la eticion ", error)
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Ocurrio un error !', mensajeError: "Favor de cargar nuevamente el archivo.", showModal: true })
        }
    }

    async guardaArchivo(idDocumento, idRegla, archivo, esActulizacion) {
        console.log("enviando archivo sencillos")
        let formData = new FormData();
        formData.append("solicitud", this.props.idSolicitud/*51*/)
        formData.append("cargado", 1)
        formData.append("documento", idDocumento)
        formData.append("regla", idRegla)
        formData.append("total_img", 1)
        formData.append("imagen1", archivo)
        if (esActulizacion) {
            this.setState({ esEditarDocumento: true })
            formData.append("actualizar", 1)
        } else {
            if (this.state.AppId != "") {
                this.setState({ esEditarDocumento: true })
            }
            formData.append("actualizar", 0)
        }
        try {
            const res = await fetch(
                conexiones.urlSolicitudConcat + this.props.idSolicitud,
                {
                    method: 'POST',
                    body: formData,
                },
            );
            const resData = await res.json();
            console.log("respues del serv", resData);
            let respuesta = resData[0]
            if (respuesta.cargado == 0) {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Ocurrio un error !', mensajeError: "Favor de cargar nuevamente el archivo.", showModal: true })
                this.setState({ showModalLoader: false })
            }
            if (respuesta.cargado == 1) {
                this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Éxito!', mensajeError: "Archivo cargado correctamente", showModal: true })
                setTimeout(() => {
                    this.getDocumentosASolicitar(this.props.idSolicitud)
                    this.setState({ showModalLoader: false })
                }, 500)
            }

        } catch (error) {
            console.log("error en la eticion ", error)
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Ocurrio un error !', mensajeError: "Favor de cargar nuevamente el archivo.", showModal: true })
        }
    }


    buscaArchivo(arrayArchivos, idDocumento, archivo, idRegla) {
        let banderaExiste = false
        for (var i = 0; i < arrayArchivos.length; i++) {
            if (idDocumento == arrayArchivos[i].idDocumento) {
                arrayArchivos[i].archivo = archivo
                banderaExiste = true
            }
        }

        if (!banderaExiste) {
            arrayArchivos.push({
                idDocumento: idDocumento,
                archivo: archivo,
                idRegla: idRegla
            })
        }


        console.log("modifficaciones ", arrayArchivos)
    }

    buscaArchivoExisente(arrayArchivos, idDocumento) {
        console.log("esCagardo buscaArchivoExisente", arrayArchivos)

        let banderaExiste = false
        let nombre = ""
        for (var i = 0; i < arrayArchivos.length; i++) {
            console.log("esCagardo ", idDocumento, "==", arrayArchivos[i].idDocumento, idDocumento == arrayArchivos[i].idDocumento)
            if (idDocumento == arrayArchivos[i].idDocumento) {
                banderaExiste = true
                nombre = arrayArchivos[i].archivo.name
                break;
            }
        }

        return { banderaExiste: banderaExiste, nombre: nombre }
    }



    buscaArchivoExisente444(arrayArchivos, idDocumento) {
        console.log("esCagardo buscaArchivoExisente", arrayArchivos)

        let banderaExiste = false
        let nombre = ""
        for (var i = 0; i < arrayArchivos.length; i++) {
            console.log("esCagardo ", idDocumento, "==", arrayArchivos[i].idDocumento, idDocumento == arrayArchivos[i].idDocumento)
            if (idDocumento == arrayArchivos[i].idDocumento) {
                banderaExiste = true
                nombre = arrayArchivos[i].archivo.name
                break;
            }
        }

        return { banderaExiste: banderaExiste, nombre: nombre }
    }




    buscaSubArchivoExisente(arraySubArchivos, idDocumento, idSubDocumento) {
        let banderaExiste = false
        let nombre = ""
        let archivo = ""
        for (var i = 0; i < arraySubArchivos.length; i++) {
            if (idDocumento == arraySubArchivos[i].idDocumento && idSubDocumento == arraySubArchivos[i].idSubDocumento && arraySubArchivos[i].archivo != undefined && arraySubArchivos[i].archivo != '') {
                nombre = arraySubArchivos[i].nombre
                archivo = arraySubArchivos[i].archivo
                banderaExiste = true
            }
        }

        console.log("salida de la busqueda ", arraySubArchivos, { banderaExiste: banderaExiste, nombre: nombre, archivo: archivo })

        return { banderaExiste: banderaExiste, nombre: nombre, archivo: archivo }
    }
    buscaSubArchivo(arraySubArchivos, idDocumento, idSubDocumento, archivo) {
        let banderaExiste = false
        for (var i = 0; i < arraySubArchivos.length; i++) {
            if (idDocumento == arraySubArchivos[i].idDocumento && idSubDocumento == arraySubArchivos[i].idSubDocumento && arraySubArchivos[i].archivo != undefined && arraySubArchivos[i].archivo != '') {
                arraySubArchivos[i].archivo = archivo
                arraySubArchivos[i].nombre = archivo.name
                banderaExiste = true
            }
        }

        if (!banderaExiste) {
            arraySubArchivos.push({
                idDocumento: idDocumento,
                idSubDocumento: idSubDocumento,
                archivo: archivo,
                nombre: archivo.name
            })
        }


        console.log("modifficaciones ", arraySubArchivos)
    }
    onChangeSeleccionaSubArchivo = (idDocumento, idSubDocumento, /*archivo,*/ totalDeCargas, nombreDoc, idRegla) => event => {
        let archivo = event.target.files[0]
        if (archivo != undefined) {

            console.log("idDocumento", "idSubDocumento", idDocumento, idSubDocumento, event.target.name)
            let arraySubArchivos = this.state.arraySubArchivos
            if (arraySubArchivos.length == 0) {
                arraySubArchivos.push({
                    idDocumento: idDocumento,
                    idSubDocumento: idSubDocumento,
                    archivo: archivo,
                    nombre: archivo.name
                })
            } else {
                this.buscaSubArchivo(arraySubArchivos, idDocumento, idSubDocumento, archivo)
            }
            this.setState({ arraySubArchivos: arraySubArchivos })
            setTimeout(() => {
                this.cargaInformacionDocumentoASolicitar(idDocumento, nombreDoc, totalDeCargas, idRegla)

                //   this.armaDocumentosASolicitar(this.state.documentos, idDocumento)
            }, 500)
        }



    }

    async enviarArchivosMultiples(idDocumento, totalDeCargas, idRegla, esActulizacion) {
        let arraySubArchivos = this.state.arraySubArchivos
        let arrayArchivosAEnviar = []
        this.setState({ showModalLoader: true })
        let arrayIdsActualizar = []
        for (var i = 0; i < arraySubArchivos.length; i++) {
            if (arraySubArchivos[i].idDocumento == idDocumento && arraySubArchivos[i].archivo != undefined && arraySubArchivos[i].archivo instanceof File) {
                arrayArchivosAEnviar.push(arraySubArchivos[i])
                // if(arraySubArchivos[i].id != undefined && arraySubArchivos[i].id != null){
                arrayIdsActualizar.push(arraySubArchivos[i].id)

                //}
            }
        }

        let formData = new FormData();
        formData.append("solicitud", this.props.idSolicitud)
        formData.append("cargado", 1)
        formData.append("documento", idDocumento)
        formData.append("regla", idRegla)
        formData.append("total_img", arrayArchivosAEnviar.length)
        console.log("es actualizacion ", esActulizacion)
        if (esActulizacion) {
            formData.append("actualizar", 1)
            this.setState({ esEditarDocumento: true })
            console.log("los ids  ", arrayIdsActualizar)
            if (arrayIdsActualizar.length == 1) {
                if (arrayIdsActualizar[0] != undefined && arrayIdsActualizar[0] != null) {
                    formData.append("idDocumentos", arrayIdsActualizar[0] + "")
                } else {
                    formData.append("idDocumentos", "0")
                }
            } else if (arrayIdsActualizar.length > 1) {

                console.log("arrayIdsActualizar ", arrayIdsActualizar)
                let ids = ''
                for (var i = 0; i < arrayIdsActualizar.length; i++) {
                    if (i == arrayIdsActualizar.length - 1) {
                        if (arrayIdsActualizar[0] != undefined && arrayIdsActualizar[0] != null) {
                            ids += arrayIdsActualizar[i]
                        } else {
                            ids += ids + "0"
                        }
                        //  ids += ids + arrayIdsActualizar[i]
                    } else {
                        if (arrayIdsActualizar[0] != undefined && arrayIdsActualizar[0] != null) {
                            ids += arrayIdsActualizar[i] + ","
                        } else {
                            ids += ids + "0" + ","
                        }
                    }
                }

                formData.append("idDocumentos", ids)
            }
        } else {
            if (this.state.AppId != "") {
                this.setState({ esEditarDocumento: true })
            }
            formData.append("actualizar", 0)
        }

        console.log("arrayArchivosAEnviar ", arrayArchivosAEnviar)

        for (var i = 0; i < arrayArchivosAEnviar.length; i++) {
            //  let gene = this.buscaSubArchivoExisente(arrayArchivosAEnviar, idDocumento, i)
            //  if (gene.banderaExiste) {
            //   formData.append("imagen" + (i + 1), gene.archivo)
            formData.append("imagen" + (i + 1), arrayArchivosAEnviar[i].archivo)
            // }
        }


        this.setState({ showModalDocumentos: false })

        try {
            const res = await fetch(
                conexiones.urlSolicitudConcat + this.props.idSolicitud,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            const resData = await res.json();
            console.log("respues del serv", resData);
            let respuesta = resData[0]
            if (respuesta.cargado == 0) {
                this.setState({ showModalLoader: false })
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Ocurrio un error !', mensajeError: "Favor de cargar nuevamente el archivo.", showModal: true })
            }
            if (respuesta.cargado == 1) {
                //    this.eliminaSubArchivosCargados(idDocumento)
                this.cargaArchivosIndividualesConformantesDeArchivoMultiple(arrayArchivosAEnviar, idDocumento, this.props.idSolicitud)
                //  this.setState({ showModalLoader: false })
                this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Éxito!', mensajeError: "Archivo cargado correctamente", showModal: true })
            }

            setTimeout(() => {
                //this.setState({ showModalLoader: true })
                this.getDocumentosASolicitar(this.props.idSolicitud)
                this.setState({ showModalLoader: false })
            }, 3000)

        } catch (error) {
            console.log("error en la eticion ", error)
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: '¡Ocurrio un error !', mensajeError: "Favor de cargar nuevamente el archivo.", showModal: true })
        }


    }


    async cargaArchivosIndividualesConformantesDeArchivoMultiple(arrayArchivosAEnviar, idDocumento, idSolicitud) {

        try {

            for (var i = 0; i < arrayArchivosAEnviar.length; i++) {
                let formData = new FormData();
                formData.append("solicitud", this.props.idSolicitud)
                formData.append("documento", idDocumento)
                formData.append("documento_ruta", arrayArchivosAEnviar[i].archivo)
                const res = await fetch(
                    conexiones.urlApiDoc,
                    {
                        method: 'POST',
                        body: formData,
                    },
                );
                const resData = await res.json();
                if (resData.id == undefined) {
                    this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al persistir el  archivo individual  !', mensajeError: "Revisar por con admin el por que no sube el archivo", showModal: true })
                } else {

                    console.log("actualizando los documentos ", arrayArchivosAEnviar, arrayArchivosAEnviar[i].id)
                    this.actualizaSubArchivosCargados(idDocumento, resData.id, arrayArchivosAEnviar[i].id == undefined ? 0 : arrayArchivosAEnviar[i].id)
                }

            }

        } catch (error) {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al enviar el archivo individual  !', mensajeError: error.toString(), showModal: true })
        }
        console.log("archivos a enciar ma friend ", arrayArchivosAEnviar)

    }


    eliminaArchivo = async (idDocumento) => {
        this.setState({ showModalLoader: true })
        try {
            let json = {
                "solicitud": this.props.idSolicitud,
                "documento": idDocumento

            }
            const res = await fetch(
                conexiones.urlServicios + 'wedo_borra_documento/0',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(json)
                },
            );
            const data = await res.json();
            console.log("eliminando archivo  resp ", data)
            this.getDocumentosASolicitar(this.props.idSolicitud)
            this.setState({ showModalLoader: false })


        } catch (error) {
            this.setState({ showModalLoader: false })

            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al eliminar el archivo  !', mensajeError: error.toString(), showModal: true })
        }
    }


    eliminaSubArchivosCargados = async (idDocumento) => {
        try {
            let json = {
                "solicitud": this.props.idSolicitud,
                "documento": idDocumento,
                "tipo_operacion": 2
            }
            const res = await fetch(
                conexiones.urlSolicitudDocumento,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(json)
                },
            );
            const data = await res.json();
        } catch (error) {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al eliminar los archivos individuales  !', mensajeError: error.toString(), showModal: true })
        }
    }

    actualizaSubArchivosCargados = async (idDocumento, idDocumentoNuevo, idDocViejo) => {
        try {
            let json = {
                "solicitud": this.props.idSolicitud,
                "documento": idDocumento,
                "tipo_operacion": 2,
                "id_documento_old": idDocViejo,
                "id_documento_new": idDocumentoNuevo,
            }
            const res = await fetch(
                conexiones.urlSolicitudDocumento,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(json)
                },
            );
            const data = await res.json();
        } catch (error) {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al eliminar los archivos individuales  !', mensajeError: error.toString(), showModal: true })
        }
    }

    consultaSubArchivosCargados = async (idDocumento) => {
        try {

            let json = {
                "solicitud": this.props.idSolicitud,
                "documento": idDocumento,
                "tipo_operacion": 1
            }


            const res = await fetch(
                conexiones.urlSolicitudDocumento,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(json)
                },
            );

            const data = await res.json();

            return data


        } catch (error) {
            this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al consultar los archivos individuales  !', mensajeError: error.toString(), showModal: true })
        }
    }

    async cargaInformacionDocumentoASolicitar(idDocumento, nombreDoc, totalDeCargas, idRegla) {
        let archivos = []
        //   let gene=

        let subArchivosCargados = await this.consultaSubArchivosCargados(idDocumento)
        console.log("toto cargas  ", this.state.arraySubArchivos)
        let contador = 0;
        let esActulizacion = false
        if (subArchivosCargados.length > 0) {
            esActulizacion = true
            for (var i = 0; i < subArchivosCargados.length; i++) {
                let gene = this.buscaSubArchivoExisente(this.state.arraySubArchivos, idDocumento, i)
                console.log("retoro  ", gene)
                if (gene.banderaExiste == false) {
                    this.state.arraySubArchivos.push({
                        idDocumento: idDocumento,
                        idSubDocumento: i,
                        archivo: subArchivosCargados[i].documento_ruta,
                        nombre: subArchivosCargados[i].documento_nombre,
                        id: subArchivosCargados[i].id
                    })
                }


            }
        }
        for (var i = 0; i < totalDeCargas; i++) {
            let gene = this.buscaSubArchivoExisente(this.state.arraySubArchivos, idDocumento, i)

            let esCargado = gene.banderaExiste


            let ruta = subArchivosCargados.length > 0 ? gene.archivo : null
            let nombreArchivo = ""
            //  console.log("la ruta ",ruta,gene.archivo)
            if (ruta != null) {

                let extension = ""
                if (ruta instanceof File) {
                    console.log("la ruta ", ruta.name)
                    let partes = ruta.name.split('.');
                    extension = partes[partes.length - 1];
                } else {
                    let partes = ruta.split('.');
                    extension = partes[partes.length - 1];
                }

                //  console.log("la ruta ","archivo "+(i+1)+"."+extension)

                nombreArchivo = "archivo " + (i + 1) + "." + extension //gene.nombre
            }

            if (gene.archivo instanceof File) {
                let partes = gene.archivo.name.split(".")
                let extension = partes[partes.length - 1];
                nombreArchivo = "archivo " + (i + 1) + "." + extension //gene.nombre


            }




            if (esCargado == true) contador++


            console.log("la ruta antes de pintar es = ",ruta)
            archivos.push(
                <tr>
                    <td style={{ color: esCargado == true ? "#FF6B00" : 'blue' }}>{i + 1}</td>
                    {
                        ruta == null || ruta == undefined || ruta.length == 0 ?
                            <td style={{ color: esCargado == true ? "#FF6B00" : 'blue' }}>{esCargado == true ? nombreArchivo : " "}</td>
                            : <td style={{ color: esCargado == true ? "#FF6B00" : 'blue' }}> <a href={ruta} target="_blank" rel="noopener noreferrer">{nombreArchivo}</a></td>
                    }
                    <td>
                        <div style={{ "position": "relative", "display": "inline-block" }}>
                            <input
                                type="file"
                                id={"custom-file-input" + i}
                                style={{ display: 'none' }}
                                name={i}
                                accept=".jpg, .jpeg, .pdf, .jfif, .mp4, .mov"
                                onChange={
                                    this.onChangeSeleccionaSubArchivo(idDocumento, i,/* e.target.files[0],*/ totalDeCargas, nombreDoc, idRegla)
                                }
                            />
                            <label htmlFor={"custom-file-input" + i} style={{
                                "display": "inline-block",
                                "padding": "5px 5px",
                                "backgroundColor": esCargado == true ? "#FF6B00" : "#2E64FE",
                                "color": "white",
                                "cursor": "pointer",
                                "borderRadius": "5px",
                                "border": "none",
                                "fontSize": "15px"
                            }}
                            >
                                Seleccionar archivo
                            </label>
                        </div>
                        {
                            // <input type="file" onChange={null/*this.onChangeArchivoSolicitudCredito*/}></input>
                        }
                    </td>
                </tr>
            )
        }


        let salidaHtmlBotonGuardar = ''
        if (contador > 0) {
            salidaHtmlBotonGuardar = <Button variant="primary" onClick={() => { this.enviarArchivosMultiples(idDocumento, totalDeCargas, idRegla, esActulizacion) }}>Guardar</Button>
        }
        let salidaHTML = <Table striped >
            <thead>
                <tr>
                    <th>Número</th>
                    <th>Nombre Documento</th>
                    <th>Selecciona</th>
                </tr>
            </thead>
            {archivos}
            <tbody>
            </tbody>
        </Table>
        this.setState({ tituloModalDocumentos: "Selecione los archivos del documento " + nombreDoc, showModalDocumentos: true, mensajesDocumentos: salidaHTML, salidaHtmlBotonGuardar: salidaHtmlBotonGuardar })
    }


    enviarConcatenacionDeArchivos() {
        console.log(this.state.arrayDocumentosCargados)
        this.setState({ banderaEnviar: true })
        this.setState({ esEditarDocumento: false })
        this.setJsonRequestZell(this.props.jsonResultadosZell)
    }



    enviaSolicitudAZELL() {
        let jsonResultadosZell = this.state.esqueletoReqZell


    }





    /* esto no jala */

    eliminaDocumento(idDocumento, id, ruta) {
        const requestReferidoOptions = {
            method: "DELETE",
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(conexiones.urlSeviciosArchivos + "/doc_solicitud/" + idDocumento, requestReferidoOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({ [id]: null, [ruta]: null })
                this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Archivo eliminado', mensajeError: 'El archivo ha sido eliminado con exito ', showModal: true })
                this.getDocumentos(this.props.idSolicitud)

            }).catch(error => {
                this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al eliminar el archivo !', mensajeError: error.toString(), showModal: true })

            })

    }

    mascaraDecimales(valor) {
        return valor.replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    }
    quitaEspeciales(valor) {
        let finalString = valor.replace(/[^0-9.d{2}]/g, '')
        return finalString
    }
    onChangeDecimales = e => {
        console.log("eeee ", this.mascaraDecimales(e.target.value))
        if (e.target.name == "decimalesCampoName") {
            //if (e.target.value.length <= 2) {
                this.setState({ decimalesDescuento: this.mascaraDecimales(e.target.value) })
            //}
        }

        if (e.target.name == "montoCampoName") {
            let cadena = this.quitaEspeciales(e.target.value)
            if (cadena.split(".").length > 0) {
                let decimales = cadena.split(".")[1]
                if (decimales != undefined) {
                    if (decimales.length <= 2) {
                        this.setState({ montoSolicitado: cadena })
                    }
                } else {
                    this.setState({ montoSolicitado: cadena })
                }

            } else {
                this.setState({ montoSolicitado: cadena })
            }
        }


    }

    actualizarDecimales = e => {
        this.setState({ showModalLoader: true })
      
        let json =
        {
            "solicitud": this.props.idSolicitud,
            "descuento": /*this.props.nuevaSolicitudServicio.descuento.split(".")[0] + "." + */this.state.decimalesDescuento.replace(",",""),
            "monto_solicitado": this.state.montoSolicitado
        }
        console.log("el json ", json,this.state.decimalesDescuento.replace(",",""))
        const requestReferidoOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        }
        fetch(conexiones.urlServicios + "actualiza_importe_descuento/0", requestReferidoOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.length > 0) {
                    if (data[0].cargado == 1) {
                        //this.setState({ colorModal: this.state.colorSuccesModal, tituloModal: 'Importe actualizado', mensajeError: 'El importe ha sido actualizado', showModal: true })
                        this.setState({ showModalCartaLibranza: false })
                        this.setState({ showModalLoader: false })
                        //   const startButton = document.getElementById('custom-file-input-libranza');
                        //  startButton.click()
                    } else if (data[0].cargado == 0) {
                        this.setState({ colorModal: this.state.colorErrorModal, tituloModal: 'Ocurrio un error al actualizar el importe !', mensajeError: data[0].estatus, showModal: true })

                    }
                }
            }).catch(error => {
                this.setState({ showModalLoader: false })
                console.log(error)

            })
    }

    copiaAlportaPapeles = e => {
        let text = 'https://serviciosdxn.mx/firmar-documentos/?id_solicitud=' + this.props.idSolicitud + '&signerName=' +
            this.state.primerNombreDigital.trim() +"%20"+
            this.state.segundoNombreDigital+
            this.state.primerApellidoDigital.trim() +"%20"+
            this.state.segundoApellidoDigital.trim()
            + '&signatureReason=De%20acuerdo%20con%20las%20condiciones&carpeta=' + this.state.carpeta + "&convenio=" + this.state.tipo_convenioURLFirma

        navigator.clipboard.writeText(text)
            .then(() => {
                this.setState({ showModalFirmaDigitalTexto: false })
                // alert('Texto copiado al portapapeles!');
            })
            .catch(err => {
                console.error('Error al copiar el texto: ', err);
            });
    }

    render() {
        return (
            <div>
                <div className="sweet-loading">
                </div>
                {
                    this.state.banderaMuestraFirma && this.props.urlINE ?
                        <Fab onClick={() => {
                            this.props.banderaSolicitud ? console.log("mkk") : this.setState({ showModalFirmaDigitalTexto: true })
                        }} color="primary" aria-label="add" style={{ position: "fixed", bottom: "16px", left: "16px", width: '60px', height: "60px", backgroundColor: '#FF6B00', borderColor: '#FF6B00' }}>
                            URL Firma
                        </Fab> : ''
                }
                <br></br>
                < Row>
                    <Col xs={12}>
                        <span style={{ font: "bold", color: "#FF4000" }}>Sol: {this.props.idSolicitud == null ? "0" : this.props.idSolicitud}  DXN:{this.state.idDXN} </span>

                    </Col>
                </Row>
                <Row>

                    <div style={{ display: "flex" }}>
                        <img src={botonRegresar}
                            style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "2px", cursor: "pointer" }} onClick={() => { this.props.clickAvanzar("referencias") }} />
 
                        <img src={botonDescargarExpediente} disabled={this.props.banderaSolicitud}
                            style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "2px", cursor: "pointer" }} onClick={() => {
                                this.props.banderaSolicitud  || !this.props.existenDatosReferencias ? console.log("mkk") :
                                    this.generarExpediente("referencia1s")
                            }} />



                        {
                            this.state.banderaMuestraFirma ?
                                <img src={firma} disabled={this.props.banderaSolicitud}
                                    style={{ width: '60px', height: "60px", borderRadius: '10px', marginRight: "2px", cursor: "pointer" }} onClick={() => {
                                        this.props.banderaSolicitud ? console.log("mkk") : this.getSolicitudEvaulaFirmaDigital(this.props.idSolicitud)
                                    }} />
                                : ''
                        }


                        {this.state.banderaEnviar == false ?
                            <img src={botonEnviar} disabled={this.props.banderaSolicitud}
                                style={{ width: '150px', height: "60px", borderRadius: '40px', marginRight: "2px", cursor: "pointer" }} onClick={() => {
                                    this.props.banderaSolicitud ? console.log("mkk") : this.setState({ showModalConfirmaEnviarAZell: true }) /*this.enviarConcatenacionDeArchivos()*/
                                }} />
                            : ''}


                    </div>





                </Row>
                <br></br>

                { /*<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <MoonLoader
                        size={80}
                        color={"#052c65"}
                        loading={this.state.loading}
                        speedMultiplier={0.5}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        title="Cargando"
                    />
                </div>*/}
                {/*this.state.banMuestraTabla ?
                    <Row lg={12} md={12} xs={12} style={{ margin: '1px' }}>
                      {  <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Solicitud</th>
                                    <th>Ruta del Documento</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.selectTR}
                            </tbody>
                        </Table>}
                       
                    </Row>
                    : ''*/}

                {
                    this.state.arrayDocumentosASolicitar.length == 0 ?
                        <Alert key={"danger"} variant={"primary"}>
                            Por favor, imprima su solicitud de crédito para seguir con la carga de documentos.


                        </Alert>
                        :
                        <Table striped >
                            <thead>
                                <tr>
                                    <th>Documento {this.state.arrayDocumentosASolicitar.length != 0 ?
                                        <span style={{ marginLeft: "15px", marginTop: "10px", fontWeight: "bold", color: 'blue' }}>{"REQUERIDOS FALTANTES: " + this.state.documentosRestantes}</span> : ''}</th>
                                    <th></th>
                                    <th>Opciones</th>
                                    <th>Ver Documento</th>
                                    <th>Eliminar documento</th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.arrayDocumentosASolicitar}
                            </tbody>
                        </Table>
                }

                <Modal backdrop="static"keyboard={false}
                    show={this.state.showModaldocumentoFormato} onHide={() => { this.setState({ showModaldocumentoFormato: false }) }} centered size="md" fullscreen={true} >
                    <Modal.Header style={{ backgroundColor: this.state.colorModalDocumentos, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.nombreDocAMostrar}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                        {
                            this.state.showDocumentoPDF && !this.state.showDocumentoImagen ?
                        <Worker style={{width:"100%",height:"100%"}} workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">
                            <Viewer fileUrl={this.state.documentoformato} />
                        </Worker>
                        : !this.state.showDocumentoPDF && this.state.showDocumentoImagen ? <img style={{width:"100%"}} src={this.state.documentoformato} alt="Vista previa" />
                        :!this.state.showDocumentoPDF && !this.state.showDocumentoImagen ?
                        <iframe style={{width:"100%",height:"100%"}} src={this.state.documentoformato} ></iframe>:''
                        }

                    </Modal.Body> 
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModaldocumentoFormato: false }) }}>
                            Cerrar
                        </Button>

                        <Button variant="secondary" onClick={() => { this.descargarDocumentoAMostrar() }}>
                            Descargar Documentos
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModalCartaLibranza}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => { this.setState({ showModalCartaLibranza: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>
                        <Form.Group className="mb-6" controlId="">
                            <Form.Label style={{ fontWeight: "bold", textAlign: "justify", textJustify: "inter-word" }}>{"El importe del DESCUENTO es de : " + this.mascaraDecimales(this.state.descuento) + ", por favor verifique la carta libranza y cerciórese que el importe sea igual, en caso contrario presione modificar para ajustar solo decimales."}</Form.Label>
                            <Form.Control id="decimalesCampo" name="decimalesCampoName" disabled={this.state.banderaModificarDecimales} type="text" onChange={this.onChangeDecimales} placeholder="" value={this.state.decimalesDescuento} />
                            <br />
                            <Form.Label style={{ fontWeight: "bold", textAlign: "justify", textJustify: "inter-word" }}>{"El importe del MONTO SOLICITADO es de : " + this.mascaraDecimales(this.state.montoSolicitadoLabel) + ", por favor verifique la carta libranza y cerciórese que el importe sea igual, en caso contrario presione modificar para ajustar la cantidad."}</Form.Label>
                            <Form.Control id="montoCampo" name="montoCampoName" disabled={this.state.banderaModificarDecimales} type="text" onChange={this.onChangeDecimales} placeholder="" value={this.state.montoSolicitado} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => {
                            this.setState({ showModalCartaLibranza: false })
                            //    const startButton = document.getElementById('custom-file-input-libranza');
                            //   startButton.click()
                        }}>
                            Importe correcto

                        </Button>
                        {
                            this.state.banderaModificarDecimales == true
                                ?
                                <Button style={{ backgroundColor: "#77dd77", borderColor: "#77dd77" }} onClick={() => {
                                    this.setState({ banderaModificarDecimales: false })
                                    const startButton = document.getElementById('decimalesCampo');
                                    startButton.focus()
                                }}>
                                    Modificar cantidades
                                </Button> :
                                <Button style={{ backgroundColor: "#FF6B00", borderColor: "#FF6B00" }} onClick={
                                    this.actualizarDecimales}>
                                    Actualizar cantidades
                                </Button>
                        }


                    </Modal.Footer>
                </Modal>


                <Modal backdrop="static"
                    keyboard={false}
                    show={this.state.showModalFirmaDigital} onHide={() => { this.setState({ showModalFirmaDigital: false }) }} centered size="md" fullscreen={true} >
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>Ingresa firma digital   <Button variant="secondary" onClick={() => { this.setState({ showModalFirmaDigital: false }) }}>
                            Cerrar
                        </Button></Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        
                        
                                <iframe id="myIframe1" height={"100%"} width={"100%"}
                                    src={'https://serviciosdxn.mx/firmar-documentos/?id_solicitud=' + this.props.idSolicitud + '&signerName=' +
                                    this.state.primerNombreDigital.trim()+"%20" +    
                                    this.state.segundoNombreDigital+
                                    this.state.primerApellidoDigital.trim()+"%20" +
                                    this.state.segundoApellidoDigital
                                    + '&signatureReason=De%20acuerdo%20con%20las%20condiciones&carpeta=' + this.state.carpeta + "&convenio=" + this.state.tipo_convenioURLFirma}></iframe>
                            
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModalFirmaDigital: false }) }}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal backdrop="static"
                    keyboard={false}
                    show={this.state.showModalFirmaDigitalTexto} onHide={() => { this.setState({ showModalFirmaDigitalTexto: false }) }} centered size="lg" fullscreen={true} >
                    <Modal.Header style={{ backgroundColor: this.state.colorModalDocumentos, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>Copia la url de la firma digital   </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

             
                        <span >{'https://serviciosdxn.mx/firmar-documentos/?id_solicitud=' + this.props.idSolicitud + '&signerName=' +
                            this.state.primerNombreDigital.trim()+"%20" +    
                            this.state.segundoNombreDigital+
                            this.state.primerApellidoDigital.trim()+"%20" +
                            this.state.segundoApellidoDigital
                            + '&signatureReason=De%20acuerdo%20con%20las%20condiciones&carpeta=' + this.state.carpeta + "&convenio=" + this.state.tipo_convenioURLFirma}</span>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModalFirmaDigitalTexto: false }) }}>
                            Cerrar
                        </Button>

                        <Button style={{ backgroundColor: '#FF6B00', borderColor: '#FF6B00' }} onClick={this.copiaAlportaPapeles}>
                            Copiar al portapapeles
                        </Button>

                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.showModalBorrar} onHide={() => { this.setState({ showModalBorrar: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModalBorrar: false }) }}>
                            Cerrar
                        </Button>

                        <Button variant="primary" onClick={() => {
                            this.eliminaArchivo(this.state.idDocumentoAEliminar)
                            this.setState({ showModalBorrar: false })
                            console.log("el id del dox  ", this.state.idDocumentoAEliminar)
                        }}>
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModal, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.tituloModal}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajeError}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModal: false }) }}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>



                <Modal show={this.state.showModalDocumentos} onHide={() => { this.setState({ showModalDocumentos: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModalDocumentos, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center', color: 'white' }}>{this.state.tituloModalDocumentos}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{this.state.mensajesDocumentos}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModalDocumentos: false }) }}>
                            Cerrar
                        </Button>
                        {this.state.salidaHtmlBotonGuardar}
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.showModalConfirmaEnviarAZell} onHide={() => { this.setState({ showModalConfirmaEnviarAZell: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModalDocumentos, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center', color: 'white' }}>{"Estas Seguro ?"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>{"Confirmas el envío de información a ZELL?"}</Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={() => { this.enviarConcatenacionDeArchivos() }}>
                            Confirmar
                        </Button>
                        <Button variant="secondary" onClick={() => { this.setState({ showModalConfirmaEnviarAZell: false }) }}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </Modal>





                <Modal backdrop="static"
                    keyboard={false}
                    show={this.state.showModalLoader} onHide={() => { this.setState({ showModalLoader: false }) }} centered size="lg">
                    <Modal.Header style={{ backgroundColor: this.state.colorModalDocumentos, textAlign: 'center' }} >
                        <Modal.Title style={{ textAlign: 'center', color: 'white' }}>{"Cargando información, por favor espera…"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>
                        {/*   <Spinner animation="grow" variant="primary" />
                        <Spinner animation="grow" variant="secondary" />
                        <Spinner animation="grow" variant="success" />
                        <Spinner animation="grow" variant="danger" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="info" />
                        <Spinner animation="grow" variant="light" />
                        <Spinner animation="grow" variant="dark" />
                       
                        */

                        }

                        <LinearProgress color="secondary" />



                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>




                <Row lg={12} md={12} xs={12} style={{ margin: '1px' }}>
                    <Col lg={9} md={9} xs={9}>&nbsp;</Col>

                    <Col lg={3} md={3} xs={3}>{ }</Col>
                </Row>
            </div>
        )

    }
}