import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from 'react-bootstrap/Pagination';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from 'react-bootstrap/Modal';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Button from 'react-bootstrap/Button';
import CatalogoAutos from "./CatalogoAutos";
import Fab from '@mui/material/Fab';

export default class ConsultaCotizacionesAutos extends Component {

    constructor() {
        super();
        this.generaDocumentoAMostrar = this.generaDocumentoAMostrar.bind(this)
        this.regresarABandeja=this.regresarABandeja.bind(this)
        this.state = {
            esNuevaCotizacion:false,
            showDocumentoPDF: false,
            showDocumentoImagen: false,
            showModaldocumentoFormato: false,
            documentoformato: null,
            nombreDocAMostrar: "",
            valoresTabla: null,
            selectEstatus: [],
            selectTR: [],
            selectEstatusBusqueda: [],
            paginacion: null,
            topePaginacion: 20,
            columns: [
                { id: 'Estatus de mi venta', label: 'Estatus de mi venta', minWidth: 170 },
                { id: 'Solicitud', label: 'Solicitud', minWidth: 100 },
                {
                    id: 'Sucursal',
                    label: 'Sucursal',
                    minWidth: 170,
                    align: 'right',
                    format: (value) => value.toLocaleString('en-US'),
                },


                {
                    id: 'Datos del crédito	',
                    label: 'Opciones',
                    minWidth: 170,
                    align: 'right',
                    format: (value) => value.toFixed(2),
                },



            ],
        }
    }

    getTabla() {
        fetch("https://wedoplus.mx:8099/autos/cotizacion_listado/0")
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({valoresTabla:data})
                this.armaTabla(data, 1, this.state.topePaginacion + 1, 1)

            }).catch(error => {

            })
    }

    UNSAFE_componentWillMount() {
        this.getTabla()
    }
    mascaraDecimales(valor) {
        return valor.replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    }

    cambiaPagina(numPagina, e) {
        return event => {
            let conteo = (numPagina) * this.state.topePaginacion
            let inicio = (conteo - this.state.topePaginacion) + 1
            let fin = conteo + 1
            this.armaTabla(this.state.valoresTabla, inicio, fin, numPagina)
        }

    }

    armaPaginador(data, pagina) {
        let items = []
        let paginas = data.length / this.state.topePaginacion;

        if (paginas % 2 == 0) {
            console.log("total de paginas  == 0 " + paginas)
            for (var i = 1; i <= paginas; i++) {
                items.push(
                    <Pagination.Item onClick={this.cambiaPagina(i)} key={i} active={i === pagina}>
                        {i}
                    </Pagination.Item>
                )
            }
        } else {
            let paginasString = paginas + ""
            let paginaInt = parseInt(paginasString.split(".")[0]) + 1
            console.log("total de paginas != 0 " + (paginaInt))

            for (var i = 1; i <= paginaInt; i++) {
                items.push(
                    <Pagination.Item onClick={this.cambiaPagina(i)} key={i} active={i === pagina}>
                        {i}
                    </Pagination.Item>
                )
            }
        }

        let paginacion = <Pagination>{items}</Pagination>
        this.setState({ paginacion: paginacion })




    }
    generaDocumentoAMostrar = (urlDoc, nombreDocAMostrar) => {
        return e => {
            try {
                this.setState({ urlDocumentoMostrado: urlDoc })
                console.log("la url a resolver mi chavo ", urlDoc)
                if (urlDoc.includes(".pdf")) {
                    console.log("PDFFFFF  ", urlDoc)
                    this.setState({ showDocumentoPDF: true, showDocumentoImagen: false })

                } else if (urlDoc.includes(".jpg") || urlDoc.includes(".jpeg")) {
                    console.log("IMG ", urlDoc)
                    this.setState({ showDocumentoImagen: true, showDocumentoPDF: false })
                } else {
                    console.log("otro ", urlDoc)
                    this.setState({ showDocumentoImagen: false, showDocumentoPDF: false })
                }

                fetch(urlDoc)
                    .then(response => response.blob())
                    .then(data => {
                        const url = URL.createObjectURL(data);
                        console.log("se ha resuelto la url ", url)
                        setTimeout(() => {
                            this.setState({ nombreDocAMostrar: nombreDocAMostrar, documentoformato: url, showModaldocumentoFormato: true })
                        }, 1500)
                    }).catch(error => {
                        console.log(error)
                    })


            } catch (exception) {
                console.log(exception)

            }
        }


    }

    armaTabla(data, inicio, fin, pagina) {
        let selectTR = []
        console.log(data)

        inicio = inicio - 1
        fin = fin - 1
        console.log(inicio)
        console.log(fin)
        for (var i = inicio; i < fin; i++) {
            console.log("/////////////////////////////// ", i, data[i])

            if (data[i] != undefined) {
                let selectTD = []
                selectTD.push(
                    <TableCell align={"center"} >
                        {<Row>
                            <Col lg={12} md={12} xs={12}>
                                <h6 >{data[i].descripcion} </h6><h6 style={{ color: "#FF6B00" }}><AccessTimeOutlinedIcon style={{ color: '#ff6b00', cursor: 'pointer' }} />&nbsp;{data[i].descripcion} </h6>

                            </Col>

                        </Row>}
                    </TableCell>
                )
                selectTD.push(<TableCell align={"left"}>
                    <>
                        <span style={{ color: 'blue', fontWeight: 'bold' }}>{data[i].sector} </span>
                        <br></br>
                        <WorkOutlinedIcon style={{ color: '#585858' }} />{data[i].producto}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>{data[i].periodo}</span>
                        <br></br>
                        <CalendarMonthOutlinedIcon style={{ color: '#585858' }} />{data[i].plazo}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>Costo de la gestoria: $</span>{data[i].costo_gestoria}


                    </>
                </TableCell>)
                selectTD.push(<TableCell align={"left"}>
                    <>

                        <span style={{ fontWeight: 'bold' }}>  Unidad: </span>{data[i].unidad_cotizada}
                        <br></br>
                        <><span style={{ fontWeight: 'bold', color: 'green' }}>  Pago: </span>   <span style={{ color: 'green' }} >{"$" + this.mascaraDecimales(data[i].pago)}</span></>
                        <br></br>
                        <><span style={{ fontWeight: 'bold', color: 'green' }}>  Total: </span>   <span style={{ color: 'red' }} >{"$" + this.mascaraDecimales(data[i].total)}</span></>
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>Enganche: $</span>{data[i].enganche}
                        <br></br>
                        <span style={{ fontWeight: 'bold' }}>Financiamiento: </span>{data[i].financiamiento}
                        <br />
                        <PersonOutlineOutlinedIcon style={{ color: '#585858' }} />  <span style={{ fontWeight: 'bold' }}>Correo cliente: </span>{data[i].correo_electronico}

                    </>
                </TableCell>)




                selectTD.push(<TableCell align={"left"}>
                    <>
                        <Row>
                            {
                                data[i] != undefined ? <Col lg={6} md={6} xs={5}>
                                    {
                                        <VisibilityIcon onClick={
                                            this.generaDocumentoAMostrar(data[i].url_cotiza, "noo")
                                        } style={{ color: '#77dd77', cursor: 'pointer' }} ></VisibilityIcon>
                                    }
                                </Col> : ""
                            }

                        </Row>
                    </>
                </TableCell>)


                selectTR.push(<TableRow>{selectTD}</TableRow>)


            }


        }
        this.setState({ selectTR: selectTR })
        this.armaPaginador(data, pagina)



    }

    regresarABandeja(){
        this.getTabla()
        setTimeout(()=>{ this.setState({esNuevaCotizacion:false})},2000)
    }


    render() {

        return (
            <>

                <Modal backdrop="static" keyboard={false}
                    show={this.state.showModaldocumentoFormato} onHide={() => { this.setState({ showModaldocumentoFormato: false }) }} centered size="md" fullscreen={true} >
                    <Modal.Header style={{ backgroundColor: this.state.colorModalDocumentos, textAlign: 'center' }} closeButton>
                        <Modal.Title style={{ textAlign: 'center' }}>{this.state.nombreDocAMostrar}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                        {
                            this.state.showDocumentoPDF && !this.state.showDocumentoImagen ?
                                <Worker style={{ width: "100%", height: "100%" }} workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">
                                    <Viewer fileUrl={this.state.documentoformato} />
                                </Worker>
                                : !this.state.showDocumentoPDF && this.state.showDocumentoImagen ? <img style={{ width: "100%" }} src={this.state.documentoformato} alt="Vista previa" />
                                    : !this.state.showDocumentoPDF && !this.state.showDocumentoImagen ?
                                        <iframe style={{ width: "100%", height: "100%" }} src={this.state.documentoformato} ></iframe> : ''
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ showModaldocumentoFormato: false }) }}>
                            Cerrar
                        </Button>

                        <Button variant="secondary" onClick={() => {/* this.descargarDocumentoAMostrar() */ }}>
                            Descargar Documentos
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                {
                    !this.state.esNuevaCotizacion ?<div className="page-content" style={{ backgroundColor: 'white', marginTop: '25px', height: '100%', width: '100%' }}>
                    <div className="content-wrapper">
                        <div className="content-inner" style={{ margin: '10px' }}>
                            <br></br>
                            <Row>
                                <Col xs={6} md={8} lg={8} style={{ display: 'flex' }}>
                                    &nbsp;
                                </Col>
                                <Col xs={6} md={4} lg={4} style={{ display: 'flex' }}>
                                    <Fab variant="extended" style={{ backgroundColor: '#FF6B00', color: "white", width: "100%" }} onClick={() => {
                                        this.setState({ muestraComponente: "pp" })
                                        setTimeout(() => { this.setState({ esNuevaCotizacion: true }) }, 10)
                                    }}>
                                        Nueva cotización
                                    </Fab>
                                </Col>
                            </Row>

                            <br />
                            <Col lg={12} md={12} xs={12}>
                                <Paper sx={{ width: '100%' }} md={{ width: '100%' }} lg={{ width: '100%' }} >
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <Col lg={12} md={12} xs={12}>

                                            </Col>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" colSpan={5}>
                                                        &nbsp;
                                                    </TableCell>
                                                    <TableCell align="left" colSpan={3}>
                                                        &nbsp;
                                                    </TableCell>
                                                    <TableCell align="left" colSpan={3}>
                                                        &nbsp;
                                                    </TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    {this.state.columns.map((column, index) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={index == 0 ? "center" : "left"}
                                                            style={{ top: 57, minWidth: column.minWidth, fontStyle: "bold", fontSize: "15px" }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.selectTR}


                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Row lg={12} md={12} xs={12} style={{ margin: '1px' }}>
                                        <Col lg={8} md={8} xs={0}>&nbsp;</Col>
                                        <Col lg={4} md={4} xs={12}>{this.state.paginacion}</Col>
                                    </Row>
                                </Paper>
                            </Col>
                        </div>
                    </div>
                </div>:<CatalogoAutos regresarABandeja={this.regresarABandeja} idUsuario={this.props.idUsuario}/>
                }
                


            </>

        )
    }

}